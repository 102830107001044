import React, { useState, useEffect } from 'react';
import GridCardEvent from './GridCardEvent.js';
import SportIcon from './utils/SportIcon.js';
import RoundFlag from './RoundFlag.js';
//import { ethers } from 'ethers';
//import IPFS from 'ipfs-core';
//import { setNetworkConfig } from '../config/ethersConfig.js';
//const ethersConfig = require('../config/ethersConfig.js');
//const coreAbi = require('../abi/core-abi.json');
//import coreAbi from '../abi/core-abi.json';
import { useSelector } from 'react-redux';
const utils = require('../utils/utils.js');
//import * as store from '../utils/store.js';

const GridCard = (props) => {
  /*const [sport, setSport] = useState('Football');
  const [country, setCountry] = useState('es');
  const [league, setLeague] = useState('La Liga');*/
  /*const [sport, setSport] = useState(sportProp);
  const [games, setGames] = useState(gamesProp);*/
  const { network, sport, games } = props;
  console.log('GridCard.js games',games)
  console.log('GridCard.js network',network)
  const globalCompetitions = useSelector((state) => state.jsonCompetitions.data);
  const globalCountries = useSelector((state) => state.jsonCountries.data);
  console.log('GridCard.js globalCompetitions',globalCompetitions)
  console.log('GridCard.js globalCountries',globalCountries)
  /*return (
    <div id='main-events-container' className='main-div grid-card'>
      <div className='grid-card-sport-header grid-card-sport-header-bottom-line'>
        <div className='dp-flex mr-12'>
          <Soccer className='icon' />
        </div>
        <div className='left-menu-text-div'>{sport}</div>
      </div>
      <div className='grid-card-sport-header'>
        <div className='dp-flex mr-12'>
          <RoundFlag country={country} />
        </div>
        <div className='left-menu-text-div'>
          {country} · {league}
        </div>
      </div>
      <GridCardEvent />
      <GridCardEvent />
      <GridCardEvent />
      <GridCardEvent />
    </div>
  );*/

  /*if (!games || !games[sport] || !Array.isArray(games[sport])) {
    // Manejar el caso en el que games o games[sport] no estén definidos correctamente
    console.log('NULL games GridCard',games)
    return null; // O muestra un mensaje de error, renderiza algo diferente, etc.
  }*/
  
  //console.log('games GridCard',games)
  //console.log('globalCompetitions -> ',globalCompetitions)
  
  //Protection against undefined globalCompetitions and globalCountries. If they are undefined, no error message is shown
  const ShowCountryAndCompetitions = () => {
    if (globalCompetitions[sport] !== undefined && globalCountries !== undefined) {
      return Object.keys(games).map((gameCompetition) => {
        const competition = globalCompetitions[sport].find((globalCompetition) => globalCompetition.keyName === gameCompetition);
        if (competition !== undefined && ('isoCountry' in competition)) {
          const country = globalCountries.find(globalCountry => globalCountry.isoCountry === competition.isoCountry);
          if (country !== undefined) {
            return (
              <React.Fragment key={gameCompetition}>
                <div className='grid-card-event-line'></div>
                <div className='grid-card-sport-header'>
                  <div className='dp-flex mr-12'>
                    <RoundFlag country={country.isoCountry} />
                  </div>
                  <div className='left-menu-text-div'>
                    {country.shortName} · {competition.fullName}
                  </div>
                </div>

                {games[gameCompetition].map(game => (
                  <GridCardEvent key={game.gameId} network={network} sportKeyname={sport} competitionKeyname={gameCompetition} game={game} isoCountry={country.isoCountry} />
                ))}
              </React.Fragment>
            )
          }
        }
      });
    }
  }

  return (
    <ShowCountryAndCompetitions />
  )

  /* return (
    globalCompetitions[sport] !== undefined && Object.keys(games).map((gameCompetition) => {
      return (<React.Fragment key={gameCompetition}>
        <div className='grid-card-event-line'></div>
        <div className='grid-card-sport-header'>
          <div className='dp-flex mr-12'>
            <RoundFlag country={globalCompetitions[sport].find((globalCompetition) => globalCompetition.keyName === gameCompetition).isoCountry} />
          </div>
          <div className='left-menu-text-div'>
            {globalCountries.find(globalCountry => globalCountry.isoCountry === (globalCompetitions[sport].find((globalCompetition) => globalCompetition.keyName === gameCompetition).isoCountry)).shortName} · {globalCompetitions[sport].find((globalCompetition) => globalCompetition.keyName === gameCompetition).fullName}
          </div>
        </div>
        
        {games[gameCompetition].map(game => (
          <GridCardEvent key={game.gameId} network={network} sportKeyname={sport} competitionKeyname={gameCompetition} game={game} isoCountry={globalCompetitions[sport].find((globalCompetition) => globalCompetition.keyName === gameCompetition).isoCountry} />
        ))}
      </React.Fragment>)
    })
  ) */

}

export default GridCard;