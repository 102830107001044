
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.

import { useEffect, useState } from "react";
import NivoCanvas from "../../components/Statistics/NivoCanvas";
import data from "../../components/Statistics/data.json";
import '../../components/Statistics/styles.css';

const Statistics = () => {
    const [ mapHeight, setMapHeight ] = useState(0);
    const [ mapWidth, setMapWidth ] = useState(0);
    
    useEffect(() => {
        setMapHeight(window.innerHeight/2);
        setMapWidth(window.innerHeight);
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    const updateDimensions = () => {
        setMapHeight(window.innerHeight/2);
        setMapWidth(window.innerHeight);
    };

    return (
        <div id='main-parent-container'>
            <div id='main-container'>
                <h1>Statistics</h1>
                <div className='statistics-main-div'>
                    <div className="statistics-map-div" style={{width: mapWidth, height: mapHeight}}>
                        <NivoCanvas data={data} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Statistics;