//import { useSelector } from 'react-redux';

export const Toggle = ({ label, toggled, onClick }) => {
    //const { data: betslip } = useSelector(state => state.betslip);

    /* const callback = () => {
        onClick(betslip.combo)
    } */

    return (
        <label className='toggle-label'>
            <input type="checkbox" defaultChecked={true} checked={toggled} onClick={onClick} className='toggle-input' />
            <span className='toggle-span' />
            <strong className='toggle-strong'>{label}</strong>
        </label>
    )
}