import React from 'react';
import Football from '../../assets/icons/sports/Football.js';
import Basketball from '../../assets/icons/sports/Basketball.js';
import Tennis from '../../assets/icons/sports/Tennis.js';
import Baseball from '../../assets/icons/sports/Baseball.js';
import Golf from '../../assets/icons/sports/Golf.js';
import Volleyball from '../../assets/icons/sports/Volleyball.js';
import AmericanFootball from '../../assets/icons/sports/AmericanFootball.js';

const SportIcon = (props) => {
    const sportKeyname = props.sport
    console.log('SportIcon.js sportKeyname',sportKeyname)
    const sportIcon =
        (sportKeyname === 'football' && <Football className='icon' />) ||
        (sportKeyname === 'basketball' && <Basketball className='icon' />) ||
        (sportKeyname === 'tennis' && <Tennis className='icon' />) ||
        (sportKeyname === 'baseball' && <Baseball className='icon' />) ||
        (sportKeyname === 'golf' && <Golf className='icon' />) ||
        (sportKeyname === 'volleyball' && <Volleyball className='icon' />) ||
        (sportKeyname === 'american_football' && <AmericanFootball className='icon' />)
    return (sportIcon)
}

export default SportIcon