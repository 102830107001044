import React from 'react';
import * as utils from '../utils/utils.js';

const flagsPath = `${utils.baseUrl}/assets/icons/countries/`;

const RoundFlag = ({country, size}) => {
  const iconSize = size || 'small';
  return (
    <img
      src={`${flagsPath}${country}.svg`}
      alt=''
      className={`round-icon-${iconSize}`}
    />
  );
};

export default RoundFlag;