import * as ethersConfig from '../../config/ethersConfig.js';
import { useContractWrite, useContract, Web3Button } from "@thirdweb-dev/react";
const utils = require('../../utils/utils.js');

const RedeemButton = (props) => {
    const { network, betsSet, betsSetStatus, onTxRedeemResolved } = props
    //const isBetsSetGameResolvedAndWon = onRedeemClick()
    console.log('Redeem component', network)
    console.log('Redeem component betsSet', betsSet)
    console.log('Redeem component isBetsSetGameResolvedAndWon', betsSetStatus)
    const web3Config = ethersConfig.web3Config(network)
    const { contract } = useContract(
        web3Config.coreAddress,
        web3Config.coreAbi
    );
    const { mutateAsync, isLoading, error } = useContractWrite(
        contract,
        "payWonBet",
    );
    
    const handleClick = async (betsSetId) => {
        try {
            if ((betsSetStatus === utils.betsSetStatus.WON || betsSetStatus === utils.betsSetStatus.SUSPENDED) && !betsSet.paid) {
                console.log('Calling mutateAsync...');
                console.log('betsSet.id',betsSet.id);
                const result = await mutateAsync({ args: [betsSet.id] });
                console.log('mutateAsync result:', result);
                // Llama a la función onTxResolved con el resultado de mutateAsync
                onTxRedeemResolved(betsSetId);
            }
        } catch (error) {
            console.error('An error occurred:', error);
            // Puedes manejar el error aquí si es necesario
        }
    };

    const buttonClassName = 
    'btn-very-small btn-redeem pt-0 pr-16 pb-0 pl-16 h-32 lh-32 br-5'
    + ((betsSetStatus === utils.betsSetStatus.WON || betsSetStatus === utils.betsSetStatus.SUSPENDED) && !betsSet.paid
    ? ' bg-gradient-lightgreen-green'
    : ' bg-grey btn-disabled')
    
    return (
      <button
        className={buttonClassName}
        disabled={((!betsSetStatus === utils.betsSetStatus.WON || !betsSetStatus === utils.betsSetStatus.SUSPENDED) && betsSet.paid)}
        onClick={() => handleClick(betsSet.id)}>{!betsSet.paid ? 'Redeem' : 'Redeemed'}
      </button>
    )
}

export default RedeemButton