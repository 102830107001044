import React, { useEffect, useState } from "react";
import { Outlet, Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

import Logo from "../Logo/Logo.js";
import ConnectButton from "../ConnectButton/ConnectButton.js";
import NetworkButton from "../NetworkButton/NetworkButton.js";
import * as utils from "../../utils/utils.js";
import { useSelector } from "react-redux";

const Navbar = ({ handleLeftMenuClick, isLeftMenuToggleOn, navBarRef }) => {
  console.log("Navbar.js");
  const wallet = useSelector((state) => state.wallet.data);
  const [network, setNetwork] = useState(undefined);

  const [isChecked, setIsChecked] = useState(false);
  console.log("Navbar.js param utils network", utils.defaultNetwork.keyName);

  const onChange = () => {
    setIsChecked(!isChecked);
    handleLeftMenuClick();
  };

  useEffect(() => {
    if (wallet.network.keyName) {
      setNetwork(wallet.network.keyName);
    }
  }, [wallet.network.keyName]);

  useEffect(() => {
    setIsChecked(isLeftMenuToggleOn);
  }, [isLeftMenuToggleOn]);

  const ShowLeftMenuButton = () => {
    if (isChecked) {
      return <FontAwesomeIcon icon={icon({ name: "xmark" })} />;
    } else {
      return <FontAwesomeIcon icon={icon({ name: "bars" })} />;
    }
  };

  const handleClick = (page) => {
    window.location.href = network
      ? `/${network}/${page}`
      : `/${utils.defaultNetwork.keyName}/${page}`;
  };

  const handleLogoClick = () => {
    window.location.href = "/";
  };

  return (
    <>
      <nav id="menu" ref={navBarRef}>
        <input
          type="checkbox"
          id="responsive-menu"
          checked={isChecked}
          onChange={onChange}
        />
        <label>
          <ShowLeftMenuButton />
        </label>
        <div onClick={() => handleLogoClick()}>
          <div className="app-logo-div">
            <Logo className="app-logo" />
          </div>
        </div>
        <div className="menu-navbar-middle">
          <ul>
            <li>
              <div onClick={() => handleClick("sports")}>Sports</div>
            </li>
            <li>
              <div onClick={() => handleClick("my-bets")}>My Bets</div>
            </li>
            <li>
              <div onClick={() => handleClick("get-token")}>Get DAI</div>
            </li>
            <li>
              <div className="btn-disabled">Live <span className='soon-text'>soon</span></div>
            </li>
          </ul>
        </div>
        <NetworkButton />
        <ConnectButton />
      </nav>
      <Outlet />
    </>
  );
};

export default Navbar;
