import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as ethersConfig from '../config/ethersConfig.js';
const utils = require('../utils/utils.js');
//import * as store from '../utils/store.js';

const MyBetSingleCard = (props) => {
  const { betsSet } = props;
  //console.log('MyBetSingleCard.js: ',betsSet)

  const dispatch = useDispatch();
    const { data: wallet } = useSelector(state => state.wallet);
  //const { loading: loadingUserBetsSets, error: errorUserBetsSets, data: userBetsSets } = useSelector(state => state.userBetsSets);
  /*const { loading: loadingBetslip, error: errorBetslip, data: betslip } = useSelector(state => state.betslip);
  const globalCompetitions = useSelector((state) => state.jsonCompetitions.data);*/
  
  const lang = utils.lang; //TODO: make global var

  /*const web3Config = ethersConfig.web3Config
  const tokenContract = web3Config.tokenContract;
  const [tokenDecimals, setTokenDecimals] = useState(null)
  const [tokenSymbol, setTokenSymbol] = useState(null)*/
  /*const tokenDecimals = async () => {
      return await tokenContract.decimals()
  } //TODO: hacer variable global*/
  /*useEffect(() => {
      //const tokenContract = ethersConfig.tokenContract
      tokenContract.decimals()
      .then((decimals) => {
        setTokenDecimals(decimals) //TODO: hacer var global
      })
      tokenContract.symbol()
      .then((symbol) => {
        setTokenSymbol(symbol) //TODO: hacer var global
      })
  }, []);
  console.log('decimals_',tokenDecimals);*/

  const betTimestampLocal = utils.gameTimestampToLocal(betsSet.createdAt);

  const UserBetsSet = () => {
    let showBetsSet = true
    console.log('MyBetSingleCard betsSet',betsSet)
    if (!betsSet.paid) {
      betsSet.bets.map(bet => {
        if (bet.game.resolved) {
          showBetsSet = false
        }
      })
    } else {
      showBetsSet = false
    }
    
    if (showBetsSet) {
      const betsSetStatusClassName = 'betslip-my-bets-grid-card-header-status '
      + (betsSet.accepted ? 'won-bet' : 'pending-bet')

      return (
        <>
          <div key={`${betsSet.createdAt}`} className='betslip-my-bets-card'>
            <div className='betslip-my-bets-grid-card-header'>
              <div className='betslip-my-bets-grid-card-header-datetime'>
                <span>
                  {
                    betTimestampLocal.dayLabel
                  }, {
                    betTimestampLocal.timeLabel
                  }
                </span>
              </div>
              <div className={betsSetStatusClassName}>
                {betsSet.accepted ? 'Accepted' : 'Pending'}
              </div>
            </div>
            <div className='grid-card-event-line-with-offset'></div>
            {
              betsSet.bets.map((bet) => (
                <React.Fragment key={`betslip-my-bets-${bet.betId}`}>
                  <div className='betslip-my-bets-card-details'>
                    <div className='betslip-my-bets-card-participants'>
                      {bet.home.shortName} - {bet.away.shortName}
                    </div>
                    <div className='betslip-my-bets-card-market'>
                      {bet.market[lang]}
                    </div>
                    <div className='betslip-my-bets-card-market-value'>
                      {bet.market.value}
                    </div>
                    <div className='betslip-my-bets-card-odd'>
                      @ {parseFloat(bet.market.odd/10**bet.market.oddsDecimals).toFixed(2)}
                    </div>
                  </div>
                  <div className='grid-card-event-line-with-offset'></div>
                </React.Fragment>
              ))
            }
            <div className='betslip-my-bets-grid-card-footer'>
              <div className='betslip-my-bets-grid-card-amount'>
                Amount:
              </div>
              <div className='betslip-my-bets-grid-card-amount-value'>
                {wallet.tokenDecimals !== null ? parseFloat(betsSet.amount/(10**wallet.tokenDecimals)) : ''}
                {wallet.tokenSymbol !== null ? wallet.tokenSymbol : ''}
              </div>
            </div>
          </div>
        </>
      )
    }
  }
  
  return(
    <>
    <UserBetsSet />
    </>
  )
}

export default MyBetSingleCard;