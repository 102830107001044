import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import * as utils from './utils';

export const fetchGameJsonFromIpfs = createAsyncThunk(
  'json/fetchGameFromIpfs',
  async (ipfsHash) => {
    const data = await utils.getJsonObjectFromHash(ipfsHash);
    return data;
  }
);

export const fetchCompetitionsJsonFromIpfs = createAsyncThunk(
  'json/fetchCompetitionsFromIpfs',
  async (ipfsHash) => {
    const data = await utils.getJsonObjectFromHash(ipfsHash);
    return data;
  }
);

export const fetchCountriesJsonFromIpfs = createAsyncThunk(
  'json/fetchCountriesFromIpfs',
  async (ipfsHash) => {
    const data = await utils.getJsonObjectFromHash(ipfsHash);
    return data;
  }
);

export const fetchTeamsJsonFromIpfs = createAsyncThunk(
  'json/fetchTeamsJsonFromIpfs',
  async (ipfsHash) => {
    const data = await utils.getJsonObjectFromHash(ipfsHash);
    return data;
  }
);

export const fetchMarketsJsonFromIpfs = createAsyncThunk(
  'json/fetchMarketsJsonFromIpfs',
  async (ipfsHash) => {
    const data = await utils.getJsonObjectFromHash(ipfsHash);
    return data;
  }
);

export const addVersion = createAction(
  'version/addVersion',
  (version, getState) => {
    return {
      payload: version,
    };
  }
);

export const addLastConnection = createAction(
  'lastConnection/addLastConnection',
  (lastConnection, getState) => {
    return {
      payload: lastConnection,
    };
  }
);

export const setReferralAddress = createAction(
  'referral/setReferralAddress',
  (referral, getState) => {
    return {
      payload: referral,
    }
  }
)

export const addSports = createAction(
  'sports/addSports',
  (sports, getState) => {
    return {
      payload: sports,
    };
  }
);

export const addGame = createAction( //TODO: se usa?
  'jsonGames/addGame',
  (game, getState) => {
    const state = getState();
    //console.log('state.jsonGames', state.jsonGames);
    let shouldAdd = state.jsonGames && !state.data.some( //TODO: revisar variable shouldAdd, sobra?
      (existingGame) => existingGame.id === game[0].id
      //console.log('existingGame:', existingGame),
      //console.log('existingGame:', game[0].id)
    );
    console.log('shouldAdd1:', shouldAdd);
    if (Array.isArray(game) && game.length === 0) {
      shouldAdd = false;
    }
    console.log('shouldAdd:', shouldAdd);
    return {
      payload: game,
      meta: { shouldAdd },
    };
  }
);

export const addCompetitions = createAction(
  'jsonCompetitions/addCompetitions',
  (competitions, getState) => {
    /*const state = getState();
    let shouldAdd = state.jsonCompetitions && !state.data.some(
      (existingCompetition) => existingCompetition.id === competitions[0].id
    );
    if (Array.isArray(competitions) && competitions.length === 0) {
      shouldAdd = false;
    }*/
    return {
      payload: competitions,
      //meta: { shouldAdd },
    };
  }
);

export const addCountries = createAction(
  'jsonCountries/addCountries',
  (countries, getState) => {
    return {
      payload: countries,
      //meta: { shouldAdd },
    };
  }
);

export const addTeams = createAction(
  'jsonTeams/addTeams',
  (teams, getState) => {
    return {
      payload: teams,
    };
  }
);

export const addMarkets = createAction(
  'jsonMarkets/addMarkets',
  (markets, getState) => {
    return {
      payload: markets,
    };
  }
);

export const addFullGames = createAction(
  'fullGames/addFullGames',
  (fullGames, getState) => {
    return {
      payload: fullGames,
    };
  }
);

export const resetFullGames = createAction(
  'fullGames/resetFullGames',
  () => {
    return {
      payload: fullGamesSlice.initialState,
    };
  }
);

export const addBetToBetslip = createAction(
  'betslip/addBetToBetslip',
  (betslip, getState) => {
    return {
      payload: betslip,
    };
  }
);

export const removeBetFromBetslip = createAction(
  'betslip/removeBetFromBetslip',
  (gameId) => {
    return {
      payload: gameId,
    };
  }
);

export const updateBetsFromBetslip = createAction(
  'betslip/updateBetsFromBetslip',
  (bets) => {
    return {
      payload: bets,
    };
  }
);

export const updateBetslip = createAction(
  'betslip/updateBetslip',
  (betslip) => {
    return {
      payload: betslip,
    };
  }
);

export const clearBetslip = createAction(
  'betslip/clearBetslip',
  () => {
    return {
      payload: null,
    };
  }
);

/*export const addUserBetsSets = createAction(
  'userBetsSets/addUserBetsSets',
  (userBetsSets) => {
    console.log('addUserBetsSets state.userBetsSets', userBetsSets);
    return {
      payload: userBetsSets,
    };
  }
);*/

export const addUserBetsSet = createAction(
  'userBetsSets/addUserBetsSet',
  (userBetsSet) => {
    console.log('addUserBetsSet state.userBetsSet', userBetsSet);
    return {
      payload: userBetsSet,
    };
  }
);

export const setUserBetsSetAsPaid = createAction(
  'userBetsSets/setUserBetsSetAsPaid',
  (userBetsSetId) => {
    console.log('setUserBetsSetAsPaid state.userBetsSetId', userBetsSetId);
    return {
      payload: userBetsSetId,
    };
  }
);

export const clearUserBetsSets = createAction(
  'userBetsSets/clearUserBetsSets',
  () => {
    console.log('clearUserBetsSets state.userBetsSet');
    return {
      payload: [],
    };
  }
);

export const updateWallet = createAction(
  'wallet/updateWallet',
  (wallet) => {
    console.log('state.updateWallet', wallet);
    return {
      payload: wallet,
    };
  }
);

export const updateWelcomeBonus = createAction(
  'welcomeBonus/updateWelcomeBonus',
  (welcomeBonus) => {
    console.log('state.updateWelcomeBonus', welcomeBonus);
    return {
      payload: welcomeBonus,
    };
  }
);

export const updateBonus = createAction(
  'bonus/updateBonus',
  (bonus) => {
    console.log('state.updateBonus', bonus);
    return {
      payload: bonus,
    };
  }
);

export const versionSlice = createSlice({
  name: 'version',
  initialState: {
    loading: false,
    error: null,
    data: null,
  },
  reducers: {
    addVersion: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
  }
});

export const lastConnectionSlice = createSlice({
  name: 'lastConnection',
  initialState: {
    loading: false,
    error: null,
    data: null,
  },
  reducers: {
    addLastConnection: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
  }
});

export const referralSlice = createSlice({
  name: 'referral',
  initialState: {
    loading: false,
    error: null,
    data: null,
  },
  reducers: {
    setReferralAddress: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state = null;
    }
  }
})

//TODO: añadir "AddSports" para almacenar los deportes tambien
//TODO: añadir "AddHashes" para almacenar los hashes tambien

export const sportsSlice = createSlice({
  name: 'sports',
  initialState: {
    loading: false,
    error: null,
    data: [],
  },
  reducers: {
    addSports: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
  }
});

export const gamesSlice = createSlice({
  name: 'jsonGames',
  initialState: {
    loading: false,
    error: null,
    data: [],
  },
  reducers: {
    addGame: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    /*resetGames: (state) => {
      state.data = [];
    },*/
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGameJsonFromIpfs.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchGameJsonFromIpfs.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      //state.data.push(action.payload);
      if (Array.isArray(action.payload)) {
        action.payload.forEach((game) => {
          if (game && !state.data.some((existingGame) => existingGame.id === game.id/* && existingGame.provId === game.provId*/)) {
            state.data.push(game);
          }
        });
      } /*else {
        if (action.payload && !state.data.some((existingGame) => existingGame.id === action.payload.id && existingGame.provId === action.payload.provId)) {
          state.data.push(action.payload);
        }
      }*/
    });
    builder.addCase(fetchGameJsonFromIpfs.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(addGame, (state, action) => {
      /*if (Array.isArray(state.data)) {
        const existingIndex = state.data.findIndex(obj => obj.id === action.payload.id);
        if (existingIndex === -1) {
          state.data.push(action.payload);
        }
      }*/
      if (action.meta && action.meta.shouldAdd) {
        if (action.meta.shouldAdd) {
          /*if (Array.isArray(action.payload)) {
            action.payload.forEach((game) => {
              console.log('game:'+game);
                console.log('state.data:'+state.data);
              if (game && !state.data.some((existingGame) => existingGame.id === game.id)) {
                state.data.push(game);
              }
            });
          }*/ /*else {
            if (action.payload && !state.data.some((existingGame) => existingGame.id === action.payload.id && existingGame.provId === action.payload.provId)) {
              state.data.push(action.payload);
            }
          }*/
        }
      }
    });
  },
});

export const competitionsSlice = createSlice({
  name: 'jsonCompetitions',
  initialState: {
    loading: false,
    error: null,
    data: {},
  },
  reducers: {
    addCompetitions: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCompetitionsJsonFromIpfs.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchCompetitionsJsonFromIpfs.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchCompetitionsJsonFromIpfs.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(addCompetitions, (state, action) => {
      //state.data.push(action.payload);
    });
  },
});

export const countriesSlice = createSlice({
  name: 'jsonCountries',
  initialState: {
    loading: false,
    error: null,
    data: [],
  },
  reducers: {
    addCountries: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCountriesJsonFromIpfs.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchCountriesJsonFromIpfs.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchCountriesJsonFromIpfs.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(addCountries, (state, action) => {
      //state.data.push(action.payload);
    });
  },
});

export const teamsSlice = createSlice({
  name: 'jsonTeams',
  initialState: {
    loading: false,
    error: null,
    data: {},
  },
  reducers: {
    addTeams: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTeamsJsonFromIpfs.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchTeamsJsonFromIpfs.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchTeamsJsonFromIpfs.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(addTeams, (state, action) => {
      //state.data.push(action.payload);
    });
  },
});

export const marketsSlice = createSlice({
  name: 'jsonMarkets',
  initialState: {
    loading: false,
    error: null,
    data: {},
  },
  reducers: {
    addMarkets: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMarketsJsonFromIpfs.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchMarketsJsonFromIpfs.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchMarketsJsonFromIpfs.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const fullGamesSlice = createSlice({
  name: 'fullGames',
  initialState: {
    loading: false,
    error: null,
    data: [],
  },
  reducers: {
    addFullGames: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    resetFullGames: (state) => {
      //const initialState = fullGamesSlice.initialState;
      state.loading = false//initialState.loading;
      state.error = null//initialState.error;
      state.data = []//initialState.data;
    }
  }
});

export const betslipSlice = createSlice({
  name: 'betslip',
  initialState: {
    loading: false,
    error: null,
    data: {
      combo: false,
      totalAmount: 0,
      totalComboAmount: 5,
      totalComboAmountWithoutBonus: 5,
      totalPossibleWinnings: 0,
      totalComboPossibleWinnings: 0,
      bets: [],
      isBonusApplied: false,
    },
  },
  reducers: {
    addBetToBetslip: (state, action) => {
      state.data.bets.push(action.payload);
      state.loading = false;
      state.error = null;
    },
    removeBetFromBetslip: (state, action) => {
      const gameIdToRemove = action.payload.gameId;
      const marketIdToRemove = action.payload.marketId;
      const outcomeIdToRemove = action.payload.outcomeId;
      state.data.bets = state.data.bets.filter(bet => 
        (bet.gameId !== gameIdToRemove) ||
        (bet.gameId === gameIdToRemove &&
        bet.marketId !== marketIdToRemove) ||
        (bet.gameId === gameIdToRemove &&
        bet.marketId === marketIdToRemove &&
        bet.outcomeId !== outcomeIdToRemove));
      state.loading = false;
      state.error = null;
    },
    updateBetsFromBetslip: (state, action) => {
      console.log('#slice updateBetsFromBetslip action.payload', action.payload)
      const bets = action.payload;
      state.data.bets = bets;
      state.loading = false;
      state.error = null;
    },
    updateBetslip: (state, action) => {
      console.log('#slice updateBetslip action.payload', action.payload)
      state.data.bets = action.payload.bets;
      state.data.combo = action.payload.combo;
      state.data.totalAmount = !isNaN(parseFloat(action.payload.totalAmount)) ? parseFloat(action.payload.totalAmount).toFixed(2) : 0;
      state.data.totalComboAmount = !isNaN(parseFloat(action.payload.totalComboAmount)) ? parseFloat(action.payload.totalComboAmount).toFixed(2) : 0;
      state.data.totalComboAmountWithoutBonus = !isNaN(parseFloat(action.payload.totalComboAmountWithoutBonus)) ? parseFloat(action.payload.totalComboAmountWithoutBonus).toFixed(2) : 0;
      state.data.totalPossibleWinnings = parseFloat(action.payload.totalPossibleWinnings).toFixed(2);
      state.data.totalComboOdds = parseFloat(action.payload.totalComboOdds).toFixed(2);
      state.data.totalComboPossibleWinnings = parseFloat(action.payload.totalComboPossibleWinnings).toFixed(2);
      state.data.isBonusApplied = action.payload.isBonusApplied;
      state.loading = false;
      state.error = null;
    },
    clearBetslip: (state) => {
      state.data = {
        combo: false,
        totalAmount: 0,
        totalComboAmount: 5,
        totalComboAmountWithoutBonus: 5,
        totalPossibleWinnings: 0,
        totalComboOdds: 0,
        totalComboPossibleWinnings: 0,
        bets: [],
        isBonusApplied: false,
      }
      state.loading = false;
      state.error = null;
    }
  }
});

export const userBetsSetsSlice = createSlice({
  name: 'userBetsSets',
  initialState: {
    loading: false,
    error: null,
    data: [],
  },
  reducers: {
    /*addUserBetsSets: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },*/
    addUserBetsSet: (state, action) => {
      const newBetsSet = action.payload
      const betsSetIndex = state.data.findIndex(betsSet => betsSet.id == newBetsSet.id)
      const betsSets = state.data

      if (newBetsSet.id !== null) {
        if (betsSetIndex === -1) {
          //state.data.push(action.payload);
          betsSets.push(newBetsSet)
        } else if (betsSetIndex !== -1
          && JSON.stringify(state.data[betsSetIndex]) !== JSON.stringify(newBetsSet)) {
          betsSets[betsSetIndex] = newBetsSet
        }
      }
      betsSets.sort((a,b) => { return parseInt(b.id) - parseInt(a.id) })
      state.data = betsSets
      state.loading = false;
      state.error = null;
    },
    setUserBetsSetAsPaid: (state, action) => {
      const betsSetId = action.payload
      state.data = state.data.map(betsSet => {
        if (betsSet.id === betsSetId) {
          return {...betsSet, paid: true}
        }
        return betsSet
      })
      state.loading = false;
      state.error = null;
    },
    clearUserBetsSets: (state) => {
      state.data = []
      state.loading = false;
      state.error = null;
    }
  }
});

export const walletSlice = createSlice({
  name: 'wallet',
  initialState: {
    loading: false,
    error: null,
    data: {
      network: {
        keyName: null,
        name: null,
        networkId: null,
        TwRpc: null
      },
      address: null,
      isConnected: false,
      balance: null,
      tokenDecimals: null,
      tokenSymbol: null,
      allowance: null,
    },
  },
  reducers: {
    updateWallet: (state, action) => {
      //const wallet = action.payload;
      state.data = {allowance: state.data.allowance, ...action.payload};
      state.loading = false;
      state.error = null;
    }
  }
});

export const welcomeBonusSlice = createSlice({
  name: 'welcomeBonus',
  initialState: {
    loading: false,
    error: null,
    data: {
      isEnabled: false,
      isApplied: false,
      isEnjoyed: false,
      isUserApplicableForBonus: false,
      discordNickname: '',
      ip: '',
      bonusMultiplier: 0
    },
  },
  reducers: {
    updateWelcomeBonus: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    }
  }
});

export const bonusSlice = createSlice({
  name: 'bonus',
  initialState: {
    loading: false,
    error: null,
    data: {
      isEnabled: false,
      //isApplied: false,
      isUserApplicableForBonus: false,
      bonusAmount: 0
    },
  },
  reducers: {
    updateBonus: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    }
  }
});

//export const { addGames/*, resetGames*/ } = gamesSlice.actions;
//export const { resetCompetitions } = competitionsSlice.actions;

//export const selectJsonGames = (state) => state.jsonGames.data;
//export default gamesSlice.reducer;
//export default competitionsSlice.reducer;
