import axios from 'axios';

const axiosInstance = axios.create({
    timeout: 0 // Establecemos timeout en 0 para que no espere la respuesta del servidor
});

export const putUserInfo = /* async */ (props) => {
    const userInfo = props
    console.log('putUserInfo.js ',userInfo);
    if (userInfo) {
        try {
            /* const response = await  */axiosInstance.post(`${process.env.REACT_APP_BACKEND_SERVER}/statistics`, { userInfo })
            /* console.log('putUserInfo.js response',response);
            return response.data; */
            return true
        } catch (error) {
            console.error(error);
            return false
        }
    } else {
        console.error('There is no userinfo to send to the server.');
        return false;
    }
}