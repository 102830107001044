import { useEffect, useState } from "react";

const Exchange = () => {
  const [windowHeight, setWindowHeight] = useState(0); //TODO: probar a mover el windowHeigh y su useEffect dentro de App.js

  useEffect(() => {
    setWindowHeight(window.innerHeight);
    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  const updateDimensions = () => {
    setWindowHeight(window.innerHeight);
  };

  return (
    <div id='main-parent-container'>
      <div id="main-container" style={{ minHeight: windowHeight }}>
        <div id="main-betssets-container" className="main-div mt-16">
          <div className="main-header">
            <div className="main-header-title">{'Exchange cryptocurrency (Powered by Uniswap)'}</div>
          </div>
          
        </div>
        <>
        <iframe
          src="https://app.uniswap.org/#/swap?inputCurrency=0x0000000000000000000000000000000000001010&outputCurrency=0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063"
          height="700px"
          width="100%"
          style={{
            border: '0',
            margin: '0 auto',
            display: 'block',
            borderRadius: '10px',
            maxWidth: '600px',
            minWidth: '300px',
          }}
        />
        </>
      </div>
    </div>
  );
};

export default Exchange;