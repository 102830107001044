import * as slice from '../utils/slice.js';
import tokenAbi from '../abi/token-abi.json';
import hashesAbi from '../abi/hashes-abi.json';
import coreAbi from '../abi/core-abi.json';
import liquidityAbi from '../abi/liquidity-abi.json';
import welcomeBonusAbi from '../abi/welcomeBonus-abi.json';
import bonusAbi from '../abi/bonus-abi.json';
import networkConfig from './networkConfig.json'; //TODO: antes de enviar a produccion, cargar las direcciones de los SC del proxy SC
import * as utils from '../utils/utils.js';

import { ethers } from 'ethers';
//import { useMetamask } from "@thirdweb-dev/react";


export const SetNetworkConfig = (_network) => {
    
    
    /*const config = {
        provider: null,
        walletProvider: null,
        signer: null,
        network: null,
        tokenContract: null,
        coreContract: null,
        liquidityContract: null
    }*/
    
    let selectedNetwork
    const network = {}
    console.log('selectedNetwork',_network)

    switch(_network){
        case "local":
            selectedNetwork = networkConfig.local;
            network.keyName = _network
            break;
        case "ganache":
            selectedNetwork = networkConfig.ganache;
            network.keyName = _network
            break;
        case "mumbai":
            selectedNetwork = networkConfig.mumbai;
            network.keyName = _network
            break;
        case "polygon":
            selectedNetwork = networkConfig.polygon;
            network.keyName = _network
            break;
        case "base-sepolia-testnet":
            selectedNetwork = networkConfig.baseSepolia;
            network.keyName = _network
            break;
        default:
            selectedNetwork = networkConfig.polygon;
            network.keyName = process.env.REACT_APP_DEFAULT_NETWORK_KEYNAME
    }

    if (process.env.NODE_ENV === "development" && network.keyName === 'base-sepolia-testnet') {
        //network.name = selectedNetwork.name
        network.customRpc = selectedNetwork.rpc+process.env.REACT_APP_BASE_SEPOLIA_ANKR_API_KEY;
        network.networkId = selectedNetwork.networkId
    } else if (process.env.NODE_ENV === "production" && network.keyName === 'polygon') {
        //network.name = selectedNetwork.name
        network.customRpc = selectedNetwork.rpc+process.env.REACT_APP_POLYGON_ANKR_API_KEY;
        network.networkId = selectedNetwork.networkId
    }
    console.log('network.keyName',network.keyName)
    console.log('network.customRpc',network.customRpc)
    console.log('network.networkId',network.networkId)

    const provider = new ethers.providers.JsonRpcProvider(network.customRpc, network.networkId);
    const tokenAddress = selectedNetwork.tokenAddress;
    const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, provider);
    const hashesAddress = selectedNetwork.hashesAddress;
    const hashesContract = new ethers.Contract(hashesAddress, hashesAbi, provider);
    const coreAddress = selectedNetwork.coreAddress;
    const coreContract = new ethers.Contract(coreAddress, coreAbi, provider);
    const liquidityAddress = selectedNetwork.liquidityAddress;
    const liquidityContract = new ethers.Contract(liquidityAddress, liquidityAbi, provider);
    const welcomeBonusAddress = selectedNetwork.welcomeBonusAddress;
    const welcomeBonusContract = new ethers.Contract(welcomeBonusAddress, welcomeBonusAbi, provider);
    const bonusAddress = selectedNetwork.bonusAddress;
    const bonusContract = new ethers.Contract(bonusAddress, bonusAbi, provider);
    //const connectWithMetamask = useMetamask();
    
    try {
        //const walletProvider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner()
        //console.log("A");
        //console.log('#provider: ',provider);

        /*if(provider){
            config.provider = provider,
            config.walletProvider = walletProvider,
            config.signer = signer,
            config.network = network,
            config.tokenContract = tokenContract,
            config.coreContract = coreContract,
            config.liquidityContract = liquidityContract
        }
        return config*/
        return {
            provider: provider,
            //walletProvider: walletProvider,
            signer: signer,
            network: network,
            tokenAbi: tokenAbi,
            tokenAddress: tokenAddress,
            tokenContract: tokenContract,
            hashesAbi: hashesAbi,
            hashesAddress: hashesAddress,
            hashesContract: hashesContract,
            coreAbi: coreAbi,
            coreAddress: coreAddress,
            coreContract: coreContract,
            liquidityAbi: liquidityAbi,
            liquidityAddress: liquidityAddress,
            liquidityContract: liquidityContract,
            welcomeBonusAbi: welcomeBonusAbi,
            welcomeBonusAddress: welcomeBonusAddress,
            welcomeBonusContract: welcomeBonusContract,
            bonusAbi: bonusAbi,
            bonusAddress: bonusAddress,
            bonusContract: bonusContract,
            //connectWithMetamask: connectWithMetamask()
        }
    } catch (error) {
        /*const config = {}
        config.provider = null,
        config.walletProvider = null,
        config.signer = null,
        config.network = null,
        config.tokenContract = null,
        config.coreContract = null,
        config.liquidityContract = null*/

        console.log('ERROR',error)
        //console.log('CONFIG',config)
        return {
            provider: provider,
            walletProvider: null,
            signer: null,
            network: network,
            tokenContract: tokenContract,
            hashesContract: hashesContract,
            coreContract: coreContract,
            liquidityContract: liquidityContract,
            welcomeBonusContract: welcomeBonusContract,
            bonusContract: bonusContract,
            //connectWithMetamask: connectWithMetamask()
        }
    }
}

export const web3Config = (_network = utils.defaultNetwork.keyName) => {
    return SetNetworkConfig(_network); //TODO: change this when go to production
}

/*export const connectWallet = async () => {
    if (window.ethereum) {
        try {
            await window.ethereum.request({ method: 'eth_requestAccounts' });
            const provider = await web3Config.walletProvider
            const signer = provider.getSigner();
            const address = await signer.getAddress()
            setWallet(signer)
            setIsConnected(true)
            disconnectListener()
            console.log('Connected to wallet:', address);
            provider.on('disconnect', disconnectWallet);
        } catch (error) {
            console.error('Error connecting to wallet:', error);
        }
    } else {
        console.error('Wallet is not available');
    }
};*/

export const handleConnectWallet = async (dispatch, wallet, walletAddress) => {
    console.log('isConnected', wallet.isConnected)
    if (wallet.isConnected) {
        await disconnectWallet(dispatch)
    } else {
        await connectWallet(dispatch, walletAddress)
    }
}

const connectWallet = async (dispatch, walletAddress) => {

    console.log('connectWallet')
    //if (window.ethereum) {
        try {
            /*if (window.ethereum) {
                await window.ethereum.request({ method: 'eth_requestAccounts' });
                const provider = await web3Config.walletProvider
                const signer = provider.getSigner();
                //console.log('signer',signer)
                const address = await signer.getAddress()
                const network = await web3Config.network
                const _wallet = {network: network, address: address, isConnected: true, balance: null, tokenDecimals: null, tokenSymbol: null}
                //setWallet(signer)
                //setIsConnected(true)
                dispatch(slice.updateWallet(_wallet))
                disconnectListener()
                console.log('Connected to wallet:', address);
                provider.on('disconnect', disconnectWallet);


            } else {
                
            }*/

            //web3Config.connectWithMetamask()
            /*const _wallet = {network: network, address: address, isConnected: true, balance: null, tokenDecimals: null, tokenSymbol: null}
            dispatch(slice.updateWallet(_wallet))*/
        } catch (error) {
            //console.error('Error connecting to wallet:', error);

            return;
        }
    /*} else {
        console.error('Wallet is not available', window.ethereum);
        //alert('Wallet is not available');
    }*/
};

const disconnectWallet = (dispatch) => {
    console.log('disconnectWallet')
    /*setIsConnected(false);
    setWallet(null);*/
    const _wallet = {address: null, isConnected: false, balance: null, tokenDecimals: null, tokenSymbol: null}
    dispatch(slice.updateWallet(_wallet))
    console.log('Disconnected from wallet');
};

const disconnectListener = () => {
    console.log('disconnectListener')
    if (window.ethereum && window.ethereum.on) {
    window.ethereum.on('accountsChanged', (accounts) => {
        if (accounts.length === 0) {
            /*setIsConnected(false);
            setWallet(null);*/
            const _wallet = {signer: null, address: null, isConnected: false}
            console.log('Wallet has been disconnected.');
            console.log('isConnected', false);
        }
    });
    }
};

const getTokenDecimals = async (wallet) => {
    //console.log('getTokenDecimals wallet:',wallet)
    if (window.ethereum && window.ethereum.on) {
        try {
            if (wallet && wallet.isConnected) {
                const tokenContract = web3Config.tokenContract
                const decimals = parseInt(await tokenContract.decimals())
                return decimals
            }
        } catch (error) {
            console.error('Error getting balance:', error);
        }
    }
}

const getTokenSymbol = async (wallet) => {
    console.log('getTokenSymbol1 wallet:',wallet)
    if (window.ethereum && window.ethereum.on) {
        try {
            if (wallet && wallet.isConnected) {
                const tokenContract = web3Config.tokenContract
                const symbol = await tokenContract.symbol()
                console.log('getTokenSymbol2 wallet:',wallet)
                return symbol
            }
        } catch (error) {
            console.error('Error getting balance:', error);
        }
    }
}

export const getAccountBalance = async (dispatch, wallet) => {
    console.log('getAccountBalance wallet:',wallet)
    if (window.ethereum && window.ethereum.on) {
        try {
            if (wallet && wallet.isConnected) {
                const tokenContract = web3Config.tokenContract
                const balance = parseInt(await tokenContract.balanceOf(wallet.address))
                const tokenDecimals = !wallet.tokenDecimals ?
                    parseInt(await getTokenDecimals(wallet)) :
                    wallet.tokenDecimals
                const tokenSymbol = !wallet.tokenSymbol ?
                    await getTokenSymbol(wallet) :
                    wallet.tokenSymbol
                const formattedBalance = parseFloat(balance/(10**tokenDecimals)).toFixed(2)
                console.log(`Account balance of ${wallet.address} is: ${tokenSymbol}${balance} and decimals is: ${tokenDecimals}`);
                console.log(`Account Formatted balance of ${wallet.address} is: ${tokenSymbol}${formattedBalance} and decimals is: ${tokenDecimals}`);
                const _wallet = {...wallet, balance: formattedBalance, tokenDecimals: tokenDecimals, tokenSymbol: tokenSymbol}
                dispatch(slice.updateWallet(_wallet))
            }
        } catch (error) {
            console.error('Error getting balance:', error);
        }
    }
}