import React, { useState, useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import * as slice from '../../utils/slice.js';
import * as utils from '../../utils/utils.js';
import * as ethersConfig from '../../config/ethersConfig.js';
import { ethers } from 'ethers';

import {
    useConnect,
    useDisconnect,
    useAddress,
    useChain,
    useConnectionStatus,
    useNetworkMismatch,
    useSwitchChain,
    metamaskWallet,
    walletConnect,
    ConnectWallet
} from "@thirdweb-dev/react";
import fetchTokenWalletData from '../../services/fetchTokenWalletData.js';
import fetchWelcomeBonusData from '../../services/fetchWelcomeBonusData.js';
import fetchBonusData from '../../services/fetchBonusData.js';
const metamask = metamaskWallet();
const walletconnect = walletConnect();

const ConnectButton = (props) => {
    const defaultNetwork = utils.defaultNetwork.keyName;
    const web3Config = ethersConfig.web3Config(defaultNetwork);
    const liquidityContract = web3Config.liquidityContract;
    const connect = useConnect();
    const disconnect = useDisconnect();
    const walletAddress = useAddress()
    const walletChain = useChain()
    const walletStatus = useConnectionStatus()
    const isMismatched = useNetworkMismatch()
    const switchChain = useSwitchChain()
    const dispatch = useDispatch();
    const { data: wallet } = useSelector(state => state.wallet);
    const { data: welcomeBonus } = useSelector(state => state.welcomeBonus);
    const { data: bonus } = useSelector(state => state.bonus);
    const [isAccountVisible, setIsAccountVisible] = useState(false);

    //console.log('_walletStatus',walletStatus)
    //console.log('_isMismatched',isMismatched)

    /*useEffect(() => { //SOLO PARA HACER CONSOLE.LOG
        if (wallet) {
            console.log('#wallet global',wallet)

        }
    }, [wallet])*/

    useEffect(() => {
        console.log('&&ConnectButton.js1 walletAddress OR walletChain has changed', walletAddress, walletChain)
        const fetchData = async () => {
            if (walletAddress && walletChain) {
                const _wallet = await updateWallet()
                console.log('#wallet',_wallet)
                console.log('&&ConnectButton.js2 walletAddress OR walletChain has changed', walletAddress, walletChain)
                dispatch(slice.updateWallet(_wallet))
                //ethersConfig.handleConnectWallet(dispatch, wallet, walletAddress)
                setIsAccountVisible(false)
            }
        };

        fetchData();
    }, [walletAddress, walletChain])

    useEffect(() => {
        console.log('&&ConnectButton.js1 walletAddress OR walletChain has changed', walletAddress, walletChain)
        const fetchData = async () => {
            if (walletAddress && walletChain) {
                const _welcomeBonus = await updateWelcomeBonus()
                console.log('#welcomeBonus',_welcomeBonus)
                dispatch(slice.updateWelcomeBonus(_welcomeBonus))
            }
        };

        fetchData();
    }, [wallet.address, walletChain])
    
    useEffect(() => {
        console.log('&&ConnectButton.js1 walletAddress OR walletChain has changed', walletAddress, walletChain)
        const fetchData = async () => {
            if (walletAddress && walletChain) {
                const _bonus = await updateBonus()
                console.log('#bonus',_bonus)
                dispatch(slice.updateBonus(_bonus))
            }
        };

        fetchData();
    }, [wallet.address, walletChain])

    useEffect(() => {
        console.log('walletStatus',walletStatus)
        console.log('isMismatched1',isMismatched)
        if (walletStatus === 'disconnected') {
            disconnectWallet()
        } else if (walletStatus === 'connected') {
            //console.log('isMismatched2',isMismatched)
            //console.log('network.networkId = ',/*web3Config.network.networkId*/80001)
            //try {
                //console.log('isMismatched3 trying to switch network')
                if (isMismatched) switchChain(web3Config.network.networkId)
                console.log('Betslip.js isMismatch',isMismatched)
            /*} catch (error) {
                console.log(error)
                return;
            }*/
        }
        
    }, [walletStatus, isMismatched])

    const updateWallet = async () => { //TODO: externalizar a utils
        console.log('__updateWallet1')
        try {
            //const tokenWalletData = async () => {
                console.log('__updateWallet2')
                //if (wallet.network.keyName) {
                    const tokenWalletData = await fetchTokenWalletData({
                        tokenContract: web3Config.tokenContract,
                        liquidityAddress: liquidityContract.address,
                        walletAddress: walletAddress,
                        dataToFetch: ['all']
                    })
                    /* const _tokenWalletData = {
                        balance: tokenWalletData.balance,
                        allowance: tokenWalletData.allowance,
                        tokenDecimals: tokenWalletData.tokenDecimals,
                        tokenSymbol: tokenWalletData.tokenSymbol
                    } */
                    console.log('fetchTokenWalletData tokenWalletData',tokenWalletData)
                    //return _tokenWalletData;

                    return {
                        ...wallet,
                        //..._tokenWalletData,
                        network: {
                            keyName: walletChain.slug,
                            name: walletChain.name,
                            networkId: web3Config.network.networkId, //TODO: El id de la red deberia obtenerse de la red conectada desde la libreria de ThirdWeb
                            TwRpc: walletChain.rpc
                        },
                        address: walletAddress,
                        isConnected: true,
                        balance: tokenWalletData.balance !== null && tokenWalletData.balance !== undefined ? tokenWalletData.balance : wallet.balance ? wallet.balance : null,
                        tokenDecimals: tokenWalletData.tokenDecimals ? tokenWalletData.tokenDecimals : wallet.tokenDecimals ? wallet.tokenDecimals : null,
                        tokenSymbol: tokenWalletData.tokenSymbol ? tokenWalletData.tokenSymbol : wallet.tokenSymbol ? wallet.tokenSymbol : null,
                        allowance: tokenWalletData.allowance !== null && tokenWalletData.allowance !== undefined ? tokenWalletData.allowance : wallet.allowance ? wallet.allowance : null,
                    }
                //}
            //}

            //await tokenWalletData()
            /* return {
                ...wallet,
                network: {
                    keyName: walletChain.slug,
                    name: walletChain.name,
                    networkId: walletChain.networkId,
                    TwRpc: walletChain.rpc
                },
                address: walletAddress,
                isConnected: true,
                balance: tokenWalletData ?? tokenWalletData.balance,
                tokenDecimals: tokenWalletData ?? tokenWalletData.tokenDecimals,
                tokenSymbol: tokenWalletData ?? tokenWalletData.tokenSymbol,
                allowance: tokenWalletData ?? tokenWalletData.allowance
            } */
        } catch (error) {
            console.error("Error fetching token wallet data", error);
            return null;
        }
    }

    const updateWelcomeBonus = async () => { //TODO: externalizar a utils/custom hook
        console.log('__updateWB1')
        try {
            console.log('__updateWB2', web3Config.welcomeBonusContract, wallet.address)
            const welcomeBonusData = await fetchWelcomeBonusData({
                welcomeBonusContract: web3Config.welcomeBonusContract,
                walletAddress: wallet.address,
                dataToFetch: ['all']
            })
            console.log('fetchWelcomeBonusData ',welcomeBonusData)
            //return _tokenWalletData;

            return {
                ...welcomeBonus,                    
                isEnabled: welcomeBonusData.isEnabled !== null && welcomeBonusData.isEnabled !== undefined ? welcomeBonusData.isEnabled : welcomeBonus.isEnabled ? welcomeBonus.isEnabled : false,
                isEnjoyed: welcomeBonusData.isEnjoyed !== null && welcomeBonusData.isEnjoyed !== undefined ? welcomeBonusData.isEnjoyed : welcomeBonus.isEnjoyed ? welcomeBonus.isEnjoyed : false,
                isUserApplicableForBonus: welcomeBonusData.isUserApplicableForBonus !== null && welcomeBonusData.isUserApplicableForBonus !== undefined ? welcomeBonusData.isUserApplicableForBonus : welcomeBonus.isUserApplicableForBonus ? welcomeBonus.isUserApplicableForBonus : false,
                bonusMultiplier: welcomeBonusData.bonusMultiplier !== null && welcomeBonusData.bonusMultiplier !== undefined ? welcomeBonusData.bonusMultiplier : welcomeBonus.bonusMultiplier ? welcomeBonus.bonusMultiplier : 0,
            }
        } catch (error) {
            console.error("Error fetching token wallet data", error);
            return null;
        }
    }

    const updateBonus = async () => {
        console.log('__updateBonus1')
        try {
            console.log('__updateBonus2', web3Config.bonusContract, wallet.address)
            const bonusData = await fetchBonusData({
                bonusContract: web3Config.bonusContract,
                walletAddress: wallet.address,
                dataToFetch: ['all']
            })
            console.log('fetchBonusData ',bonusData)
            //return _tokenWalletData;

            return {
                ...bonus,                    
                isEnabled: bonusData.isEnabled !== null && bonusData.isEnabled !== undefined ? bonusData.isEnabled : bonus.isEnabled ? bonus.isEnabled : false,
                isUserApplicableForBonus: bonusData.isUserApplicableForBonus !== null && bonusData.isUserApplicableForBonus !== undefined ? bonusData.isUserApplicableForBonus : bonus.isUserApplicableForBonus ? bonus.isUserApplicableForBonus : false,
                bonusAmount: bonusData.bonusAmount !== null && bonusData.bonusAmount !== undefined ? bonusData.bonusAmount : bonus.bonusAmount ? bonus.bonusAmount : 0,
            }
        } catch (error) {
            console.error("Error fetching token wallet data", error);
            return null;
        }
    }
    
    const toggleAccountVisible = () => {
        setIsAccountVisible(prevState => !prevState);
    }

    const ShowAccountOpenCloseButton = () => {
        if (isAccountVisible) {
            return (
                <FontAwesomeIcon icon={icon({name: 'xmark'})} />
            )
        }
    }

    const goTo = (event, page) => {
        console.log(event)
        const goTo = utils.buildSingleFullUrl(wallet.network.keyName, page)
        console.log('goTo',wallet.network)
        event.stopPropagation();
        window.location.href = goTo;
    };

    /*useEffect(() => {
        document.addEventListener("DOMContentLoaded", connectWallet);
        return () => {
          document.removeEventListener("DOMContentLoaded", connectWallet);
        };
      }, []);*/

    const connectWallet = async () => {
        /*try {
            let walletToConnect
            if (window.ethereum) {
                walletToConnect = metamask
            } else {
                walletToConnect = walletconnect
            }
            const tWWallet = await connect(walletToConnect);
            console.log("connected to ", tWWallet);
            console.log("walletAddress ",walletAddress)
            console.log("walletChain ",walletChain)

            if (walletAddress) {
                const _wallet = initWallet()
                
                
                dispatch(slice.updateWallet(_wallet))
                //ethersConfig.handleConnectWallet(dispatch, wallet, walletAddress)
                setIsAccountVisible(false)
            }
        } catch (error) {
            console.log(error)
            return;
        }*/
        //let walletToConnect
        /*if (window.ethereum) {
            walletToConnect = metamask
        } else {
            walletToConnect = await walletconnect
        }*/
        /*if (window.ethereum) {
            console.log('window.ethereum')
        } else {
            console.log('false')
        }*/
        const tWWallet = /*window.ethereum ? await connect(metamask) :*/ await connect(walletconnect)
        //console.log("connected to ", tWWallet);
        //console.log("walletAddress", walletAddress);
    }

    const disconnectWallet = () => {
        disconnect()
        const _wallet = utils.initWallet()
        dispatch(slice.updateWallet(_wallet))
        dispatch(slice.clearUserBetsSets())
        //console.log('Disconnected from wallet');
    }

    const ShowConnectButton = () => {
        const connectButtonClassName = 'btn-small bg-gradient-yellow-orange br-5 m-16 pt-0 pr-12 pb-0 pl-12 h-32 lh-32 ' + (walletStatus === 'unknown' || walletStatus === 'connecting' ? 'btn-connect btn-connecting' : 'btn-connect')
        //console.log('##wallet:',wallet)
        if (wallet && !wallet.isConnected/*!walletAddress*/) {
            return (
                <ConnectWallet
                    className={connectButtonClassName}
                    detailsBtn={() => {
                        <button 
                        /*onClick=/*{async () => {
                            const wallet = await connect(walletconnect);
                            console.log("connected to ", wallet);
                        }}*//*{async () => {await connectWallet()}}*/>
                            {'Connect wallet'}
                        </button>
                    }}
                    modalSize={'compact'}
                    modalTitle={"Connect wallet"}
                    modalTitleIconUrl={""}
                    showThirdwebBranding={false}
                />
            )
        } else if (wallet && wallet.isConnected/*walletAddress*/) {
            const accountPanelButtonClassName = 'btn-medium btn-my-account bg-light-grey br-5 m-16 h-40 lh-40 '    
            return (
                <>
                    <button className = {connectButtonClassName}
                        onClick={() => toggleAccountVisible()}>
                        <span className = 'pr-12 dp-none dp-block-mobile'><ShowUserIcon /></span>
                        <span className = 'pr-12 dp-none-mobile'>{'Connected'}</span>
                        <span className = 'vertical-left-line-dark-orange'><ShowDownArrowButton /></span>
                    </button>

                    <div id='account-modal' className={isAccountVisible ? 'modal-panel' : 'dp-none'} >
                        <div id='account-overlap-background' className={isAccountVisible ? 'overlap-background' : 'dp-none'} onClick={() => setIsAccountVisible(false)}>
                            <div id='account-open-close-button' className='responsive-open-close-button' /*onClick={toggleAccountVisible}*/>
                                <ShowAccountOpenCloseButton />
                            </div>
                        </div>
                        <div id='account-submodal' className={isAccountVisible ? 'modal-subpanel' : 'dp-none'}>
                            <div id='account-open-close-button' className='open-close-button' onClick={() => setIsAccountVisible(false)}>
                                <ShowAccountOpenCloseButton />
                            </div>
                            <p><span id='account-panel-title'>Address: {walletAddress ? utils.getShortWalletAddress(walletAddress) : ''}</span></p>
                            <div className='account-subpanel-button-div mt-12'>
                                <button className = {accountPanelButtonClassName}
                                    onClick={(event) => goTo(event, 'get-token')}>
                                    <span className = ''><ShowAccountPanelGetTokenIcon /></span>
                                    <span className = ''>{'Get DAI'}</span>
                                </button>
                                <button className = {accountPanelButtonClassName}
                                    onClick={(event) => goTo(event, 'my-bets')}>
                                    <span className = ''><ShowAccountPanelMyBetsIcon /></span>
                                    <span className = ''>{'My Bets'}</span>
                                </button>
                            {/* </div>
                            <div className='account-subpanel-button-div'> */}
                                <button className = {accountPanelButtonClassName}
                                    onClick={() => disconnectWallet()}>
                                    <span className = ''><ShowAccountPanelDisconnectIcon /></span>
                                    <span className = ''>{'Disconnect'}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }

    const ShowDownArrowButton = () => {
        const accountClassName = 'ml-12 dp-block'
        return (
            <FontAwesomeIcon icon={icon({name: 'sort-down'})} className = {accountClassName} />
        )
    }

    const ShowUserIcon = () => {
        const accountClassName = ''
        return (
            <FontAwesomeIcon icon={icon({name: 'user'})} className = {accountClassName} />
        )
    }

    const ShowAccountPanelMyBetsIcon = () => {
        const accountClassName = 'mt-10 dp-block'
        return (
            <FontAwesomeIcon icon={icon({name: 'ticket'})} className = {accountClassName} />
        )
    }

    const ShowAccountPanelDisconnectIcon = () => {
        const accountClassName = 'mt-10 dp-block'
        return (
            <FontAwesomeIcon icon={icon({name: 'right-from-bracket'})} className = {accountClassName} />
        )
    }
    
    const ShowAccountPanelGetTokenIcon = () => {
        const accountClassName = 'mt-10 dp-block'
        return (
            <FontAwesomeIcon icon={icon({name: 'coins'})} className = {accountClassName} />
        )
    }

    if (wallet) {
        return (
            <ShowConnectButton />
        );
    }
}

export default memo(ConnectButton);
