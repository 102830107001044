import axios from "axios";

export const fetchUserIp = async () => {
  try {
    const response = await axios.get(
      `https://ipinfo.io/json?token=${process.env.REACT_APP_IPINFO_KEY}`
    );
    const userIp = response.data;
    console.log("fetchUserIp:", userIp);

    return userIp;
  } catch (error) {
    console.error("Error getting IP information:", error);
  }
};
