import { useEffect, useRef, useState } from "react";
import LeftMenu from "../LeftMenu/LeftMenu";
import Navbar from "../Navbar/Navbar";
import { Outlet } from "react-router-dom";
import IpBlocker from "../IpBlocker/IpBlocker";
import { fetchUserIp } from "../../services/fetchUserIp";
import { StatisticsProvider } from "../Statistics/StatisticsProvider";
import { useDispatch, useSelector } from "react-redux";
import { LoaderProvider } from "../../context/loader";

import * as utils from '../../utils/utils.js';
import * as ethersConfig from '../../config/ethersConfig.js';
import * as slice from '../../utils/slice.js';
import sendDiscordUserToken from '../../services/sendDiscordUserToken.js'
import useGetURLParam from '../../components/utils/useGetURLParam.js'
import fetchGlobalObject from "../../services/fetchGlobalObject.js";
import fetchSportsHashes from "../../services/fetchSportsHashes.js";
import fetchSports from "../../services/fetchSports.js";
import fetchCompetitions from "../../services/fetchCompetitions.js";
import fetchTeams from "../../services/fetchTeams.js";
import fetchMarkets from "../../services/fetchMarkets.js";
import wrappedFullGames from "../../services/fetchGames.js";
import fetchWelcomeBonusData from "../../services/fetchWelcomeBonusData.js";
import fetchCountries from "../../services/fetchCountries.js";

const Layout = ({ children }) => {
    const dispatch = useDispatch();

    const globalSports = useSelector((state) => state.sports.data);
    const globalCountries = useSelector((state) => state.jsonCountries.data);
    const globalCompetitions = useSelector((state) => state.jsonCompetitions.data);
    const globalTeams = useSelector((state) => state.jsonTeams.data);
    const globalMarkets = useSelector((state) => state.jsonMarkets.data);
    const globalFullGames = useSelector((state) => state.fullGames.data);

    console.log('globalFullGames',globalFullGames)
    console.log('globalCompetitions',globalCompetitions)
    console.log('globalTeams',globalTeams)
    console.log('globalMarkets',globalMarkets)
    console.log('globalCountries',globalCountries)
    console.log('globalSports',globalSports)

    const { data: wallet } = useSelector(state => state.wallet);
    const { data: welcomeBonus } = useSelector(state => state.welcomeBonus);
    console.log('welcomeBonus',welcomeBonus)

    const [shouldLoadAnyGlobalData, setShouldLoadAnyGlobalData] = useState(false);
    const [shouldLoadTokenWalletData, setShouldLoadTokenWalletData] = useState(false);
    const [shouldGoToHome, setShouldGoToHome] = useState(false);
    const [shouldLoadWelcomeBonusData, setShouldLoadWelcomeBonusData] = useState(true);

    const referral = useGetURLParam('referral')
    if (referral !== undefined) {
        dispatch(slice.setReferralAddress(referral))
    }

    const discordUserToken = utils.getDiscordUserToken()
    
    const [isCurrentVersionGreater, setIsCurrentVersionGreater] = useState(false)
    const { data: version } = useSelector(state => state.version);
    console.log('version A',version)

    const { data: lastConnection } = useSelector(state => state.lastConnection);
    console.log('_lastConnectionTimestamp A',lastConnection)
    const currentTimestamp = utils.currentTimestamp
    console.log('currentTimestamp',currentTimestamp)

    const navBarRef = useRef(undefined);
    const [isLeftMenuToggleOn, setIsLeftMenuToggleOn] = useState(false);
    const [userIp, setUserIp] = useState(undefined);

    const network = wallet.network.keyName
        ? wallet.network.keyName
        : utils.defaultNetwork.keyName
    //const [network, setNetwork] = useState(utils.defaultNetwork.keyName) //TODO: se puede prescindir de useState, ya que defaultNetwork proviene de una variable de entorno que nunca cambia
    console.log('Layout.js network',network)
    const web3Config = ethersConfig.web3Config(network);
    const hashesContract = web3Config.hashesContract;
    const coreContract = web3Config.coreContract;
    const welcomeBonusContract = web3Config.welcomeBonusContract;

    console.log('Layout.js web3Config1',web3Config)
    

    /* const [balance, setBalance] = useState(null);
    const [decimals, setDecimals] = useState(null);
    const [symbol, setSymbol] = useState(null); */

    /* const checkIfTimeLapsed = () => {
        const timeLapsed = utils.timeLapsedFromLastConnection(lastConnection)
        return timeLapsed;
    }

    useEffect(() => {
        const timeLapsed = checkIfTimeLapsed()
        const isTimeLapsed = timeLapsed.minutesLapsed > 30 ? true : false
        if (isTimeLapsed) { 
            dispatch(slice.resetFullGames()); //, () => globalFullGames
        }
    }, [lastConnection]) */

    useEffect(() => {
        if (globalSports.length === 0
            || globalCountries.length === 0
            || Object.keys(globalCompetitions).length === 0
            || Object.keys(globalTeams).length === 0
            || Object.keys(globalMarkets).length === 0)
        {
            console.log('Layout.js shouldLoadAnyGlobalData',shouldLoadAnyGlobalData)
            setShouldLoadAnyGlobalData(true);
        }
    }, []);

    /* useEffect(() => {
        const fetchGlobalData = async () => {
            if (shouldLoadAnyGlobalData && hashesContract && coreContract) {
                const _globalObject = await fetchGlobalObject(hashesContract);
                const _sports = await fetchSports(coreContract);
                if (_globalObject && _sports) {
                    const _sportsHashes = await fetchSportsHashes(hashesContract, _sports);
                    console.log('_&_ sportsHashes',_sportsHashes)

                    const _competitions = await fetchCompetitions(_sportsHashes);
                    const _teams = await fetchTeams(_sportsHashes);
                    const _markets = await fetchMarkets(_sportsHashes);
                    
                    console.log('_&_ competitions',_competitions)
                    console.log('_&_ teams',_teams)
                    console.log('_&_ markets',_markets)

                    if (_competitions && _teams && _markets) {
                        // const _fullGames = await wrappedFullGames(coreContract, _sports, _competitions, _teams, _markets);
                        // console.log('_&_ fullGames',_fullGames)

                        if (_sports.length > 0) dispatch(slice.addSports(_sports, () => globalSports));
                        if (_globalObject.countries.length > 0) dispatch(slice.addCountries(_globalObject.countries)); // , () => jsonCompetitions
                        dispatch(slice.addCompetitions(_competitions, () => globalCompetitions));
                        dispatch(slice.addTeams(_teams)); //, () => jsonTeams
                        dispatch(slice.addMarkets(_markets)); //
                        //dispatch(slice.addFullGames(_fullGames, () => globalFullGames));
                        setShouldLoadAnyGlobalData(false);
                    }
                }

                console.log('_&_ globalObject',_globalObject)
                //console.log('_&_ sportsHashes',sportsHashes)
                console.log('_&_ sports',_sports)
            }
        }

        fetchGlobalData();
    }, [shouldLoadAnyGlobalData]) */

    useEffect(() => {
        const fetchGlobalData = async () => {
            //if (shouldLoadAnyGlobalData) {
                const _countries = await fetchCountries();
                const _sports = await fetchSports();
                const _competitions = await fetchCompetitions();
                const _teams = await fetchTeams();
                const _markets = await fetchMarkets();

                console.log('_&_ countries',_countries)
                console.log('_&_ sports',_sports)
                console.log('_&_ competitions',_competitions)
                console.log('_&_ teams',_teams)
                console.log('_&_ markets',_markets)

                if (_competitions && _teams && _markets) {
                    // const _fullGames = await wrappedFullGames(coreContract, _sports, _competitions, _teams, _markets);
                    // console.log('_&_ fullGames',_fullGames)

                    if (_sports.length > 0) dispatch(slice.addSports(_sports, () => globalSports));
                    if (_countries.length > 0) dispatch(slice.addCountries(_countries)); // , () => jsonCompetitions
                    dispatch(slice.addCompetitions(_competitions, () => globalCompetitions));
                    dispatch(slice.addTeams(_teams)); //, () => jsonTeams
                    dispatch(slice.addMarkets(_markets)); //
                    //dispatch(slice.addFullGames(_fullGames, () => globalFullGames));
                    setShouldLoadAnyGlobalData(false);
                }
            //}
        }

        fetchGlobalData();
    }, [shouldLoadAnyGlobalData]);
    
    /* useEffect(() => {
        const _wallet = utils.initWallet(network)
        dispatch(slice.updateWallet(_wallet))
        console.log('initWallet',_wallet)
    }, [network]) */

    
    useEffect(() => {
        console.log('_lastConnectionTimestamp B',lastConnection)
        //console.log('utils.isCurrentVersionGreater(version)',utils.isCurrentVersionGreater(version))
        /*if (lastConnection === undefined || lastConnection === null || lastConnection.length === 0) {
            console.log('_lastConnectionTimestamp C => inicializamos con nueva lastConnectionTimestamp:',utils.currentTimestamp)
            dispatch(slice.addLastConnection(utils.currentTimestamp))
        } else {*/
        dispatch(slice.addLastConnection(utils.currentTimestamp))
            console.log('_lastConnectionTimestamp D => comprobamos para actualizar con nueva lastConnectionTimestamp:',utils.currentTimestamp)
            if (utils.isTimeLapsedReached(lastConnection)) { // If X hour has been reached, clear global fullGames variable to fetch it again
                //console.log('_lastConnectionTimestamp E => actualizamos con nueva version:',utils.currentTimestamp)
                //dispatch(slice.addLastConnection(utils.currentTimestamp))
            }
        //}
    }, [lastConnection])

    useEffect(() => {
        console.log('version B',version)
        //console.log('utils.isCurrentVersionGreater(version)',utils.isCurrentVersionGreater(version))
        if (version === undefined || version === null || version.length === 0) {
            console.log('version C => inicializamos con nueva version:',version)
            dispatch(slice.addVersion(utils.currentVersion))
        } else {
            console.log('version D => comprobamos para actualizar con nueva version:',version)
            if (utils.isCurrentVersionGreater(version)) {
                console.log('version E => actualizamos con nueva version:',utils.currentVersion)
                dispatch(slice.addVersion(utils.currentVersion))
            }
        }
    }, [version, isCurrentVersionGreater])
    console.log('isCurrentVersionGreater',isCurrentVersionGreater)

    /* useEffect(() => {
        if (shouldLoadWelcomeBonusData) {
            const welcomeBonusData = async () => {
                console.log('_&_ welcomeBonusData')
                console.log('welcomeBonusContract',welcomeBonusContract)
                console.log('wallet.address',wallet.address)
                if (welcomeBonusContract && welcomeBonus)
                {
                    const _welcomeBonusData = await fetchWelcomeBonusData({
                        welcomeBonusContract: welcomeBonusContract,
                        walletAddress: wallet.address,
                        dataToFetch: ['all']
                    })
                    //_welcomeBonusData['isEnabled'] = false;
                    console.log('_&_ _welcomeBonusData',_welcomeBonusData)
                    console.log('_&_ _welcomeBonus',welcomeBonus)

                    const __welcomeBonusData = {
                        ...welcomeBonus,
                        ..._welcomeBonusData
                    }
                    console.log('_&_ __welcomeBonusData updating',__welcomeBonusData)
                    dispatch(slice.updateWelcomeBonus(__welcomeBonusData))
                    setShouldLoadWelcomeBonusData(false)
                }
            }

            welcomeBonusData();
        }
    }, []);

    useEffect(() => {
        if (discordUserToken !== null && !shouldLoadWelcomeBonusData) {
            console.log('LLAMADA',discordUserToken)
            sendDiscordUserToken(discordUserToken)
            .then(async (response) => {
                console.log('App.js response', response)
                if (response.success) {
                    const _welcomeBonus = {...welcomeBonus, isApplied: true}
                    await dispatch(slice.updateWelcomeBonus(_welcomeBonus))
                    setShouldGoToHome(true);
                } 
            })
        }
    }, [discordUserToken, shouldLoadWelcomeBonusData])

    useEffect(() => {
        if (welcomeBonus.isApplied && !shouldLoadWelcomeBonusData && shouldGoToHome) {
            utils.goToHome();
        }
    }, [welcomeBonus.isApplied, shouldGoToHome]) */

    useEffect(() => {
        const getUserIp = async () => {
            const userIpResponse = await fetchUserIp();
            setUserIp(userIpResponse);
        }

        getUserIp();
    }, []);

    const handleLeftMenuClick = () => {
        console.log('isLeftMenuToggleOn', isLeftMenuToggleOn);
        setIsLeftMenuToggleOn(prevState => {
        document.body.style.overflow = (!prevState ? 'hidden' : null);
        return !prevState;
        });
    }

    const resetIsLeftMenuToggleOn = () => {
        console.log('resetIsLeftMenuToggleOn', isLeftMenuToggleOn);
        setIsLeftMenuToggleOn(false);
        document.body.style.overflow = 'scroll';
    }

    return (
        <IpBlocker userIp={userIp}>
            <StatisticsProvider userIp={userIp}>
            <div id='main'>
                <Navbar
                    navBarRef={navBarRef}
                    handleLeftMenuClick={() => handleLeftMenuClick()}
                    isLeftMenuToggleOn={isLeftMenuToggleOn}
                />
                <LeftMenu
                    navBarRef={navBarRef}
                    handleLeftMenuClick={() => handleLeftMenuClick()}
                    resetIsLeftMenuToggleOn={() => resetIsLeftMenuToggleOn()}
                    isLeftMenuToggleOn={isLeftMenuToggleOn}
                />
                <div>
                    <LoaderProvider>
                        { children }
                    </LoaderProvider>
                    <Outlet />
                </div>
            </div>
            </StatisticsProvider>
        </IpBlocker>
    );
}

export default Layout;