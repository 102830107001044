import * as React from "react"

const SvgComponent = (props) => (
  <svg
    viewBox="118.882 54.067 80.949 80.95"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>{"voleyball"}</title>
    <path
      style={{
        fill: "#515254",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M184.272 62.758c-17.554-13.76-42.938-10.688-56.699 6.869-13.761 17.553-10.686 42.938 6.867 56.699 17.555 13.761 42.938 10.686 56.7-6.867 13.761-17.554 10.687-42.939-6.868-56.701"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M137.295 104.1a192.577 192.577 0 0 1-1.939-1.498 89.35 89.35 0 0 1-.943-.748c-2.39-1.902-4.736-3.961-6.893-5.957-1.593 2.645-5.063 9.553-3.8 17.847a40.34 40.34 0 0 0 12.249 13.831 30.548 30.548 0 0 1-2.159-12.208c.18-6.057 2.219-9.62 3.485-11.267M192.764 78.135c.489 3.341.921 11.238-3.901 19.219l-.004.006c-2.438 4.31-6.658 8.741-8.534 10.615l-1.742 1.571c-7.466 5.899-17.041 8.726-19.264 9.324-3.335 4.933-3.677 8.843-3.087 11.617 4.684-.45 24.694-3.227 34.883-18.497 5.04-7.554 7.829-14.907 8.291-21.858.024-.362.04-.713.052-1.057a40.609 40.609 0 0 0-1.808-7.645c-1.859-2.234-3.699-3.024-4.886-3.295"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M162.412 72.87c-1.405-1.1-2.84-2.178-4.262-3.203-9.898-7.118-15.002-9.928-17.481-11.036a40.215 40.215 0 0 0-10.855 8.248c3.215 3.293 8.679 8.538 16.685 15.098a414.115 414.115 0 0 0 6.796 5.431c10.146 7.953 21.935 16.636 25.139 18.986 1.603-1.6 5.491-5.638 7.882-9.559-2.046-2.702-10.636-13.561-23.904-23.965M139.258 105.585c-.997 1.296-2.831 4.401-2.992 9.856-.186 6.161 1.723 11.181 3.581 14.567a40.186 40.186 0 0 0 15.465 4.807 12.481 12.481 0 0 1-1.303-3.015c-.842-2.956-.98-7.672 2.857-13.67-2.76-1.89-10.702-7.368-17.608-12.545M184.935 63.178a15.672 15.672 0 0 0-6.488-1.381c7.501 6.215 12.105 12 13.425 13.75.815.029 2.186.227 3.781 1.065a40.3 40.3 0 0 0-10.718-13.434M169.586 130.157c-5.454 1.788-10.13 2.468-12.528 2.721a9.329 9.329 0 0 0 1.419 2.13c12.294.258 24.56-5.07 32.732-15.494a40.265 40.265 0 0 0 7.914-17.412c-1.341 3.443-3.266 7.204-5.967 11.252-6.404 9.6-16.354 14.438-23.57 16.803M175.673 59.589a16.499 16.499 0 0 1 3.708-.223 40.13 40.13 0 0 0-10.521-4.174 52.67 52.67 0 0 1 6.813 4.397M119.425 87.898a40.671 40.671 0 0 0 1.635 19.747c.576-6.516 3.359-11.499 4.627-13.47a187.448 187.448 0 0 1-6.262-6.277M151.778 89.341a427.385 427.385 0 0 1-6.835-5.463c-8.037-6.586-13.499-11.805-16.762-15.144a40.299 40.299 0 0 0-8.154 16.217c2.486 2.672 9.019 9.491 15.916 14.982 7.898 6.292 20.302 14.802 22.845 16.535 2.196-.612 10.889-3.255 17.732-8.433-3.76-2.759-15.001-11.057-24.74-18.693l-.002-.001"
    />
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M174.965 62.143c-6.99-5.479-12.888-7.423-15.587-8.076a40.72 40.72 0 0 0-15.872 3.228c3.187 1.645 8.203 4.714 16.078 10.38a132.275 132.275 0 0 1 4.341 3.265c12.382 9.705 20.775 19.821 23.707 23.578 4.158-8.082 2.786-15.828 2.491-17.204-.915-1.235-6.277-8.213-15.158-15.171"
    />
    <path
      style={{
        fill: "#85acaf",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M158.452 134.981a40.362 40.362 0 0 1-3.158-.194c-.012-.022-.025-.041-.036-.062a40.76 40.76 0 0 0 3.14.194l.054.062m40.501-32.451a40.54 40.54 0 0 0 .484-12.944l.014-.322a40.139 40.139 0 0 1-.398 13.016c-.033.083-.066.168-.1.25m-1.374-21.184c-.028-.031-.055-.065-.082-.096a40.007 40.007 0 0 0-1.978-4.707l.072.037a40.427 40.427 0 0 1 1.988 4.766m-12.758-18.219c-.062-.025-.123-.053-.184-.079a38.817 38.817 0 0 0-.365-.29l-.222-.173a3.288 3.288 0 0 1-.032-.091c.27.208.539.419.803.633"
    />
    <path
      style={{
        fill: "#3e3d3f",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M158.398 134.919a40.76 40.76 0 0 1-3.14-.194 12.552 12.552 0 0 1-1.249-2.925 13.073 13.073 0 0 1-.476-4.091c.869-.33 1.724-.68 2.565-1.052-.188 1.45-.1 2.73.134 3.83 4.684-.45 24.694-3.227 34.883-18.497 5.04-7.554 7.829-14.907 8.291-21.858.012-.185.023-.366.031-.546a40.54 40.54 0 0 1-.484 12.944c-1.332 3.326-3.207 6.943-5.797 10.824-6.404 9.6-16.354 14.438-23.57 16.803-5.454 1.788-10.13 2.468-12.528 2.721a9.362 9.362 0 0 0 1.34 2.041m17.056-23.141a51.934 51.934 0 0 0 5.011-7.493c1.834-1.978 4.2-4.741 5.851-7.45a83.924 83.924 0 0 0-1.745-2.22c.294-.954.562-1.917.801-2.889.946 1.13 1.702 2.076 2.26 2.792 4.158-8.082 2.786-15.828 2.491-17.204-.4-.539-1.647-2.173-3.664-4.422a51.91 51.91 0 0 0-.76-4.418c3.264 3.339 5.352 5.986 6.173 7.073.793.027 2.111.216 3.647.996a40.007 40.007 0 0 1 1.978 4.707c-1.806-2.096-3.577-2.852-4.733-3.115.489 3.341.921 11.238-3.901 19.219l-.004.006c-2.438 4.31-6.658 8.741-8.534 10.615l-1.742 1.571a40.748 40.748 0 0 1-3.129 2.232m9.183-48.73a16.836 16.836 0 0 0-.499-.202l-.088-.261.222.173c.122.096.246.194.365.29"
    />
    <path
      style={{
        fill: "#c2c0be",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M156.232 130.487c-.234-1.1-.322-2.38-.134-3.83a51.41 51.41 0 0 0 19.356-14.879 40.748 40.748 0 0 0 3.129-2.232l1.742-1.571c1.876-1.874 6.096-6.305 8.534-10.615l.004-.006c4.822-7.981 4.39-15.878 3.901-19.219 1.156.263 2.927 1.019 4.733 3.115.027.031.054.065.082.096a40.759 40.759 0 0 1 1.872 7.918l-.014.322c-.008.18-.019.361-.031.546-.462 6.951-3.251 14.304-8.291 21.858-10.189 15.27-30.199 18.047-34.883 18.497"
    />
    <path
      style={{
        fill: "#c2c0be",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M180.465 104.285a51.935 51.935 0 0 0 4.106-9.67c.789.972 1.374 1.73 1.745 2.22-1.651 2.709-4.017 5.472-5.851 7.45M155.294 134.787a40.426 40.426 0 0 1-14.006-4.067 52.231 52.231 0 0 0 12.245-3.011 13.073 13.073 0 0 0 .476 4.091 12.552 12.552 0 0 0 1.249 2.925c.011.021.024.04.036.062M195.591 76.58l-.072-.037c-1.536-.78-2.854-.969-3.647-.996-.821-1.087-2.909-3.734-6.173-7.073a52.658 52.658 0 0 0-.763-3.069 51.097 51.097 0 0 0-.798-2.559c.165.064.332.131.499.202.061.026.122.054.184.079a40.232 40.232 0 0 1 10.77 13.453M159.332 134.989c-.293 0-.587-.002-.88-.008l-.054-.062a9.362 9.362 0 0 1-1.34-2.041c2.398-.253 7.074-.933 12.528-2.721 7.216-2.365 17.166-7.203 23.57-16.803 2.59-3.881 4.465-7.498 5.797-10.824.034-.082.067-.167.1-.25-2.841 14.509-13.564 26.926-28.771 31.195a40.456 40.456 0 0 1-10.95 1.514M187.632 94.518a103.177 103.177 0 0 0-2.26-2.792 51.326 51.326 0 0 0 1.087-18.834c2.017 2.249 3.264 3.883 3.664 4.422.295 1.376 1.667 9.122-2.491 17.204"
    />
  </svg>
)

export default SvgComponent
