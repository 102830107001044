import { useState } from 'react';
import * as ethersConfig from '../../config/ethersConfig.js';
import { useContractWrite, useContract, Web3Button } from "@thirdweb-dev/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

const ApproveButton = (props) => {
    const [isBeingTransacted, setIsBeingTransacted] = useState(false)
    const { network, spender, onApproveClick, onTxApproveResolved } = props
    console.log('Approve component', network)
    console.log('Approve component spender', spender)
    const amount = onApproveClick()
    const web3Config = ethersConfig.web3Config(network)
    const { contract } = useContract(
        web3Config.tokenAddress,
        web3Config.tokenAbi
    );
    const { mutateAsync, isLoading, error } = useContractWrite(
        contract,
        "approve",
    );

    const handleClick = async () => {
        try {
            setIsBeingTransacted(true)
            console.log('Calling mutateAsync...');
            const result = await mutateAsync({ args: [spender, amount] });
            console.log('mutateAsync result:', result);
            // Llama a la función onTxResolved con el resultado de mutateAsync
            
            onTxApproveResolved(result);
            setIsBeingTransacted(false)
        } catch (error) {
            setIsBeingTransacted(false)
            //onTxApproveResolved(false);
            console.error('An error occurred:', error);
            // Puedes manejar el error aquí si es necesario
        }
    };

    /*const ShowLoadingIcon = () => {
        const accountClassName = 'betslip-place-bet-btn-svg dp-block'
        return (
            <FontAwesomeIcon icon={icon({name: 'spinner'})} spin className = {accountClassName} />
        )
    }*/

    const ShowLoadingIcon = () => {
        const accountClassName = 'betslip-place-bet-btn-svg dp-block spinner-loader'
        return (
            <div className = {accountClassName}></div>
        )
    }

    const buttonClassName = 'btn-medium betslip-place-bet-btn bg-gradient-yellow-orange pt-0 pr-12 pb-0 pl-20 pr-20 h-40 lh-40 br-5'
    return (
        <button className={buttonClassName}
          onClick={() => handleClick()}
          disabled={isBeingTransacted}>
          {isBeingTransacted ? <ShowLoadingIcon /> : 'Approve'}
        </button>
    )
}

export default ApproveButton