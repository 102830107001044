import axios from 'axios';

const sendDiscordUserToken = async (props) => {
    const userData = props
    console.log('sendDiscordUserToken.js code',userData.discordCode);
    if (userData.discordCode) {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVER}/sendUserToken`, userData)
            console.log('sendDiscordUserToken.js response',response);
            return response.data;
        } catch (error) {
            console.error(error);
            return false
        }
    } else {
        console.error('There is no user token to send');
        return false;
    }
}

export default sendDiscordUserToken;