import * as React from "react"

const SvgComponent = (props) => (
  <svg
    viewBox="118.882 54.067 80.949 80.95"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>{"tennis"}</title>
    <path
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M148.288 55.62c-21.494 6.111-33.966 28.492-27.852 49.989 6.111 21.494 28.491 33.967 49.988 27.855 21.494-6.113 33.967-28.492 27.853-49.989-6.111-21.496-28.489-33.968-49.989-27.855"
    />
    <path
      style={{
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
        fill: "#f6e900",
      }}
      d="M177.73 91.278c-1.115-3.91-2.589-7.518-4.013-11.008-2.368-5.797-4.607-11.272-4.88-17.312-.125-2.764-.136-5.35-.063-7.768a40.275 40.275 0 0 0-20.486.43 40.26 40.26 0 0 0-18.298 11.085 102.407 102.407 0 0 1 3.766 6.229c2.942 5.281 3.92 11.112 4.956 17.288.624 3.716 1.268 7.561 2.378 11.473l.228.808c3.172 9.603 13.405 15.109 23.228 12.358l.169-.048c9.89-2.856 15.711-13.087 13.175-23.011l-.16-.524"
    />
    <path
      style={{
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
        fill: "#f6e900",
      }}
      d="M173.156 56.511a97.282 97.282 0 0 0 .091 6.247c.239 5.278 2.337 10.41 4.555 15.844 1.377 3.363 2.795 6.836 3.937 10.659l.009.005.254.821c3.54 12.447-3.709 25.453-16.156 28.995l-.082.023c-12.428 3.496-25.398-3.746-28.934-16.177l-.209-.839c-1.04-3.854-1.661-7.552-2.263-11.138-.971-5.787-1.889-11.255-4.458-15.868a99.528 99.528 0 0 0-2.891-4.845c-7.325 9.769-10.171 22.721-6.573 35.371 6.111 21.494 28.491 33.967 49.988 27.855 21.494-6.113 33.967-28.492 27.853-49.989-3.694-12.99-13.332-22.673-25.121-26.964"
    />
    <path
      style={{
        fill: "#91cd38",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M164.891 134.632a41.092 41.092 0 0 0 5.533-1.168 40.64 40.64 0 0 0 8.041-3.244 40.217 40.217 0 0 1-13.574 4.412m-14.709-.672a40.177 40.177 0 0 1-11.242-4.482 40.665 40.665 0 0 0 11.242 4.482m41.188-14.665a40.652 40.652 0 0 0 7.651-16.704 40.277 40.277 0 0 1-7.651 16.704m8.078-30.27a40.881 40.881 0 0 0-1.171-5.55 40.906 40.906 0 0 0-2.061-5.644 40.194 40.194 0 0 1 3.232 11.194"
    />
    <path
      style={{
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
        fill: "#d8d494",
      }}
      d="M159.416 119.984c-2.502 0-4.956-.404-7.28-1.164a51.61 51.61 0 0 0 7.684-3.254 19.042 19.042 0 0 0 4.726-.705l.169-.048c7.342-2.118 12.44-8.302 13.544-15.407a51.904 51.904 0 0 0 4.244-7.191c2.198 11.769-4.918 23.527-16.657 26.867l-.082.023a23.388 23.388 0 0 1-6.348.879m40.032-30.959a40.881 40.881 0 0 0-1.171-5.55 40.714 40.714 0 0 0-2.272-6.099l.211.455a40.906 40.906 0 0 1 2.061 5.644 40.881 40.881 0 0 1 1.171 5.55"
    />
    <path
      style={{
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
        fill: "#dbd000",
      }}
      d="M159.82 115.566a52.113 52.113 0 0 0 18.439-16.16c-1.104 7.105-6.202 13.289-13.544 15.407l-.169.048c-1.575.442-3.16.67-4.726.705"
    />
    <path
      style={{
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
        fill: "#dbd000",
      }}
      d="M159.358 135.006c-3.157 0-6.228-.361-9.176-1.046a40.665 40.665 0 0 1-11.242-4.482 40.711 40.711 0 0 1-10.241-8.542 51.81 51.81 0 0 0 23.437-2.116 23.416 23.416 0 0 0 7.28 1.164c2.099 0 4.232-.283 6.348-.879l.082-.023c11.739-3.34 18.855-15.098 16.657-26.867a51.671 51.671 0 0 0 5.192-22.647c0-1.371-.065-2.723-.171-4.066a40.648 40.648 0 0 1 8.481 11.874 40.714 40.714 0 0 1 2.272 6.099 40.881 40.881 0 0 1 1.171 5.55 40.81 40.81 0 0 1 .373 5.517c0 2.756-.275 5.448-.8 8.049a40.652 40.652 0 0 1-7.651 16.704 40.686 40.686 0 0 1-12.905 10.925 40.64 40.64 0 0 1-8.041 3.244c-1.842.523-3.689.91-5.533 1.168a41.057 41.057 0 0 1-5.533.374"
    />
  </svg>
)

export default SvgComponent
