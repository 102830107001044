import { useState } from 'react';
import { useSelector } from 'react-redux';
import * as ethersConfig from '../../config/ethersConfig.js';
import * as utils from '../../utils/utils.js';
import { ethers } from 'ethers';
import { useContractWrite, useContract, Web3Button } from "@thirdweb-dev/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { updateWBInfo } from '../../services/updateWBInfo.js'
import { checkForEnjoyedWB } from '../../services/cwb.js';

const PlaceBetButton = (props) => {
    const [isBeingTransacted, setIsBeingTransacted] = useState(false)
    const { network, onPlaceBetClick, onTxPlaceBetResolved } = props
    const { betsWrapper, /* betslip, */ parsedPaidAmount } = onPlaceBetClick()
    const { data: wallet } = useSelector(state => state.wallet);
    const { data: referralAddress } = useSelector(state => state.referral);
    const { data: welcomeBonus } = useSelector(state => state.welcomeBonus);
    const { data: betslip } = useSelector(state => state.betslip);
    console.log('Place bet component', network)
    console.log('Place bet component parsedPaidAmount', parsedPaidAmount)
    const web3Config = ethersConfig.web3Config(network)
    const { contract } = useContract(
        web3Config.coreAddress,
        web3Config.coreAbi
    );
    const { mutateAsync, isLoading, error } = useContractWrite(
        contract,
        "putBets",
    );

    const placeBet = async () => {
        setIsBeingTransacted(true)
        console.log('Calling mutateAsync...');
        
        const args = [
            betsWrapper,
            betslip.combo,
            parsedPaidAmount,
            referralAddress && referralAddress !== null ? ethers.utils.getAddress(referralAddress) : ethers.constants.AddressZero,
            wallet.address && wallet.address !== null ? ethers.utils.getAddress(wallet.address) : ethers.constants.AddressZero,
            welcomeBonus.isApplied,
            betslip.isBonusApplied,
        ]
        console.log('args: ',args)
        const result = await mutateAsync({ args: args });
        console.log('mutateAsync result:', result);
        // Llama a la función onTxResolved con el resultado de mutateAsync
        onTxPlaceBetResolved(betsWrapper, parsedPaidAmount);
    }
    
    const handleClick = async () => {
        /* const _welcomeBonus = {
            ...welcomeBonus,
            isApplied: true,
            discordNickname: response.data.discordNickname,
            ip: userData.ip,
        } */
        
        try {
            if (welcomeBonus.isApplied === false) {
                await placeBet();
            } else if (welcomeBonus.isApplied === true) {
                /* const wbResponse = await updateWBInfo({
                    ...welcomeBonus,
                    wallet: wallet
                }) */

                /* if (wbResponse.success) {
                    console.log('wbResponse:', wbResponse);
                    if (wbResponse.data.code === 'T06') {
                        const cwbResponse = await checkForEnjoyedWB({
                            ...welcomeBonus,
                            wallet: wallet
                        })

                        if (cwbResponse.data.code === 'T04') {
                            await placeBet();
                        } else {
                            console.error('Error placing bets: ', cwbResponse.data.code)
                        }
                    } else {
                        console.error('Error placing bets: ', wbResponse.data.code)
                    }
                } */
                await placeBet();
            }
            setIsBeingTransacted(false)
        } catch (error) {
            setIsBeingTransacted(false)
            console.error('An error occurred:', error);
            const errorMessage = await utils.handleSmartContractError(error);
            if (errorMessage) {
                alert(errorMessage)
            }
        }
    };

    /*const ShowLoadingIcon = () => {
        const accountClassName = 'betslip-place-bet-btn-svg dp-block'
        return (
            <FontAwesomeIcon icon={icon({name: 'spinner'})} spin className = {accountClassName} />
        )
    }*/

    const ShowLoadingIcon = () => {
        const accountClassName = 'betslip-place-bet-btn-svg dp-block spinner-loader'
        return (
            <div className = {accountClassName}></div>
        )
    }

    const buttonClassName = 'btn-medium betslip-place-bet-btn bg-gradient-yellow-orange pt-0 pr-12 pb-0 pl-20 pr-20 h-40 lh-40 br-5'
    return (
        <button className={buttonClassName}
            onClick={() => handleClick()}
            disabled={
                utils.hasAnyGameStarted(betslip.bets) ||
                isBeingTransacted ||
                (
                    !welcomeBonus.isEnjoyed && welcomeBonus.isApplied &&
                    (
                        (
                            betslip.combo &&
                            (
                                betslip.totalComboAmount < 20 ||
                                betslip.totalComboAmount > 50
                            )
                        ) ||
                        (
                            !betslip.combo &&
                            (
                                betslip.totalAmount < 20 ||
                                betslip.totalAmount > 50
                            )
                        )
                    )
                ) ||
                (
                    !welcomeBonus.isApplied &&
                    (
                        (
                            betslip.combo &&
                            (
                                betslip.totalComboAmount < 0.5 ||
                                betslip.totalComboAmount > 80
                            )
                        ) ||
                        (
                            !betslip.combo &&
                            (
                                betslip.totalAmount < 0.5 ||
                                betslip.totalAmount > 80
                            )
                        )
                    )
                )
            }
        >
            {isBeingTransacted ? <ShowLoadingIcon /> : 'Place bet'}
        </button>
    )
}

export default PlaceBetButton