import axios from 'axios';

const fetchCountries = async () => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVER}/getCountries`)
        console.log('fetchCountries.js response',response);
        return response.data.data;
    } catch (error) {
        console.error(error);
        return false
    }
}

export default fetchCountries;