import React, { useEffect, useState, useMemo, useContext, useRef } from 'react';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import GridCard from '../components/GridCard.js';
import BetSlip from '../components/BetSlip.js';
import Loader from '../components/Loader.js';
import * as utils from '../utils/utils.js';
import * as slice from '../utils/slice.js';
import fetchGames from '../services/fetchGames.js'
import sendDiscordUserToken from '../services/sendDiscordUserToken.js'
import * as networkConfig from '../config/ethersConfig.js';
//const utils = require('../utils/utils.js');
import * as store from '../utils/store.js';
import { LoaderContext } from '../context/loader.jsx';
import { StatisticsContext } from '../components/Statistics/StatisticsProvider.js';
import wrappedFullGames from '../services/fetchGames.js';
import fetchWelcomeBonusData from '../services/fetchWelcomeBonusData.js';
import ConnectButton from '../components/ConnectButton/ConnectButton.js';
import SportIcon from '../components/utils/SportIcon.js';
import Footer from '../components/Footer/Footer.js';
var _ = require('lodash');

const Home = (props) => {
    const dispatch = useDispatch()

    const { data: wallet } = useSelector(state => state.wallet);
    const { network, sportParam, competitionParam, gameId } = useParams()
    console.log('Home.js useParams: '+sportParam+' '+competitionParam+' '+gameId)
    const [ useNetwork, setUseNetwork ] = useState(null)
    const { hashesContract, coreContract, welcomeBonusContract } = props.contracts
    const { data: welcomeBonus } = useSelector(state => state.welcomeBonus);
    //const { network } = useParams() ? useParams() : wallet.network.keyName
    //console.log('Home.js1 param network',useNetwork)
    
    const { isLoading, setIsLoading } = useContext(LoaderContext)
    const [ isFetchGamesResponseEmpty, setIsFetchGamesResponseEmpty ] = useState(false)
    const  statisticsContext  = useContext(StatisticsContext)
    console.log('Home.js statisticsContext',statisticsContext)
    const userInfo = useRef({});
    const [ isFirstTime, setIsFirstTime ] = useState(true)
    const [ isWBModalVisible, setIsWBModalVisible ] = useState(false)
    //console.log('Home.js userInfo',userInfo)
    const [ windowHeight, setWindowHeight ] = useState(0);

    const [shouldLoadGames, setShouldLoadGames] = useState(false);
    const [shouldGoToHome, setShouldGoToHome] = useState(false);
    const [shouldLoadWelcomeBonusData, setShouldLoadWelcomeBonusData] = useState(true);
    //const [noGamesToShow, setNoGamesToShow] = useState(false)

    const { data: lastConnection } = useSelector(state => state.lastConnection);
    console.log('_lastConnectionTimestamp A',lastConnection)
    const currentTimestamp = utils.currentTimestamp
    console.log('currentTimestamp',currentTimestamp)

    const discordUserToken = utils.getDiscordUserToken()

    useEffect(() => {
        setWindowHeight(window.innerHeight);
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    const updateDimensions = () => {
        setWindowHeight(window.innerHeight);
    };

    useEffect(() => {
        if (network || wallet.network.keyName) {
            setUseNetwork(network ? network : wallet.network.keyName)
            //console.log('Home.js2 param network',network, wallet.network.keyName, useNetwork)
        } 
    }, [network, wallet.network.keyName])

    useEffect(() => {
        /* console.log('Object.keys',Object.keys(statisticsContext['userInfo']))
        console.log('Object.keys2',statisticsContext['userInfo']['current'])
        console.log('hasOwnProperty',statisticsContext.hasOwnProperty('userInfo'))
        console.log('hasOwnProperty2',statisticsContext['userInfo'].hasOwnProperty('current')) */
        if (_.isEmpty(userInfo.current)
            && statisticsContext !== undefined
            && statisticsContext['userInfo'].hasOwnProperty('current')
            ) {
                //console.log('Home.js length', Object.keys(statisticsContext['userInfo']).length)
                //console.log('Home.js length2', statisticsContext['userInfo'])
                if (!_.isEmpty(statisticsContext['userInfo'].current)) {
                    const userInfoContext = statisticsContext['userInfo'].current
                    console.log('Home.js userInfo',userInfoContext)
                    /* userInfo.current = userInfoContext.current;
                    console.log('Home.js userInfo.current',userInfo.current) */

                }
        }
    }, [statisticsContext['userInfo'].current]);
    
    //const globalGames = useSelector((state) => state.jsonGames.data);
    const globalSports = useSelector((state) => state.sports.data);
    const globalCountries = useSelector((state) => state.jsonCountries.data);
    const globalCompetitions = useSelector((state) => state.jsonCompetitions.data);
    const globalTeams = useSelector((state) => state.jsonTeams.data);
    const globalMarkets = useSelector((state) => state.jsonMarkets.data);
    const globalFullGames = useSelector((state) => state.fullGames.data);

    const globalReferral = useSelector((state) => state.referral.data);
    console.log('globalReferral',globalReferral)

    /*useEffect(() => {
        console.log('jsonGames HOME.js: ', jsonGames);
        console.log('jsonCompetitions HOME.js: ', jsonCompetitions);
        console.log('jsonTeams HOME.js: ', jsonTeams);
        console.log('jsonMarkets HOME.js: ', jsonMarkets);
        console.log('fullGames HOME.js: ', globalFullGames);
        
    }, [globalFullGames]);*/

    const checkIfTimeLapsed = () => {
        const timeLapsed = utils.timeLapsedFromLastConnection(lastConnection)
        return timeLapsed;
    }

    useEffect(() => {
        const timeLapsed = checkIfTimeLapsed()
        const isTimeLapsed = timeLapsed.minutesLapsed > utils.loadAfterMinutesLapsed ? true : false
        //if (isTimeLapsed || Object.keys(globalFullGames).length === 0) {
            console.log('__shouldLoadGames',shouldLoadGames)
            console.log('globalFullGames',globalFullGames) 
            dispatch(slice.resetFullGames()); //, () => globalFullGames
            setShouldLoadGames(true);
            return;
        //}

        /* if (Object.keys(globalFullGames).length === 0) {
            console.log('__shouldLoadGames',shouldLoadGames)
            console.log('globalFullGames',globalFullGames)
            setShouldLoadGames(true);
        } */
    }, [lastConnection])

    useEffect(() => {
        console.log('__#shouldLoadGames',shouldLoadGames)
    }, [shouldLoadGames])

    useEffect(() => {
        if (shouldLoadWelcomeBonusData) {
            const welcomeBonusData = async () => {
                console.log('_&_ welcomeBonusData')
                console.log('welcomeBonusContract',welcomeBonusContract)
                console.log('wallet.address',wallet.address)
                if (welcomeBonusContract && welcomeBonus)
                {
                    const _welcomeBonusData = await fetchWelcomeBonusData({
                        welcomeBonusContract: welcomeBonusContract,
                        walletAddress: wallet.address,
                        dataToFetch: ['all']
                    })
                    //_welcomeBonusData['isEnabled'] = false;
                    console.log('_&_ _welcomeBonusData',_welcomeBonusData)
                    console.log('_&_ _welcomeBonus',welcomeBonus)

                    const __welcomeBonusData = {
                        ...welcomeBonus,
                        ..._welcomeBonusData
                    }
                    console.log('_&_ __welcomeBonusData updating',__welcomeBonusData)
                    dispatch(slice.updateWelcomeBonus(__welcomeBonusData))
                    setShouldLoadWelcomeBonusData(false)
                }
            }

            welcomeBonusData();
        }
    }, []);

    useEffect(() => {
        if (discordUserToken !== null && !shouldLoadWelcomeBonusData && statisticsContext['userInfo'].current !== undefined) {
            const userData = {...statisticsContext['userInfo'].current, discordCode: discordUserToken}
            console.log('LLAMADA',userData)
            sendDiscordUserToken(userData)
            .then(async (response) => {
                console.log('App.js response', response)
                if (response.success) {
                    setIsLoading(true)
                    const _welcomeBonus = {
                        ...welcomeBonus,
                        isApplied: true,
                        discordNickname: response.data.discordNickname,
                        ip: userData.ip,
                    }
                    /* await updateWBInfo({
                        ..._welcomeBonus,
                        wallet: {
                            address: userData.wallet.address,
                            network: userData.wallet.network.keyName,
                        }
                    }) */
                    //console.log('Home.js should go home 3')
                    await dispatch(slice.updateWelcomeBonus(_welcomeBonus))
                    setShouldGoToHome(true);
                } else {
                    //console.log('Home.js should go home 1')
                    setIsLoading(true)
                    utils.goToHome();
                }
            })
        } /* else if (discordUserToken !== null) {
            //console.log('Home.js should go home 2')
            setIsLoading(true)
            utils.goToHome();
        } */
    }, [discordUserToken, shouldLoadWelcomeBonusData, statisticsContext['userInfo'].current])

    useEffect(() => {
        if (welcomeBonus.isApplied && !shouldLoadWelcomeBonusData && shouldGoToHome) {
            utils.goToHome();
        }
    }, [welcomeBonus.isApplied, shouldGoToHome])

    /* useEffect(() => {
        const welcomeBonusData = async () => {
            console.log('_&_ welcomeBonusData')
            console.log('welcomeBonusContract',welcomeBonusContract)
            console.log('wallet.address',wallet.address)
            if (welcomeBonusContract)
            {
                const _welcomeBonusData = await fetchWelcomeBonusData({
                    welcomeBonusContract: welcomeBonusContract,
                    walletAddress: wallet.address,
                    dataToFetch: ['all']
                })
                //_welcomeBonusData['isEnabled'] = false;
                console.log('_&_ _welcomeBonusData',_welcomeBonusData)

                const __welcomeBonusData = {
                    ...welcomeBonus,
                    ..._welcomeBonusData
                }

                dispatch(slice.updateWelcomeBonus(__welcomeBonusData))
            }
        }

        welcomeBonusData();
    }, []); */

    useEffect(() => {
        const fetchGames = async () => {
            console.log('_&_ fetchGames')
            console.log('shouldLoadGames',shouldLoadGames)
            console.log('coreContract',coreContract)
            console.log('globalSports',globalSports)
            console.log('globalCountries',globalCountries)
            console.log('globalCompetitions',globalCompetitions)
            console.log('globalTeams',globalTeams)
            console.log('globalMarkets',globalMarkets)
            if (shouldLoadGames //TODO: descomentar
                //&& coreContract
                && globalSports.length !== 0
                && globalCountries.length !== 0
                && Object.keys(globalCompetitions).length !== 0
                && Object.keys(globalTeams).length !== 0
                && Object.keys(globalMarkets).length !== 0)
            {
                const _fullGames = await wrappedFullGames();
                console.log('_&_ fullGames',_fullGames)
                console.log('Object.keys(_fullGames).length === 0',Object.keys(_fullGames).length === 0)

                dispatch(slice.addFullGames(_fullGames, () => globalFullGames));
                setShouldLoadGames(false);
                if (Object.keys(_fullGames).length === 0) {
                    setIsFetchGamesResponseEmpty(true)
                }
            }
        }

        fetchGames();
    }, [shouldLoadGames, /* coreContract, */ globalSports, globalCountries, globalCompetitions, globalTeams, globalMarkets]);

    const ShowWBOpenCloseButton = () => {
        if (isWBModalVisible) {
            return (
                <FontAwesomeIcon icon={icon({name: 'xmark'})} />
            )
        }
    }

    //Get sports from Smart Contract => performance OK
    /* useEffect(() => {
        //dispatch(slice.resetFullGames(), () => globalFullGames);
        (async () => {
            const fetchedData = await fetchGames({
                hashesContract,
                coreContract,
                globalSports,
                globalCountries,
                globalCompetitions,
                globalTeams,
                globalMarkets
            });
            if (fetchedData.wrappedFullGamesObject) {
                console.log('wrapped!')
                // if (fetchedData.sportsArray.length > 0) dispatch(slice.addSports(fetchedData.sportsArray, () => globalSports));
                // if (fetchedData.countriesArray.length > 0) dispatch(slice.addCountries(fetchedData.countriesArray)); // , () => jsonCompetitions
                // dispatch(slice.addCompetitions(fetchedData.competitionsObject, () => globalCompetitions));
                // dispatch(slice.addTeams(fetchedData.teamsObject)); //, () => jsonTeams
                // dispatch(slice.addMarkets(fetchedData.marketsObject)); //
                dispatch(slice.addFullGames(fetchedData.wrappedFullGamesObject, () => globalFullGames));
            }
        })();
    }, []) */

    /*useEffect(() => {
        if (isFirstTime
            && globalCompetitions && Object.keys(globalCompetitions).length !== 0 && globalCompetitions.constructor === Object
            && globalTeams && Object.keys(globalTeams).length !== 0 && globalTeams.constructor === Object
            && globalMarkets && Object.keys(globalMarkets).length !== 0 && globalMarkets.constructor === Object
            ) {
            setIsFirstTime(false)
        }
    }, [isFirstTime, globalFullGames])*/

    useEffect(() => {
        //console.log("globalFullGames.length", globalFullGames, Object.keys(globalFullGames).length, globalFullGames.constructor === Object)
        console.log("isLoading",isLoading)
        if ((globalFullGames &&
            Object.keys(globalFullGames).length !== 0 &&
            globalFullGames.constructor === Object)
            || isFetchGamesResponseEmpty) {
            setIsLoading(false)
        } else {
            setIsLoading(true)
        }
    }, [globalFullGames, isFetchGamesResponseEmpty])

    const filterGames = (fullGames, _sport, _competition, day) => { //TODO: Revisar esta funcion, ya que devuelve undefineds => se ha añadido replace(/-/g,'_'), es posible que ya no ocurran
        const sport = _sport !== undefined ? utils.replaceCharacter(_sport, '-', '_') : undefined;
        const competition = _competition !== undefined ? utils.replaceCharacter(_competition, '-', '_') : undefined;
        let result = { ...fullGames };
      
        console.log('Home.js fullGames[_sport], sportParam',fullGames, sportParam)
        if (!Object.hasOwn(globalFullGames, sportParam) && sportParam !== undefined) {
            return null
        } else if (sport && competition) {
          result = { [sport]: { [competition]: result[sport]?.[competition] } };
        } else if (sport) {
          result = { [sport]: result[sport] };
        }
      
        /*if (day) { //TODO: OBTENIDO DE CHATGPT, PENDIENTE DE REVISAR
          result = Object.fromEntries(
            Object.entries(result).map(([sportKey, sportValue]) => [
              sportKey,
              Object.fromEntries(
                Object.entries(sportValue).map(([competitionKey, competitionValue]) => [
                  competitionKey,
                  competitionValue.filter(game => utils.getDayLabel(game.timestamp) === day)
                ])
              )
            ])
          );
        }*/
      
        return result;
    };
      

    const memoizedFullGames = useMemo(() => {
        if (
            !isLoading
            && sportParam !== undefined
            && !Object.hasOwn(globalFullGames, sportParam)
        ) {
            return (
                <div className = "dp-flex-full-centered h-30vh">
                    {`There are no games to show for ${sportParam}`}
                </div>
            )
        } else if (!isLoading
            && globalFullGames
            && Object.keys(globalFullGames).length !== 0
            && globalFullGames.constructor === Object) {
                const filteredGames = filterGames(globalFullGames, sportParam, competitionParam);
                console.log('filteredGames',filteredGames)
                return Object.keys(filteredGames).map((sportParam, index) => {
                    return (<div id='main-events-container' className='grid-card'>
                        <React.Fragment key={sportParam}>
                            <div className='grid-card-sport-header'>
                                <div className='dp-flex mr-12'>
                                    <SportIcon sport={sportParam} />
                                </div>
                                <div className='left-menu-text-div'>{utils.capitalize(sportParam)}</div>
                            </div>
                            <GridCard key={`${sportParam}-${index}`} network={useNetwork} sport={sportParam} games={filteredGames[sportParam]} />
                        </React.Fragment>
                    </div>
                    )
                })
            }
    }, [globalFullGames, isLoading, competitionParam, sportParam]);

    /* const NoGamesToShow = () => {
        console.log('NoGamesToShow',isLoading, Object.keys(globalFullGames).length,noGamesToShow)
        if (
            (!isLoading
                && (Object.keys(globalFullGames).length === 0
                || noGamesToShow)
            )
            ||
            (!isLoading
                && sportParam !== undefined
                && Object.keys(globalFullGames[sportParam]).length === 0
            )
        ) {
            return (
                <div className = "dp-flex-full-centered h-30vh">
                    {`There are no games to show for ${sportParam}`}
                </div>
            )
        }
    } */

    const authorizeDiscordUser = () => {
        window.location.href = process.env.REACT_APP_DISCORD_URL_AUTHORIZATION;
        //sendDiscordUserToken()
    }

    const JoinDiscordServerButton = () => {
        return (
            <button className='btn-medium bg-gradient-yellow-orange br-5 ml-8 mr-8 mb-12 pt-0 pr-16 pb-0 pl-16 h-40 lh-40'
                onClick={() => authorizeDiscordUser()}>
                Join Discord
            </button>
        )
    }

    const ShowLoadingIcon = () => {
        const accountClassName = 'mt-8 mb-8 bg-white dp-block spinner-loader'
        return (
            <div className = {accountClassName}></div>
        )
    }

    return (
        <div className='main-div'>
            <div id='main-parent-container'>
                <div id='main-container'>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <React.Fragment key={'main-subcontainer'}>
                            <div id='carousel'>
                                <div className='carousel-right-div'>
                                    <h1 className='carousel-right-div-title gradient-text-color-orange'>
                                        The fully decentralized cryptocurrency sportsbook
                                    </h1>
                                    <h2 className='carousel-right-div-text'>
                                        Pure blockchain betting, no registration required, no deposits, no limits
                                    </h2>
                                </div>
                            </div>
                            {welcomeBonus.isEnabled && !welcomeBonus.isEnjoyed && (
                                <>
                                    <div id='wb-thin-banner' className='wb-thin-banner'>
                                        <div className='wb-thin-banner-left-div'>
                                            <p className='text-m mr-8'><span className='fw-900'>WELCOME BONUS:</span> We double your bet!</p>
                                            <p className='text-m'>Join our Discord server and spend <span className='fw-900'>{wallet.tokenSymbol ? wallet.tokenSymbol : utils.defaultCurrencySymbol}20-50</span> on 1.5 odds or higher</p>
                                        </div>

                                        {welcomeBonus.isApplied ? (
                                            <div className='wb-thin-banner-applied-div'>
                                                <p><span className='text-m fw-900 won-bet'><FontAwesomeIcon icon={icon({name: 'check'})} /> Applied</span></p>
                                            </div>
                                        ) : (
                                            false
                                        )}
                                        <div className='wb-thin-banner-right-div'>
                                            <button className='btn-medium bg-gradient-yellow-orange br-5 ml-8 mr-8 pt-0 pr-16 pb-0 pl-16 h-40 lh-40'
                                                onClick={() => setIsWBModalVisible(true)}>
                                                More info
                                            </button>
                                        </div>
                                    </div>
                                    <div id='wb-modal' className={isWBModalVisible ? 'modal-panel' : 'dp-none'} >
                                        <div id='wb-overlap-background' className={isWBModalVisible ? 'overlap-background' : 'dp-none'} onClick={() => setIsWBModalVisible(false)}>
                                            <div id='wb-open-close-button' className='responsive-open-close-button' /*onClick={toggleAccountVisible}*/>
                                                <ShowWBOpenCloseButton />
                                            </div>
                                        </div>
                                        <div id='wb-submodal' className={isWBModalVisible ? 'modal-subpanel' : 'dp-none'}>
                                            <div id='wb-open-close-button' className='open-close-button' onClick={() => setIsWBModalVisible(false)}>
                                                <ShowWBOpenCloseButton />
                                            </div>
                                            <p><span className='text-xl fw-900'>WELCOME BONUS</span></p>
                                            
                                            {welcomeBonus.isApplied ? (
                                                <>
                                                    <p className='fw-500'>Spend a minimum of <span className='fw-900'>{wallet.tokenSymbol ? wallet.tokenSymbol : utils.defaultCurrencySymbol}20</span> and a maximum of <span className='fw-900'>{wallet.tokenSymbol ? wallet.tokenSymbol : utils.defaultCurrencySymbol}50</span> on 1.5 odds or higher. We will double your bet automatically!</p>
                                                    <p><span className='text-xl fw-900 won-bet'><FontAwesomeIcon icon={icon({name: 'check'})} /> Applied</span></p>
                                                </>
                                            ) : wallet.address === null ? (
                                                <>
                                                    <p className='fw-500'>Join our Discord server now and spend a minimum of <span className='fw-900'>{wallet.tokenSymbol ? wallet.tokenSymbol : utils.defaultCurrencySymbol}20</span> and a maximum of <span className='fw-900'>{wallet.tokenSymbol ? wallet.tokenSymbol : utils.defaultCurrencySymbol}50</span> on 1.5 odds or higher. We will double your bet automatically!</p>
                                                    <p className='fw-500'>First, let's connect your wallet</p>
                                                    <div className="dp-flex-full-centered">
                                                        {wallet.isConnected ? <ShowLoadingIcon /> : <ConnectButton />}
                                                    </div>
                                                </>
                                            ) : wallet.address !== null
                                                && welcomeBonus.discordNickname === '' ? (
                                                <>
                                                    <p className='fw-500'>Well done! Now join our Discord server </p>
                                                    <JoinDiscordServerButton />
                                                </>
                                            ) : (
                                                <>
                                                    <p className='fw-500'>Join our Discord server now and spend a minimum of <span className='fw-900'>{wallet.tokenSymbol ? wallet.tokenSymbol : utils.defaultCurrencySymbol}20</span> and a maximum of <span className='fw-900'>{wallet.tokenSymbol ? wallet.tokenSymbol : utils.defaultCurrencySymbol}50</span> on 1.5 odds or higher. We will double your bet automatically!</p>
                                                    <JoinDiscordServerButton />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}

                            {(!welcomeBonus.isEnabled || welcomeBonus.isEnjoyed) && welcomeBonus.discordNickname === '' && (
                                <>
                                    <div id='wb-thin-banner' className='wb-thin-banner'>
                                        <div className='wb-thin-banner-left-div'>
                                            <p className='text-m'><span className='fw-900'>Join our Discord server now</span> and find out about the best odds for each matchday!</p>
                                        </div>

                                        <div className='wb-thin-banner-right-div'>
                                            <JoinDiscordServerButton />
                                        </div>
                                    </div>
                                </>
                            )}
                            
                            {memoizedFullGames}
                        </React.Fragment>
                    )}
                </div>
                <BetSlip />
            </div>
            {!isLoading &&
                <Footer/>
            }
        </div>
    );
};

export default Home;