import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Outlet, useParams } from 'react-router-dom';
import * as utils from '../../utils/utils.js';

import Logo from "../Logo/Logo.js";
import useGetURLParam from '../utils/useGetURLParam.js';

const IpBlocker = ({ children, userIp }) => {
    const [userCountry, setUserCountry] = useState({locked: false, undefined});
    //const [unlocked, setUnlocked] = useState(undefined);
    const blockedCountries = utils.blockedCountries;
    const unlocked = {unlockedParam: undefined};
    const unlockedParam = useGetURLParam('u');
    if (unlockedParam !== undefined) {
        unlocked.unlockedParam = unlockedParam;
    }
    console.log('IPBlocker.js unlocked',unlocked)

  useEffect(() => {
    const checkIsUserLocked = () => {
        if (userIp !== undefined) {
            //const userIp = await fetchUserIp();
            const country = userIp.country;
            console.log('IPBLOCKER:',userIp)
            console.log('IPBLOCKER country:',country)

            if (utils.isIpUnlocked(userIp.ip)) {
                console.log(`Access unlocked for IP: ${userIp.ip}`);
                setUserCountry({locked: false, ...country});
            } else if (utils.isCountryLocked(country)) {
                console.log(`Access locked for country: ${country}`);
                setUserCountry({locked: true, ...country});
            } else {
                setUserCountry({locked: false, ...country});
            }
        }
    };

    checkIsUserLocked();
  }, [userIp]);

  const handleClick = () => {
    window.location.href = process.env.REACT_APP_DISCORD_URL_SERVER;
  }

  const BlockMessage = () => {
    return (
        <>
            <nav id="menu" className='p-12'>
                <div>
                    <div className="app-logo-div">
                        <Logo className="app-logo" />
                    </div>
                </div>
            </nav>
            <div className='ip-blocker-inner'>
                <div className='ip-blocker-text-alert'>
                    <h2>Sorry, this site is not available in your country.</h2>
                    <p>
                    BetcoinPro is not available in the following countries: 
                    <br />
                    {
                        Object.keys(blockedCountries).map((country, index) => {
                            return blockedCountries[country] + (index === Object.keys(blockedCountries).length - 1 ? '' : ', ')
                        })
                    }
                    </p>
                </div>
                <div className='ip-blocker-text-message m-12'>
                    <p>
                    If you think you shouldn't see this message join our Discord community.
                    <br />
                        <button className='btn-medium bg-gradient-yellow-orange br-5 mt-12 ml-8 mr-8 mb-12 pt-0 pr-12 pb-0 pl-12 h-32 lh-32'
                            onClick={() => handleClick()}>
                            Join Discord
                        </button>
                    </p>
                </div>
            </div>
        </>
    )
  }

  return (
      (userCountry === undefined && unlocked.unlockedParam === undefined)
      || userCountry.locked === false || unlocked.unlockedParam === '1' ? (
        <>
            { children }
            <Outlet />
        </>
      ) : (
        <BlockMessage />
      )
  );
};

export default IpBlocker;
