import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SingleBetGridCard from "../components/SingleBetGridCard.js";
import BetSlip from "../components/BetSlip.js";
import * as utils from "../utils/utils.js";
import * as ethersConfig from "../config/ethersConfig.js";
import * as slice from "../utils/slice.js";
import fetchBetsSetsIds from "../services/fetchBetsSetsIds.js";
import fetchBetsSet from "../services/fetchBetsSet.js";
import wrappedFullGames from "../services/fetchGames.js";
import fetchWelcomeBonusData from "../services/fetchWelcomeBonusData.js";

const MyBets = () => {
  const [windowHeight, setWindowHeight] = useState(0); //TODO: probar a mover el windowHeigh y su useEffect dentro de App.js

  useEffect(() => {
    setWindowHeight(window.innerHeight);
    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  const updateDimensions = () => {
    setWindowHeight(window.innerHeight);
  };

  const dispatch = useDispatch();
  const { data: wallet } = useSelector((state) => state.wallet);
  const { data: welcomeBonus } = useSelector((state) => state.welcomeBonus);
  const { data: userBetsSets } = useSelector((state) => state.userBetsSets);
  const { data: lastConnection } = useSelector(state => state.lastConnection);
  const [isLoadingUserBetsSets, setIsLoadingUserBetsSets] = useState(false);
  const [shouldLoadGames, setShouldLoadGames] = useState(false);

  const globalSports = useSelector((state) => state.sports.data);
  const globalCountries = useSelector((state) => state.jsonCountries.data);
  const globalCompetitions = useSelector((state) => state.jsonCompetitions.data);
  //const globalGames = useSelector((state) => state.jsonGames.data);
  const globalTeams = useSelector((state) => state.jsonTeams.data);
  const globalMarkets = useSelector((state) => state.jsonMarkets.data);
  const globalFullGames = useSelector((state) => state.fullGames.data);

  console.log("$$MyBets.js => ", wallet.network.keyName);
  const network = wallet.network.keyName
    ? wallet.network.keyName
    : utils.defaultNetwork.keyName
  const web3Config = ethersConfig.web3Config(network);
  const coreContract = web3Config.coreContract;
  const welcomeBonusContract = web3Config.welcomeBonusContract;

  const [betsSetsIds, setBetsSetsIds] = useState([]);
  const [filteredBetsSets, setFilteredBetsSets] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;
  const maxPaginationButtonsToShow = 1;

  useEffect(() => {
    console.log("useEffect betsSetsIds 1", userBetsSets);
    if (userBetsSets.length > 0) {
      console.log("useEffect betsSetsIds 2", userBetsSets);
      setTotalPages(Math.ceil(userBetsSets.length / itemsPerPage));
    }
  }, [userBetsSets.length]);

  const checkIfTimeLapsed = () => {
    const timeLapsed = utils.timeLapsedFromLastConnection(lastConnection)
    return timeLapsed;
}

  useEffect(() => {
    const timeLapsed = checkIfTimeLapsed()
    const isTimeLapsed = timeLapsed.minutesLapsed > utils.loadAfterMinutesLapsed ? true : false
    if (isTimeLapsed || Object.keys(globalFullGames).length === 0) {
        console.log('__shouldLoadGames',shouldLoadGames)
        console.log('globalFullGames',globalFullGames) 
        dispatch(slice.resetFullGames()); //, () => globalFullGames
        setShouldLoadGames(true);
        return;
    }

    /* if (Object.keys(globalFullGames).length === 0) {
        console.log('__shouldLoadGames',shouldLoadGames)
        console.log('globalFullGames',globalFullGames)
        setShouldLoadGames(true);
    } */
  }, [lastConnection])

  useEffect(() => {
    const fetchGames = async () => {
        if (shouldLoadGames
            //&&coreContract
            && globalSports.length !== 0
            && globalCountries.length !== 0
            && Object.keys(globalCompetitions).length !== 0
            && Object.keys(globalTeams).length !== 0
            && Object.keys(globalMarkets).length !== 0)
        {
            const _fullGames = await wrappedFullGames();
            console.log('_&_ fullGames',_fullGames)

            dispatch(slice.addFullGames(_fullGames, () => globalFullGames));
            setShouldLoadGames(false);
        }
    }

    fetchGames();
}, [shouldLoadGames, /* coreContract, */ globalSports, globalCountries, globalCompetitions, globalTeams, globalMarkets]);

  const generatePaginationButtons = () => {
    const buttons = [];
    const startPage = Math.max(1, currentPage - maxPaginationButtonsToShow);
    const endPage = Math.min(
      totalPages,
      currentPage + (userBetsSets.length > itemsPerPage ? maxPaginationButtonsToShow : 0)
    );
    console.log(
      "generatePaginationButtons 1 totalPages:" +
        totalPages +
        "; endPage:" +
        endPage
    );
    for (let page = startPage; page <= endPage; page++) {
      console.log(
        "generatePaginationButtons 2 page:" +
          page +
          "; startPage:" +
          startPage +
          "; endPage:" +
          endPage
      );
      buttons.push(
        <button
          key={page}
          onClick={() => setCurrentPage(page)}
          disabled={page === currentPage}
        >
          {page}
        </button>
      );
    }

    return buttons;
  };

  const PaginationButtons = () => {
    const lastPage = totalPages;

    return (
      <div className="pagination-buttons">
        <button onClick={() => setCurrentPage(1)} disabled={currentPage === 1}>
          {"<<"}
        </button>
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {"<"}
        </button>
        {generatePaginationButtons()}
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === lastPage}
        >
          {">"}
        </button>
        <button
          onClick={() => setCurrentPage(lastPage)}
          disabled={currentPage === lastPage}
        >
          {">>"}
        </button>
      </div>
    );
  };

  const getPaginatedBetsSets = useCallback(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    console.log(
      "indexOfLastItem" +
        indexOfLastItem +
        "; indexOfFirstItem" +
        indexOfFirstItem +
        "; itemsPerPage" +
        itemsPerPage
    );
    console.log("userBetsSets.slice: userBetsSets", userBetsSets);
    console.log(
      "userBetsSets.slice:",
      userBetsSets.slice(indexOfFirstItem, indexOfLastItem)
    );
    return userBetsSets.slice(indexOfFirstItem, indexOfLastItem);
  }, [currentPage, itemsPerPage, userBetsSets]);

  const getBetsSetStatus = (betsSet) => {
    let status = utils.betsSetStatus.PENDING
    let allBetsResolved = true
    console.log('getBetsSetStatus betsSet.bets: '+betsSet.bets)
    console.log(betsSet.bets)

    if (betsSet.bets !== undefined) {
      betsSet.bets.forEach(bet => {
          //console.log('getBetsSetStatus betsSet: '+betsSet+' status1 bet.game.resolved: '+bet.game.resolved+' includes:'+bet.market.resolvedOutcomeIds.includes(bet.market.outcomeId)+' allBetsResolved:'+allBetsResolved)
          if (bet.game.status === utils.gameStatus.SUSPENDED
            || bet.game.status === utils.gameStatus.CANCELLED) {
              status = utils.betsSetStatus.SUSPENDED
              console.log('&&&betsSet status: '+status)
              return
          } else if (bet.game.resolved) {
              if (!bet.market.resolvedOutcomeIds.includes(bet.market.outcomeId)) {
                status = utils.betsSetStatus.LOST
              }
          } else {
              allBetsResolved = false
          }
      })

      if (allBetsResolved && status !== utils.betsSetStatus.LOST  && status !== utils.betsSetStatus.SUSPENDED) {
          status = utils.betsSetStatus.WON
      }
    }

    console.log('getBetsSetStatus betsSet.id: '+betsSet.id+' allBetsResolved:'+allBetsResolved+' status2: '+status)

    return status
  }

  const ListOfBetsSets = () => {
    const paginatedBetsSets = getPaginatedBetsSets();
    let betsSetStatus = utils.betsSetStatus.PENDING

    return paginatedBetsSets.map(
      (betsSet) => {
        //console.log('EHHH betsSet:',betsSet)
        betsSetStatus = getBetsSetStatus(betsSet)
        console.log('#isBetsSetResolvedAndWon betsSet id: '+betsSet.id+' '+betsSetStatus)
        return betsSet.id && (
          <React.Fragment key={betsSet.id}>
            <SingleBetGridCard key={betsSet.id} betsSet={betsSet} betsSetStatus={betsSetStatus} />
          </React.Fragment>
        )
      }
    );
  }

  /*useEffect(() => {
        if (discordUserToken !== null) {
            sendDiscordUserToken(discordUserToken)
            .then((response) => {
                console.log('App.js response', response)
                if (response.success) {
                    const _welcomeBonus = {...welcomeBonus, isApplied: true}
                    dispatch(slice.updateWelcomeBonus(_welcomeBonus))
                } 
            })
            console.log('LLAMADA',discordUserToken)
        }
    }, [discordUserToken])*/

  /* useEffect(() => {
    const getBonusMultiplier = async () => {
      const bonusMultiplierResult =
        await welcomeBonusContract.getBonusMultiplier();

      if (bonusMultiplierResult) {
        const _welcomeBonus = {
          ...welcomeBonus,
          bonusMultiplier: parseInt(bonusMultiplierResult._hex),
        };
        dispatch(slice.updateWelcomeBonus(_welcomeBonus));
        console.log("wb:", _welcomeBonus);
      }
    };
    getBonusMultiplier();
  }, []); */

  useEffect(() => {
    const welcomeBonusData = async () => {
        console.log('_&_ welcomeBonusData')
        if (welcomeBonusContract && wallet.address)
        {
            const _welcomeBonusData = await fetchWelcomeBonusData({
                welcomeBonusContract: welcomeBonusContract,
                walletAddress: wallet.address,
                dataToFetch: ['bonusMultiplier']
            })
            console.log('_&_ _welcomeBonusData',_welcomeBonusData)

            const __welcomeBonusData = {
              ...welcomeBonus,
              ..._welcomeBonusData
            }

            dispatch(slice.updateWelcomeBonus(__welcomeBonusData))
        }
    }

    welcomeBonusData();
}, []);

  useEffect(() => {
    (async () => {
      if (wallet.isConnected) {
        setIsLoadingUserBetsSets(true);
        console.log("setIsLoadingUserBetsSets1", isLoadingUserBetsSets);
        if (
          globalSports &&
          globalCountries &&
          globalCompetitions &&
          globalTeams &&
          globalMarkets &&
          globalFullGames
        ) {
          const _betsSetsIds = /* [62,61,60,59,58,57,56,55,54,53,52]// */await fetchBetsSetsIds(coreContract, wallet.address);
          setBetsSetsIds(_betsSetsIds);

          for (let i = 0; i < _betsSetsIds.length; i++) {
            fetchBetsSet(
              coreContract,
              {
                sports: globalSports,
                competitions: globalCompetitions,
                teams: globalTeams,
                markets: globalMarkets,
              },
              _betsSetsIds[i]
            )
            .then(betsSet => {
              console.log(`&&&& betsSetId ${_betsSetsIds[i]}:`, betsSet);
              dispatch(slice.addUserBetsSet(betsSet));
            });
            
            if (i > itemsPerPage){
              await utils.sleep(1000);
            }
          }
          
          if (_betsSetsIds.length === 0){
            setIsLoadingUserBetsSets(false);
          }
        }
      }
    })();
  }, [wallet.address]);

  useEffect(() => {
    console.log("lengths", userBetsSets.length, betsSetsIds.length);
    if (isLoadingUserBetsSets
      && (userBetsSets.length >= itemsPerPage
      || (userBetsSets.length === betsSetsIds.length
        && userBetsSets.length > 0
        && betsSetsIds.length > 0))
    ) {
      setIsLoadingUserBetsSets(false);
      console.log("setIsLoadingUserBetsSets2", isLoadingUserBetsSets);
    }
  }, [userBetsSets, betsSetsIds]);

  //TODO: al cambiar de wallet, se debe limpiar todo

  //ESTA FUNCION INCLUYE TODAS LAS APUESTAS, INCLUIDAS LAS COBRADAS
  const getFilteredBetsSets = (userBetsSets) => {
    const bsToRedeem = [];
    userBetsSets.forEach((betsSet) => {
      if (!betsSet.paid) {
        console.log("@@ betsSet.paid => id: " + betsSet.id, betsSet.paid);
        let addToRedeem = true;
        betsSet.bets.forEach((bet) => {
          if (bet.game.resolved) {
            console.log("@@@ bet.game.resolved", bet.game.resolved);
            console.log(
              "@@@ bet.market.resolvedOutcomeIds",
              bet.market.resolvedOutcomeIds
            );
            console.log("@@@ bet.market.outcomeId", bet.market.outcomeId);
            if (
              !bet.market.resolvedOutcomeIds.includes(bet.market.outcomeId) ||
              bsToRedeem.includes(betsSet.id)
            ) {
              addToRedeem = false;
            }
            console.log(
              "@@@ if addToRedeem:",
              bet.market.resolvedOutcomeIds.includes(bet.market.outcomeId)
            );
          } else {
            addToRedeem = false;
          }
          console.log("@@@ addToRedeem:", addToRedeem, betsSet.id);
        });
        if (addToRedeem) {
          bsToRedeem.push(betsSet);
        }
      }
    });
    setFilteredBetsSets(bsToRedeem);
    console.log("@@@@ FilteredBetsSets", bsToRedeem);
  };

  const RedeemAllButton = () => {
    const redeemButtonClassName =
      "btn-very-small btn-redeem pt-0 pr-16 pb-0 pl-16 h-32 lh-32 br-5" +
      (filteredBetsSets.length > 0
        ? " bg-gradient-lightgreen-green"
        : " bg-grey btn-disabled");
    return <button className={redeemButtonClassName}>Redeem all</button>;
  };

  const NoWalletConnected = () => {
    if (!wallet.address) {
      return (
        <>
          <br />
          Please connect your wallet to see your bets
        </>
      );
    }
  };

  const ShowLoadingIcon = useCallback(() => {
    if (isLoadingUserBetsSets) {
      const loadingIconClassName = 'mt-8 dp-block spinner-loader-big-white'
      return (
        <div className = "dp-flex-full-centered h-75vh">
          <div className = {loadingIconClassName}></div>
          <div className = "mt-16">Loading bets</div>
        </div>
      )
    }
  }, [isLoadingUserBetsSets])

  return (
    <div id='main-parent-container'>
      <div id="main-container" style={{ minHeight: windowHeight }}>
        <div id="main-betssets-container" className="main-div mt-16">
          <div className="main-header">
            <div className="main-header-title">{`My Bets (${betsSetsIds.length})`}</div>
            {/*<div className='main-header-button'><RedeemAllButton /></div>*/}
          </div>
          {
            <>
              <NoWalletConnected />
              {wallet.isConnected && isLoadingUserBetsSets === true && <ShowLoadingIcon />}
              {wallet.isConnected && !isLoadingUserBetsSets && <ListOfBetsSets />}
            </>
          }
        </div>
        <>
          {wallet.isConnected && !isLoadingUserBetsSets
          && userBetsSets.length > maxPaginationButtonsToShow && (
            <PaginationButtons />
          )}
        </>
      </div>
      {<BetSlip />}
    </div>
  );
};

export default MyBets;
