import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TeamLogo from './TeamLogo.js'
//import Moment from 'react-moment';
//import moment from 'moment';
import * as utils from '../utils/utils.js';
import * as slice from '../utils/slice.js';
import RoundFlag from './RoundFlag.js';

const GridCardEvent = (props) => {
    //console.log('GridCardEvent .')
    const { network, sportKeyname, competitionKeyname, game, isoCountry } = props;
    
    const dispatch = useDispatch();
    const { loading: loadingBetslip, error: errorBetslip, data: betslip } = useSelector(state => state.betslip);
    const { data: wallet } = useSelector(state => state.wallet);
    const { data: bonus } = useSelector(state => state.bonus);
    /*useEffect(() => {
        //console.log('betslip:::',betslip);
    }, [betslip]);*/

    const globalSports = useSelector((state) => state.sports.data);

    const sport = {id: globalSports.find((sport) => sport.keyName === sportKeyname).id, desc: sportKeyname}
    //console.log('gameeee',game);
    const lang = 'en'; //TODO: make global var
    //const network = 'polygon-testnet-chainstack' //TODO: hacer variable global
    const type = 'sports'
    //const [alertMsg, setAlertMsg] = useState('');
    const gameTimestampLocal = utils.gameTimestampToLocal(game.timestamp);

    const goToGame = (event) => {
        const teams = `${utils.replaceCharacter(game.homeTeamKeyName,'_','-')}-${utils.replaceCharacter(game.awayTeamKeyName,'_','-')}`;
        const goTo = utils.buildFullUrl(network, type, sportKeyname, competitionKeyname, game.gameId, teams)
        //alert(goTo);
        event.stopPropagation();
        window.location.href = goTo;
    };

    const addBetToBetslip = (event, sport, marketId, outcomeId) => {
        console.log('addBetToBetslip', sport.id+' '+game.gameId+' '+marketId+' '+outcomeId);
        event.stopPropagation();
        utils.addBetToBetslip(dispatch, sport, game, betslip, marketId, outcomeId, lang, wallet, bonus)
    };

    const MarketHeader = ({ header }) => {
        return (
            <div className='grid-card-header-odds-market-element'>
                { header }
            </div>
        )
    }

    const MarketOdd = ({ sport, marketId, odd, blank }) => {
        const _blank = odd.outcomeId === -1 && blank ? utils.Css.BLANK : ''
        return (
            <button className={`grid-card-event-odds-market-element${_blank}`} onClick={odd.outcomeId !== -1 ? (event) => addBetToBetslip(event, sport, marketId, odd.outcomeId) : null}>
                { odd.odd }
            </button>
        )
    }

    const Market = ({ market, gameId }) => {
        console.log('GCE sport.id',sport.id)
        const headers = [];
        let odds = [];
        //const odds = [];
        let blank = false;
        //console.log('GridCardEvent Market .')
        if (sport.id === 0) {
            if (market.id === 1) {
                blank = false;
                headers.push('1','X','2');
                /*market.values.filter(valueObject => {
                    values.push(valueObject.odd);
                });*/
                //odds[odds.length].push(market.odds);
                //Sacar esto a una funciona
                market.odds.forEach((odd, index) => {
                    odds.push({
                        outcomeId: index,
                        odd: odd
                    });
                })

                //console.log('GCE odds 1',odds);
            } else if (market.id === 5) {
                blank = true;
                headers.push('Goals');
                const goals = ['2.5','3.5','1.5','4.5','0.5'];
                let foundValues = [];
                for (let v = 0; v < goals.length; v++) {
                    if (foundValues.length === 0) {
                        const currentGoal = goals[v];
                        market.values.filter((marketValue, index) => {
                            
                            //console.log('GCE marketValue',marketValue);
                            if (marketValue.includes('Over ' + currentGoal) ||
                                marketValue.includes('Under ' + currentGoal)) {
                                foundValues.push(marketValue);
                                if (foundValues.length > 0) {
                                    //const splitValues = foundValues[0].split(" ")[1];
                                    headers.push(marketValue.split(" ")[0]);
                                    odds[0] = {
                                        outcomeId: -1,
                                        odd: goals[v]
                                    };
                                }
                                odds.push({
                                    outcomeId: index,
                                    odd: market.odds[index]
                                });
                                //console.log('GCE ENTRO',marketValue);
                            }
                            //console.log('GCE foundValues',foundValues);                        
                        });
                    }
                }
                //console.log('GCE odds 5',odds);
            }
        } else if (sport.id === 1) {
            if (market.id === 182) {
                blank = false;
                headers.push('1','X','2');
                /*market.values.filter(valueObject => {
                    values.push(valueObject.odd);
                });*/
                //odds[odds.length].push(market.odds);
                //Sacar esto a una funciona
                market.odds.forEach((odd, index) => {
                    odds.push({
                        outcomeId: index,
                        odd: odd
                    });
                })

                //console.log('GCE odds 1',odds);
            } else if (market.id === 185) {
                blank = true;
                headers.push('Score');
                const startValue = 100.5;
                const endValue = 260.5;
                const goals = [];

                for (let i = startValue; i <= endValue; i++) {
                    goals.push((i).toFixed(1));
                }

                //let odds = [];
                for (let v = 0; v < goals.length; v++) {
                    if (odds.length < 2) {
                        const currentGoal = goals[v];
                        market.values.filter((marketValue, index) => {
                            if (marketValue.includes('Over ' + currentGoal)) {
                                //console.log('GCE market.values',market)
                                const foundUnderValueId = market.values.findIndex(value => value === ('Under ' + currentGoal))
                                //console.log('GCE foundUnderValueId',foundUnderValueId)
                                if (foundUnderValueId !== -1) {
                                    console.log('GCE foundValues',foundUnderValueId,market.values[foundUnderValueId])
                                    headers.push(marketValue.split(" ")[0]);
                                    headers.push(market.values[foundUnderValueId].split(" ")[0]);
                                    odds[0] = {
                                        outcomeId: -1,
                                        odd: goals[v]
                                    };
                                    odds.push({
                                        outcomeId: index,
                                        odd: market.odds[index],
                                    });
                                    odds.push({
                                        outcomeId: foundUnderValueId,
                                        odd: market.odds[foundUnderValueId],
                                    });
                                }
                                //console.log('GCE ENTRO',marketValue);
                                //console.log('GCE odds 185',odds)
                            }
                            //console.log('GCE foundValues',foundValues);                        
                        });
                    }
                }
                //console.log('GCE odds 2',odds);
            }
        }

        if (utils.marketsPriority[sportKeyname].some(marketPriorityId => marketPriorityId === market.id)) {
            
            return (
                <div className='grid-card-event-row-right-odds-element'>
                    <div className='grid-card-header-odds'>
                        <div className='grid-card-subheader-odds'>
                            <div className='grid-card-header-odds-market'>
                                {
                                    headers.map((header, index) => (
                                        <MarketHeader key={`${market.id}-${index}`} header={header} />
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className='grid-card-event-odds-group'>
                        <div className='grid-card-event-odds-market-group'>
                            {
                                odds.map((odd) => (
                                    <MarketOdd sport={sport} marketId={market.id} odd={odd} blank={blank} />
                                ))
                            }
                        </div>
                    </div>
                </div>
            )
        }
    }

    return (
        <>
            <div className='grid-card-event-line'></div>
            <div className='grid-card-event-link' key={game.gameId} onClick={(event) => goToGame(event)}>
                <div className='grid-card-event-row'>
                    <div className='grid-card-event-row-left-details'>
                        <div className='grid-card-event-row-left-blank'></div>
                        <div className='grid-card-event-detail'>
                            <div className='grid-card-event-name'>
                                <div className='grid-card-event-participant'>
                                    <div className='grid-card-event-participant-logo'>
                                        {game.homeTeamIsoCountry === '-'
                                            ? <RoundFlag country={game.homeTeamLogoKeyname} />
                                            : <TeamLogo sportKeyname={sportKeyname} isoCountry={game.homeTeamIsoCountry} teamLogoKeyname={game.homeTeamLogoKeyname} size={'small'} />
                                        }
                                    </div>
                                    <div className='grid-card-event-participant-name'>
                                        {game.homeTeamShortName}
                                    </div>
                                </div>
                                <div className='grid-card-event-participant'>
                                    <div className='grid-card-event-participant-logo'>
                                        {game.awayTeamIsoCountry === '-'
                                            ? <RoundFlag country={game.awayTeamLogoKeyname} />
                                            : <TeamLogo sportKeyname={sportKeyname} isoCountry={game.awayTeamIsoCountry} teamLogoKeyname={game.awayTeamLogoKeyname} size={'small'} />
                                        }
                                    </div>
                                    <div className='grid-card-event-participant-name'>
                                        {game.awayTeamShortName}
                                    </div>
                                </div>
                            </div>
                            <div className='grid-card-event-date'>
                                <span>{ //TODO: pasar este codigo a una funcion
                                    gameTimestampLocal.dayLabel
                                } / {
                                    gameTimestampLocal.timeLabel
                                }
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='grid-card-event-row-right-odds'>
                        {
                            game.markets.map(market => (
                                <Market key={market.id} market={market} gameId={game.gameId}/>
                            ))
                        }
                    </div>
                </div>
            </div>
        </>         
    )
};

export default GridCardEvent;