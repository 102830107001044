import countries from './world_countries.json'
import { ResponsiveChoroplethCanvas } from '@nivo/geo'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const NivoCanvas = ({ data }) => {
    console.log('Statistics data',data)
    return (
        <ResponsiveChoroplethCanvas
            data={data}
            features={countries.features}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            colors="blues"
            domain={[ 0, 1000000 ]}
            unknownColor="#ffffff"
            label="properties.name"
            valueFormat=".2s"
            projectionScale={125}
            projectionTranslation={[ 0.5, 0.5 ]}
            projectionRotation={[ 0, 0, 0 ]}
            graticuleLineColor="rgba(0, 0, 0, .2)"
            borderWidth={0.5}
            borderColor="#101b42"
            legends={[
                {
                    anchor: 'bottom-left',
                    direction: 'column',
                    justify: true,
                    translateX: 20,
                    translateY: -60,
                    itemsSpacing: 0,
                    itemWidth: 92,
                    itemHeight: 18,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 18
                }
            ]}
        />
    )
}

export default NivoCanvas;