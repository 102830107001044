import axios from 'axios';

const fetchTeams = async () => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVER}/getTeams`)
        console.log('fetchTeams.js response',response);
        return response.data.data;
    } catch (error) {
        console.error(error);
        return false
    }
}

export default fetchTeams;