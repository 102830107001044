import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as utils from './utils/utils.js';
import * as ethersConfig from './config/ethersConfig.js';
import * as slice from './utils/slice.js';

import sendDiscordUserToken from './services/sendDiscordUserToken.js'
import useGetURLParam from './components/utils/useGetURLParam.js'
import { LoaderProvider } from './context/loader.jsx'
import Home from "./pages/Home";

import { Polygon, Mumbai } from '@thirdweb-dev/chains';

console.log('MUMBAI:',Mumbai)
console.log('POLYGON:',Polygon)

/*TODO: crear variable global con la version de frontend, para cuando quiera hacer
algun cambio y que se haga efectivo, comprobar si la version que tiene cargada
el usuario es menor que la que tnego seteada aqui, y entonces forzar el borrado
de las variables globales y volver a cargarlas, por ejemplo si quiero mostrar/cargar
en el navegador una nueva funcionalidad (la cual por ejemplo no carga si fullGames ya existe)
Digamos que el efecto sería el mismo que cuando borro los datos del navegador a mano
para hacer efectivos los nuevos cambios
*/

const App = () => {
    const dispatch = useDispatch();
    //useEffect(() => {
        const referral = useGetURLParam('referral')
        if (referral !== undefined) {
            dispatch(slice.setReferralAddress(referral))
        }
    //}, [])

    //const discordUserToken = utils.getDiscordUserToken()
    
    const [isCurrentVersionGreater, setIsCurrentVersionGreater] = useState(false)
    const { data: version } = useSelector(state => state.version);
    console.log('version A',version)
    
    useEffect(() => {
        console.log('version B',version)
        //console.log('utils.isCurrentVersionGreater(version)',utils.isCurrentVersionGreater(version))
        if (version === undefined || version === null || version.length === 0) {
            console.log('version C => inicializamos con nueva version:',version)
            dispatch(slice.addVersion(utils.currentVersion))
        } else {
            console.log('version D => comprobamos para actualizar con nueva version:',version)
            if (utils.isCurrentVersionGreater(version)) {
                console.log('version E => actualizamos con nueva version:',utils.currentVersion)
                dispatch(slice.addVersion(utils.currentVersion))
            }
        }
    }, [version, isCurrentVersionGreater])
    console.log('isCurrentVersionGreater',isCurrentVersionGreater)
    
    const { data: lastConnection } = useSelector(state => state.lastConnection);
    console.log('_lastConnectionTimestamp A',lastConnection)
    const currentTimestamp = utils.currentTimestamp
    console.log('currentTimestamp',currentTimestamp)

    
    
    useEffect(() => {
        console.log('_lastConnectionTimestamp B',lastConnection)
        //console.log('utils.isCurrentVersionGreater(version)',utils.isCurrentVersionGreater(version))
        /*if (lastConnection === undefined || lastConnection === null || lastConnection.length === 0) {
            console.log('_lastConnectionTimestamp C => inicializamos con nueva lastConnectionTimestamp:',utils.currentTimestamp)
            dispatch(slice.addLastConnection(utils.currentTimestamp))
        } else {*/
        dispatch(slice.addLastConnection(utils.currentTimestamp))
            console.log('_lastConnectionTimestamp D => comprobamos para actualizar con nueva lastConnectionTimestamp:',utils.currentTimestamp)
            if (utils.isTimeLapsedReached(lastConnection)) { // If X hour has been reached, clear global fullGames variable to fetch it again
                //console.log('_lastConnectionTimestamp E => actualizamos con nueva version:',utils.currentTimestamp)
                //dispatch(slice.addLastConnection(utils.currentTimestamp))
            }
        //}
    }, [lastConnection])
    //console.log('isCurrentVersionGreater',isCurrentVersionGreater)

    const { data: wallet } = useSelector(state => state.wallet);

    const network = wallet.network.keyName
        ? wallet.network.keyName
        : utils.defaultNetwork.keyName
    //const web3Config = ethersConfig.setNetworkConfig('local');
    const web3Config = ethersConfig.web3Config(network)
    const hashesContract = web3Config.hashesContract;
    const coreContract = web3Config.coreContract;
    const welcomeBonusContract = web3Config.welcomeBonusContract;
    //console.log('App.js coreContract',coreContract)
    console.log('App.js web3Config1',web3Config)
    
    /* const { data: welcomeBonus } = useSelector(state => state.welcomeBonus);
    useEffect(() => {
        if (discordUserToken !== null) {
            sendDiscordUserToken(discordUserToken)
            .then((response) => {
                console.log('App.js response', response)
                if (response.success) {
                    const _welcomeBonus = {...welcomeBonus, isApplied: true}
                    dispatch(slice.updateWelcomeBonus(_welcomeBonus))
                } 
            })
            console.log('LLAMADA',discordUserToken)
        }
    }, [discordUserToken]) */

    //TODO: Hacer que cuando userBetsSets cambie, se actualice el balance

    const [isLeftMenuToggleOn, setIsLeftMenuToggleOn] = useState(false);

    return (
    	<div className='main-div'>
            {/* <Layout> */}
                <LoaderProvider>
                    <Home contracts={{
                        hashesContract: hashesContract,
                        coreContract: coreContract,
                        welcomeBonusContract: welcomeBonusContract
                    }} />
                </LoaderProvider>
            {/* </Layout> */}
        </div>
    );
}

export default App;
