import { createContext, useEffect, useRef, useState } from "react";
import * as utils from "../../utils/utils.js";
import { useSelector } from "react-redux";
import { putUserInfo } from "../../services/putUserInfo.js";
var _ = require('lodash');

export const StatisticsContext = createContext();

export const StatisticsProvider = ({ children, userIp }) => {
  const wallet = useSelector((state) => state.wallet.data);
  const userInfo = useRef({});
  const prevUserInfo = useRef({});
  const isFirstLoading = useRef(true);

  const recordVisit = (userInfo) => {
    console.log("Statistics recordVisit:", userInfo);
    putUserInfo(userInfo);
  };

  const buildUserInfo = (wallet) => {
    const URL = window.location.href;

    if (wallet.isConnected !== undefined && wallet.isConnected !== null) {
      const userInfo = {
        ip: userIp?.ip,
        country: userIp?.country,
        locked: utils.isCountryLocked(userIp?.country),
        city: userIp?.city,
        hostname: userIp?.hostname,
        org: userIp?.org,
        url: URL,
        action: "",
        app: { version: utils.currentVersion },
        device: {
          userAgent: window.navigator.userAgent,
          platform: window.navigator.platform,
          appName: window.navigator.appName,
          appVersion: window.navigator.appVersion,
          width: window.innerWidth,
          height: window.innerHeight,
        },
        wallet: {
          isConnected: wallet.isConnected !== null ? wallet.isConnected : false,
          address: wallet.address !== null ? wallet.address : null,
          network: wallet.network !== null ? wallet.network : null,
          balance: wallet.balance !== null ? wallet.balance : null,
          currency: wallet.tokenSymbol !== null ? wallet.tokenSymbol : null,
          isWBApplied:
            wallet.isWelcomeBonusApplied !== null
              ? wallet.isWelcomeBonusApplied
              : false,
        },
      };

      return userInfo;
    }

    return undefined;
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (
        userIp !== undefined &&
        wallet.isConnected !== undefined &&
        wallet.isConnected !== null
      ) {
        const builtUserInfo = buildUserInfo(wallet);
        const isUserInfoEqualThanBuildUserInfo = _.isEqual(userInfo.current, builtUserInfo)
        userInfo.current = builtUserInfo;
        console.log("Statistics userInfo wallet:", wallet);
        console.log("isEqual: ",isUserInfoEqualThanBuildUserInfo)
        console.log('userInfo.current',userInfo.current)
        console.log('buildUserInfo(wallet)',builtUserInfo)

        if (
          ((builtUserInfo.wallet.isConnected === false &&
            builtUserInfo.wallet.address === null &&
            builtUserInfo.wallet.balance === null &&
            builtUserInfo.wallet.network.keyName === null) ||
          (builtUserInfo.wallet.isConnected === true &&
            builtUserInfo.wallet.address !== null &&
            builtUserInfo.wallet.network !== null &&
            builtUserInfo.wallet.balance !== null))
            && !isUserInfoEqualThanBuildUserInfo
            && !_.isEqual(prevUserInfo.current, userInfo.current)
        ) {
          //console.log("Statistics userInfo:", userInfo);
          //const bUserInfo = buildUserInfo(wallet);

          if (isFirstLoading.current) {
            isFirstLoading.current = false;
            if (document.referrer !== '') {
              const referrerURL = new URL(document.referrer);
              const referringSite = referrerURL.hostname;
              console.log('User comes from:', referringSite);
              builtUserInfo['referrerUrl'] = referringSite;
            } else {
              console.log('Can\'t know where user comes from');
              builtUserInfo['referrerUrl'] = '';
            }

            console.log("Statistics buildUserInfo:", builtUserInfo);
            recordVisit(builtUserInfo);
            prevUserInfo.current = builtUserInfo;
          }
        }
      }
    };

    fetchUserInfo();
  }, [userIp, wallet]);

  return (
    <StatisticsContext.Provider value={{ userInfo: prevUserInfo }}>
      {children}
    </StatisticsContext.Provider>
  )
};
