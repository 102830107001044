import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import * as utils from '../../utils/utils.js';
import LogoBW from "../Logo/LogoBW.js";
import XLogo from "../Logo/XLogo.js";
import DiscordLogo from "../Logo/DiscordLogo.js";
import InstagramLogo from "../Logo/InstagramLogo.js";
import { useSelector } from "react-redux";

const Footer = () => {
  const { data, isLoading, error } = useSelector((state) => state.fullGames.data);

  const currentYear = moment().format('YYYY');

  if (isLoading) {
    return false;
    //return <div>LOADINGGGGG</div>
    //return data
  }
  return (
    <footer id='footer'>
      <section className='footer-section-logos'>
        <div className='footer-logo'>
          <LogoBW />
        </div>
        <div className='footer-sn-icons'>
          <a href={process.env.REACT_APP_X_URL} target="_blank" rel="noreferrer">
            <XLogo className='footer-sn-icon' />
          </a>
          <a href={process.env.REACT_APP_DISCORD_URL_SERVER} target="_blank" rel="noreferrer">
            <DiscordLogo className='footer-sn-icon' />
          </a>
          <a href={process.env.REACT_APP_INSTAGRAM_URL} target="_blank" rel="noreferrer">
            <InstagramLogo className='footer-sn-icon' />
          </a>
        </div>
      </section>

      <section className='footer-section-sc'>
        <div className='footer-sc-title'>Official Contracts:</div>
        <div className='footer-scs'>
          <div className='footer-sc'>
            <div className='footer-sc-name'>
              Core
            </div>
            <div className='footer-sc-address'>
              <a
                href='https://polygonscan.com/address/0x35e756bdd4993D88331D3213bcc88c1DDCaaf3Ad'
                target='_blank'
                rel='noreferrer'
              >
                <FontAwesomeIcon icon={icon({name: 'circle-check'})} />&nbsp;
                {utils.getShortWalletAddress('0x35e756bdd4993D88331D3213bcc88c1DDCaaf3Ad')}
              </a>
            </div>
          </div>

          <div className='footer-sc'>
            <div className='footer-sc-name'>
              Liquidity
            </div>
            <div className='footer-sc-address'>
              <a
                href='https://polygonscan.com/address/0x604Ce3B83dC2D9dB06cD9e781A9b7eA1867621b6'
                target='_blank'
                rel='noreferrer'
              >
                <FontAwesomeIcon icon={icon({name: 'circle-check'})} />&nbsp;
                {utils.getShortWalletAddress('0x604Ce3B83dC2D9dB06cD9e781A9b7eA1867621b6')}
              </a>
            </div>
          </div>
        </div>
      </section>

      <div className='footer-copyright' >
        © {currentYear} betcoinpro.app
      </div>
    </footer>
  );
};

export default Footer;