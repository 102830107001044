import React from 'react';
import * as utils from '../utils/utils.js';

const teamsLogosPath = '/assets/teams-logos/';

const TeamLogo = ({ sportKeyname, isoCountry, teamLogoKeyname, size }) => {
    return (
        <img 
        //utils.genericTeamLogoHash
            //src={`${utils.baseUrl}${teamsLogosPath}${isoCountry}/${teamKeyname}.png`} 
            src={teamLogoKeyname === 'generic-team-logo' ? `${utils.baseUrl}${teamsLogosPath}${teamLogoKeyname}.png` : `${utils.baseUrl}${teamsLogosPath}${sportKeyname}/${isoCountry}/${teamLogoKeyname}.png`} 
            //src={`https://ipfs.io/ipfs/${ipfsHash}`} 
            alt='' 
            className={`team-logo-${size}`}
        />
    );
};

export default TeamLogo;