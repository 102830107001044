import React, { useState, useEffect } from 'react';
import { Outlet, Link } from 'react-router-dom';
import Football from '../../assets/icons/sports/Football.js';
import Basketball from '../../assets/icons/sports/Basketball.js';
import Tennis from '../../assets/icons/sports/Tennis.js';
import Baseball from '../../assets/icons/sports/Baseball.js';
import Golf from '../../assets/icons/sports/Golf.js';
import Volleyball from '../../assets/icons/sports/Volleyball.js';
import AmericanFootball from '../../assets/icons/sports/AmericanFootball.js';
import LiveIcon from '../../assets/icons/others/LiveIcon.js';
import { useSelector } from 'react-redux';
import * as utils from '../../utils/utils.js';
import RoundFlag from '../RoundFlag.js';

const LeftMenu = (props) => {
    const [windowHeight, setWindowHeight] = useState(0);
    const [navBarHeight, setNavBarHeight] = useState(0);
    const [isLeftMenuVisible, setIsLeftMenuVisible] = useState(false);
    const { navBarRef, handleLeftMenuClick, resetIsLeftMenuToggleOn, isLeftMenuToggleOn } = props;
    
    const wallet = useSelector(state => state.wallet.data);
    const globalSports = useSelector((state) => state.sports.data);
    const globalFullGames = useSelector((state) => state.fullGames.data);
    const globalCountries = useSelector((state) => state.jsonCountries.data);
    const globalCompetitions = useSelector((state) => state.jsonCompetitions.data);
    //console.log('LeftMenu.js globalCompetitions 1',globalCompetitions)
    useEffect(() => {
        setWindowHeight(window.innerHeight);
        setNavBarHeight(navBarRef.current.clientHeight);
        setIsLeftMenuVisible(true);
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    const networkKeyname = () => {
        if (wallet.network.keyName) {
            console.log('LeftMenu.js networkKeyname',wallet.network.keyName)
            return wallet.network.keyName;
        }
        console.log('LeftMenu.js networkKeyname',utils.defaultNetwork.keyName)
        return utils.defaultNetwork.keyName;
    }

    const updateDimensions = () => {
        setWindowHeight(window.innerHeight);
    };

    const handleMenuItemClick = (sport) => {
        window.location.href = `/${networkKeyname()}/sports/${sport}`;
    }

    const handleSubmenuItemClick = (sport, competition) => {
        window.location.href = `/${networkKeyname()}/sports/${sport}/${competition}`;
    }

    const MenuItem = ({sport}) => {
        return (
            <div className='left-menu-item' onClick={() => handleMenuItemClick(sport)} >
                <div className='left-menu-icon-div'>
                    {sport === 'football' && <Football className='icon' />}
                    {sport === 'basketball' && <Basketball className='icon' />}
                    {sport === 'tennis' && <Tennis className='icon' />}
                    {sport === 'baseball' && <Baseball className='icon' />}
                    {sport === 'golf' && <Golf className='icon' />}
                    {sport === 'volleyball' && <Volleyball className='icon' />}
                    {sport === 'american_football' && <AmericanFootball className='icon' />}
                </div>
                <div className='left-menu-text-div'>{utils.replaceCharacter(utils.capitalize(sport), '_-', ' ')}</div>
            </div>
        )
    }

    const SubmenuItems = ({ sport, competitions }) => {
        if (!competitions) {
            return null;
        }
        console.log('LeftMenu.js competitions', competitions);
      
        const competitionKeys = Object.keys(competitions);
      
        const sortedCompetitions = competitionKeys
          .map((competitionKey) => {
            const competitionInGlobal = globalCompetitions[sport]?.find(c => c.keyName === competitionKey);
      
            if (!competitionInGlobal) {
              console.error(`Competition "${competitionKey}" not found in globalCompetitions[${sport}]`);
            }
      
            return competitionInGlobal;
          })
          .filter(Boolean)
          .sort((a, b) => a.relevance - b.relevance);
      
        const competitionElements = sortedCompetitions.map((competition) => {
            const country = globalCountries.find(c => c.isoCountry === competition.isoCountry);
            const competitionKeyname = utils.replaceCharacter(competition.keyName, '_', '-')
            console.log('LeftMenu.js competitionKeyname',competitionKeyname)

            return (
                <li key={competition.keyName}>
                    <div className='left-menu-subitem' onClick={() => handleSubmenuItemClick(sport, competitionKeyname)} >
                        <div className='left-submenu-icon-div'>
                        {country?.isoCountry === competition.isoCountry ? <RoundFlag country={country?.isoCountry} /> : ''}
                        </div>
                        <div className='left-submenu-text-div'>
                        {country?.isoCountry === competition.isoCountry ? `${competition.fullName} (${competitions[competition.keyName].length})` : country?.shortName}
                        </div>
                    </div>
                </li>
            );
        });
      
        return <ul>{competitionElements}</ul>;
    };

    const ProvisionalMenuItems = () => {
        return (
            <>
                {/* <div className='left-menu-item-disabled'>
                    <div className='left-menu-icon-div'>
                        <Basketball className='icon' />
                    </div>
                    <div className='left-menu-text-div'>Basketball <span className='soon-text'>soon</span></div>
                </div> */}
                <div className='left-menu-item-disabled'>
                    <div className='left-menu-icon-div'>
                        <Tennis className='icon' />
                    </div>
                    <div className='left-menu-text-div'>Tennis <span className='soon-text'>soon</span></div>
                </div>
                <div className='left-menu-item-disabled'>
                    <div className='left-menu-icon-div'>
                        <Baseball className='icon' />
                    </div>
                    <div className='left-menu-text-div'>Baseball <span className='soon-text'>soon</span></div>
                </div>
                {/* <div className='left-menu-item-disabled'>
                    <div className='left-menu-icon-div'>
                        <Golf className='icon' />
                    </div>
                    <div className='left-menu-text-div'>Golf <span className='soon-text'>soon</span></div>
                </div> */}
                <div className='left-menu-item-disabled'>
                    <div className='left-menu-icon-div'>
                        <Volleyball className='icon' />
                    </div>
                    <div className='left-menu-text-div'>Volleyball <span className='soon-text'>soon</span></div>
                </div>
                {/* <div className='left-menu-item-disabled'>
                    <div className='left-menu-icon-div'>
                        <AmericanFootball className='icon' />
                    </div>
                    <div className='left-menu-text-div'>American Football <span className='soon-text'>soon</span></div>
                </div> */}
                <div className='left-menu-item-disabled'>
                    <div className='left-menu-icon-div'>
                        <LiveIcon className='icon' />
                    </div>
                    <div className='left-menu-text-div'>Live <span className='soon-text'>soon</span></div>
                </div>
            </>
        )
    }

    const MenuItems = () => {
        console.log('LeftMenu.js globalFullGames',globalFullGames)
        return (
            <>
            {globalSports.length > 0
            && Object.keys(globalFullGames).length > 0
            && globalSports.map((sport) => {
                console.log('LeftMenu.js sport',sport.keyName)
                console.log('LeftMenu.js globalFullGames[sport.keyName]',globalFullGames[sport.keyName])
                return (
                    <div key={sport.keyName}>
                        <li>
                            {
                                <MenuItem sport={sport.keyName} />
                            }
                            <ul>
                                <SubmenuItems sport={sport.keyName} competitions={globalFullGames[sport.keyName]} />
                            </ul>
                        </li>
                    </div>
                )
            })}
            <ProvisionalMenuItems />
            </>
        )
    }

    return (
        <>
            <nav id='left-menu' style={{height: isLeftMenuVisible && windowHeight - navBarHeight, top: isLeftMenuVisible && navBarRef.current.clientHeight}} className={isLeftMenuToggleOn ? '' : 'dp-none-mobile'} /*isLeftMenuToggleOn={state.isLeftMenuToggleOn}*/>
                <ul onClick={resetIsLeftMenuToggleOn}>
                    <MenuItems />
                </ul>
            </nav>
            <div id='left-menu-overlap-background' className={isLeftMenuToggleOn ? 'top-overlap-background' : 'dp-none-mobile'} onClick={resetIsLeftMenuToggleOn}></div>
        </>
    );
}

export default LeftMenu;