import * as React from "react"

const SvgComponent = (props) => (
  <svg
    viewBox="118.882 54.067 80.949 80.95"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <clipPath id="a" clipPathUnits="userSpaceOnUse">
        <path d="M2781.22 2534.96h-.15.15m-.5 0h-.17.17m-.5 0c-.06.01-.11.01-.16.01.05 0 .11 0 .16-.01m-.51.01h-.15.15m-.5 0h-.14.14m-.5.01h-.14.14m-.51 0h-.11.11m-.5.01h-.1.1m-.52 0c-.02 0-.04.01-.06.01.02 0 .04-.01.06-.01m-.51.01h-.04.04m-.51.01h-.02.02m309.09 303.92v.05-.05m0 .41V2839.46v-.12m0 .41v.18-.18m0 .39v.26-.26m0 .49c0 .08 0 .16-.01.24.01-.08.01-.16.01-.24m-.01.48v.23-.23m0 .48v.22-.22m-.01.48v.2-.2m0 .48c0 .05 0 .11-.01.17.01-.06.01-.11.01-.17m-.01.47v.15-.15m-.01.48v.09-.09m0 .47c0 .01 0 .03-.01.04.01-.01.01-.03.01-.04" />
      </clipPath>
    </defs>
    <title>{"football"}</title>
    <path
      style={{
        fill: "#f5f5f5",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M159.359 54.069c-22.355 0-40.475 18.121-40.475 40.473 0 22.353 18.12 40.473 40.475 40.473 22.35 0 40.47-18.12 40.47-40.473 0-22.352-18.12-40.473-40.47-40.473"
    />
    <path
      style={{
        fill: "#2c2a2a",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="m163.255 90.901-17.179-8.69 1.548-14.981 15.631-6.889 14.281 7.738 1.148 15.38-15.429 7.442m-15.126 29.684-16.628-10.487.25-17.377 13.581-9.188 17.177 8.689 1 20.073-15.38 8.29m49.524-26.41-8.24 3.447-9.437-13.832-1.149-15.38 8.563-1.452 6.778 6.94a40.754 40.754 0 0 0-3.304-4.751l-2.783-2.526.14-.44c-6.493-6.608-15.234-10.996-24.988-11.923l-.661 5.333-15.629 6.892h-12.286l.882-4.654c-.506.369-1.002.748-1.49 1.14l-.464 4.21-8.788 13.483-4.93 5.028a40.02 40.02 0 0 0-.366 1.843l5.192-5.688 5.942 10.685-.251 17.377-3.995 4.744-3.661-2.89c.429.912.893 1.802 1.388 2.676l1.24.813-.323.731c.279.444.562.886.856 1.32.006-.303.024-.612.059-.93l10.165 11.295a40.297 40.297 0 0 0 8.863 4.709l5.771 1.011 9.956 1.596a41.353 41.353 0 0 0 4.566-.401l-14.23-2.618-2.048-10.285 15.381-8.289 16.127 2.796v11.085l-10.506 6.324a40.348 40.348 0 0 0 15.352-8.032l-3.585 1.068v-11.085l7.738-16.479 8.24-3.445 1.648 4.897c.29-1.951.443-3.945.443-5.976 0-4.331-.687-8.499-1.945-12.409l-.231 12.042"
    />
    <path
      style={{
        fill: "#b9b8b7",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="M159.359 135.015h-.2l-.022-.001h-.132l-.048-.001h-.082l-.052-.002h-.07l-.008-.001h-.065l-.063-.001h-.002c-11.243-.203-21.358-4.998-28.571-12.579.481.04.965.075 1.451.103l4.618 5.133a40.297 40.297 0 0 0 8.863 4.709l5.771 1.011 9.956 1.596a41.353 41.353 0 0 0 4.566-.401l-14.23-2.618-2.048-10.285 4.409-2.376a54.841 54.841 0 0 0 11.5-5.821l15.599 2.704v11.085l-10.506 6.324a40.348 40.348 0 0 0 15.352-8.032l-3.585 1.068v-11.085l7.738-16.479 8.24-3.445 1.648 4.897c.283-1.903.436-3.847.443-5.825-.081 22.282-18.169 40.322-40.47 40.322m30.054-37.393-4.868-7.135a54.722 54.722 0 0 0 4.933-21.392l4.69 4.803a40.754 40.754 0 0 0-3.304-4.751l-1.368-1.241v-.252l-.003-.12c6.273 6.996 10.151 16.183 10.329 26.275.002.001.002.004.002.005v.063l.001.044v.06l.001.022v.064c0 .012 0 .023.002.035v.125l.001.032v.07a40.482 40.482 0 0 0-1.945-12.196l-.231 12.042-8.24 3.447m10.416-2.939v-.029.029m0-.062v-.022.022m0-.138v-.018V94.483m0-.058v-.022.022m0-.058v-.022.022"
    />
    <path
      style={{
        fill: "#232121",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      d="m160.703 134.982-9.956-1.596-5.771-1.011a40.297 40.297 0 0 1-8.863-4.709l-4.618-5.133a50.67 50.67 0 0 0 3.03.094 54.862 54.862 0 0 0 18.875-3.325l-4.409 2.376 2.048 10.285 14.23 2.618a41.353 41.353 0 0 1-4.566.401m9.29-1.388 10.506-6.324v-11.085l-15.599-2.704a55.196 55.196 0 0 0 19.645-22.994l4.868 7.135 8.24-3.447.231-12.042a40.482 40.482 0 0 1 1.945 12.196V94.693a40.549 40.549 0 0 1-.443 5.825l-1.648-4.897-8.24 3.445-7.738 16.479v11.085l3.585-1.068a40.348 40.348 0 0 1-15.352 8.032m24.175-59.696-4.69-4.803c.01-.396.016-.792.018-1.189l1.368 1.241a40.754 40.754 0 0 1 3.304 4.751"
    />
  </svg>
)

export default SvgComponent
