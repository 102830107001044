// Fetch welcome bonus data from smart contract

const fetchWelcomeBonusData = async ({
  welcomeBonusContract,
  walletAddress,
  dataToFetch,
}) => {
  //console.log('fetchWelcomeBonusData.js', welcomeBonusContract, walletAddress, dataToFetch)
  try {
    const _welcomeBonus = {};
    const data =
      dataToFetch[0] === "all"
        ? ["isEnabled", "isEnjoyed", "isUserApplicableForBonus", "bonusMultiplier"]
        : dataToFetch;
    const promises = [];

    if (data.includes("isEnabled")) {
      promises.push(
        welcomeBonusContract.isWelcomeBonusEnabled().then((result) => {
          _welcomeBonus["isEnabled"] = result;
        })
      );
    }

    if (data.includes("isEnjoyed") && walletAddress) {
      promises.push(
        welcomeBonusContract.didBettorEnjoyWelcomeBonus(walletAddress).then((result) => {
          _welcomeBonus["isEnjoyed"] = result;
        })
      );
    }

    if (data.includes("isUserApplicableForBonus") && walletAddress) {
      promises.push(
        welcomeBonusContract.isUserApplicableForBonus(walletAddress).then((result) => {
          _welcomeBonus["isUserApplicableForBonus"] = result;
        })
      );
    }

    if (data.includes("bonusMultiplier")) {
      promises.push(
        welcomeBonusContract.getBonusMultiplier().then((result) => {
          _welcomeBonus["bonusMultiplier"] = parseInt(result._hex);
        })
      );
    }
    await Promise.all(promises);

    console.log("fetchWelcomeBonusData data", _welcomeBonus);

    const welcomeBonus = {
      ..._welcomeBonus
    }

    return welcomeBonus;
  } catch (error) {
    console.error("Error getting welcome bonus data from smart contract", error);
    return {};
  }
};

export default fetchWelcomeBonusData;



    
    