import * as React from "react"

const SvgComponent = (props) => (
  <svg
    viewBox="118.882 54.067 80.949 80.95"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <clipPath id="a" clipPathUnits="userSpaceOnUse">
        <path d="M1981.96 2965.24c-86.52 0-148.53 27.61-156.68 37.83-1.44 1.81-3.04 4.68-4.68 8.44.05.84.1 1.73.15 2.65 2.85-.28 5.74-.53 8.65-.76-.21-3.63-.33-6.61-.4-8.87 13.16 1.06 37.92 3.45 69.53 8.3 53.02 3.27 112.11 15.45 173.36 42.59 68.82 24.54 141.76 61.86 191.19 117.82 47.97 54.33 77.46 110.73 95.57 158.99 24.84 54.04 35.97 105.91 39.03 151.83.17-.36.35-.73.53-1.11-.42-17.64-2.71-50.45-12.59-92.16-13.59-57.31-44.73-142.57-116.04-223.3-31.61-35.79-75.01-67.12-129.05-93.13-43.06-20.73-92.96-38.15-148.27-51.79-69.44-17.11-130.32-23.89-157.77-26.3 21.62-12.31 76.24-31.03 147.47-31.03 38.5 0 81.85 5.46 127.48 20.36-45.63-14.89-88.99-20.36-127.48-20.36" />
      </clipPath>
    </defs>
    <g transform="matrix(.99155 0 0 .99155 1.005 -1.734)">
      <title>{"football"}</title>
      <path
        style={{
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
          fill: "#5e2112",
        }}
        d="M141.719 73.677c-29.548 23.464-22.972 52.916-20.279 56.296 2.152 2.697 32.444 14.484 60.544-11.201 26.571-24.287 17.671-51.758 14.677-54.654-2.502-2.421-25.396-13.905-54.942 9.559"
      />
      <path
        style={{
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
          fill: "#9d491d",
        }}
        d="M141.719 73.677c-27.429 21.783-23.729 48.724-20.903 55.177.148-2.87.726-9.328 2.964-17.134 1.78-6.206 4.246-12.048 7.328-17.364 3.858-6.648 8.698-12.487 14.386-17.354 5.69-4.868 11.999-8.604 18.751-11.105a60.765 60.765 0 0 1 16.927-3.624c5.839-.405 10.592.084 13.573.571-6.517-3.503-27.237-9.647-53.026 10.833"
      />
      <path
        style={{
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
          fill: "#9d491d",
        }}
        d="M181.205 63.432c-8.976.626-22.325 3.644-34.958 14.45-12.66 10.832-18.599 24.552-21.352 34.158-2.487 8.668-2.892 15.612-2.959 17.738 3.236-.261 11.73-1.122 21.511-3.536 11.444-2.821 27.041-8.401 36.366-18.958 9.346-10.586 13.432-21.763 15.211-29.275 1.701-7.183 1.68-12.449 1.647-13.616-2.363-.508-8.08-1.479-15.466-.961"
      />
      <path
        style={{
          fill: "#f46e38",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        d="M196.152 78.277a69.3 69.3 0 0 1-15.472 29.773c-4.215 4.772-10.001 8.95-17.207 12.418-5.741 2.764-12.394 5.086-19.769 6.905-9.258 2.281-17.376 3.185-21.036 3.507 6.312 3.596 33.724 11.285 59.316-12.108 23.597-21.571 19.22-45.65 15.847-52.798-.055 2.348-.359 6.731-1.679 12.303"
      />
      <path
        style={{
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
          fill: "#822f1b",
        }}
        d="M140.279 106a1.232 1.232 0 0 0-1.708 1.774l4.779 4.599c.238.231.546.344.853.344.323 0 .645-.125.888-.377.471-.49.455-1.27-.035-1.742L140.279 106M144.759 100.981a1.234 1.234 0 0 0-1.741.065 1.233 1.233 0 0 0 .065 1.74l4.904 4.552a1.227 1.227 0 0 0 1.739-.066 1.232 1.232 0 0 0-.064-1.739l-4.903-4.552M149.678 96.06a1.232 1.232 0 1 0-1.643 1.833l5.027 4.505c.233.211.528.315.82.315a1.231 1.231 0 0 0 .822-2.148l-5.026-4.505M154.598 91.769a1.232 1.232 0 0 0-1.611 1.863l5.15 4.457a1.23 1.23 0 1 0 1.611-1.861l-5.15-4.459M159.985 87.236a1.233 1.233 0 0 0-1.581 1.889l5.276 4.411a1.228 1.228 0 0 0 1.733-.154 1.232 1.232 0 0 0-.153-1.734l-5.275-4.412M165.133 82.96a1.23 1.23 0 1 0-1.548 1.914l5.399 4.364a1.23 1.23 0 1 0 1.548-1.913l-5.399-4.365M170.263 78.686a1.23 1.23 0 0 0-1.728.211 1.23 1.23 0 0 0 .21 1.729l5.523 4.318a1.23 1.23 0 1 0 1.517-1.94l-5.522-4.318M176.167 74.636a1.231 1.231 0 0 0-1.487 1.965l5.648 4.271c.221.169.484.249.743.249.372 0 .741-.168.981-.488a1.23 1.23 0 0 0-.236-1.724l-5.649-4.273"
      />
      <path
        style={{
          fill: "#fff",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        d="M139.192 105.729a1.23 1.23 0 0 0-1.708 1.775l4.78 4.6a1.23 1.23 0 0 0 1.74-.035 1.23 1.23 0 0 0-.034-1.74l-4.778-4.6M143.672 100.71a1.231 1.231 0 0 0-1.676 1.806l4.903 4.55c.237.222.537.33.837.33a1.233 1.233 0 0 0 .838-2.134l-4.902-4.552M148.592 95.789a1.232 1.232 0 1 0-1.644 1.835l5.027 4.505a1.231 1.231 0 0 0 1.643-1.835l-5.026-4.505M153.512 91.498a1.23 1.23 0 1 0-1.61 1.862l5.149 4.46c.233.201.518.298.805.298a1.229 1.229 0 0 0 .805-2.161l-5.149-4.459M158.899 86.966a1.232 1.232 0 1 0-1.58 1.888l5.274 4.411a1.226 1.226 0 0 0 1.734-.155 1.23 1.23 0 0 0-.155-1.733l-5.273-4.411M164.047 82.689a1.23 1.23 0 1 0-1.548 1.915l5.397 4.364a1.23 1.23 0 1 0 1.549-1.914l-5.398-4.365M169.177 78.416a1.23 1.23 0 1 0-1.518 1.938l5.522 4.319a1.233 1.233 0 0 0 1.73-.212 1.23 1.23 0 0 0-.211-1.727l-5.523-4.318M175.081 74.365a1.232 1.232 0 0 0-1.485 1.965l5.647 4.271a1.228 1.228 0 0 0 1.724-.239 1.233 1.233 0 0 0-.239-1.725l-5.647-4.272"
      />
      <g
        clipPath="url(#a)"
        transform="matrix(.13333 0 0 -.13333 -121.93 530.381)"
      >
        <path
          style={{
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            fill: "#5e2112",
          }}
          d="M1820.6 2965.24h577.609v518.82H1820.6Z"
        />
      </g>
      <path
        style={{
          fill: "#f14529",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        d="M120.816 128.848c-.05-.118-.102-.24-.153-.371.057.007.115.012.173.017l-.02.354"
      />
      <path
        style={{
          fill: "#ee2822",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        d="M159.345 132.297c.006-.003.016-.005.022-.007l-.022.007M159.381 132.285l.026-.008-.026.008M159.427 132.27l.018-.006-.018.006"
      />
      <path
        style={{
          fill: "#ac9583",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        d="m182.047 118.714.002-.002.006-.004c.001-.002-.002 0 .001-.002l-.009.008m.038-.034.006-.006-.006.006m.036-.034.004-.002-.004.002m4.26-4.261v-.001.001m.028-.031.004-.005-.004.005m.031-.033.003-.004-.003.004m.028-.031.012-.014-.012.014m.031-.033.06-.068c-.019.021-.042.045-.06.068m.068-.076c9.345-10.303 12.912-20.857 13.749-29.624v-.003l.001-.017.002-.016.001-.009c.176-1.862.229-3.64.188-5.32.235 9.594-2.593 22.478-13.941 34.989"
      />
      <path
        style={{
          fill: "#ee2822",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
        }}
        d="m182.035 118.726.014-.014-.002.002-.012.012m.02-.018c12.746-11.682 17.3-24.095 18.261-34.151-.837 8.767-4.404 19.321-13.749 29.624l-.008.008-.06.068-.019.019-.012.014-.025.027-.003.004-.027.028-.004.005-.028.03v.001a69.456 69.456 0 0 1-4.256 4.259l-.004.002-.03.028-.006.006-.029.026c-.003.002 0 0-.001.002m18.261-34.154.001-.017-.001.017m.003-.033.001-.009-.001.009"
      />
      <path
        style={{
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
          fill: "#822f1b",
        }}
        d="M121.936 129.778c.01-.301.026-.698.054-1.182a65.808 65.808 0 0 0 9.217.076c-4.215.646-7.516.965-9.271 1.106m32.386-6.785c6.905-3.06 14.018-7.545 21.019-13.945 8.303-7.59 13.756-15.435 17.215-22.963a68.182 68.182 0 0 1-12.743 21.199c-6.59 7.461-16.316 12.437-25.491 15.709"
      />
      <path
        style={{
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
          fill: "#822f1b",
        }}
        d="M142.331 135.017c-9.497 0-16.78-2.496-19.663-4.137 3.66-.322 11.778-1.226 21.036-3.507 7.375-1.819 14.028-4.141 19.769-6.905 7.206-3.468 12.992-7.646 17.207-12.418a69.3 69.3 0 0 0 15.472-29.773c1.317-5.561 1.623-9.936 1.679-12.288 1.201 2.547 2.53 7.24 2.677 13.203a44.895 44.895 0 0 1-.188 5.32l-.001.009-.002.016-.001.017v.003c-.961 10.056-5.515 22.469-18.261 34.151l-.006.004-.014.014-.051.046c-7.551 6.902-15.259 11.098-22.539 13.492l-.018.006a.118.118 0 0 0-.02.007l-.026.008-.014.005c-.006.002-.016.004-.022.007a54.677 54.677 0 0 1-17.014 2.72"
      />
    </g>
  </svg>
)

export default SvgComponent
