// Fetch bonus data from smart contract

const fetchBonusData = async ({
  bonusContract,
  walletAddress,
  dataToFetch,
}) => {
  console.log('fetchBonusData.js', bonusContract, walletAddress, dataToFetch)
  try {
    const _bonus = {};
    const data =
      dataToFetch[0] === "all"
        ? ["isEnabled", "isUserApplicableForBonus", "bonusAmount"]
        : dataToFetch;
    const promises = [];

    if (data.includes("isEnabled")) {
      promises.push(
        bonusContract.isBonusEnabled().then((result) => {
          _bonus["isEnabled"] = result;
        })
      );
    }

    if (data.includes("isUserApplicableForBonus") && walletAddress) {
      promises.push(
        bonusContract.isUserApplicableForBonus(walletAddress).then((result) => {
          _bonus["isUserApplicableForBonus"] = result;
        })
      );
    }

    if (data.includes("bonusAmount")) {
      promises.push(
        bonusContract.getBettorBonus(walletAddress).then((result) => {
          _bonus["bonusAmount"] = parseInt(result._hex);
        })
      );
    }
    await Promise.all(promises);

    console.log("fetchBonusData data", _bonus);

    const bonus = {
      ..._bonus
    }

    return bonus;
  } catch (error) {
    console.error("Error getting bonus data from smart contract", error);
    return {};
  }
};

export default fetchBonusData;



    
    