import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GridCardEvent from './GridCardEvent.js';
import RoundFlag from './RoundFlag.js';
import Icon from './Icon.js';
//import { ethers } from 'ethers';
//import IPFS from 'ipfs-core';
//import { setNetworkConfig } from '../config/ethersConfig.js';
//const ethersConfig = require('../config/ethersConfig.js');
//const coreAbi = require('../abi/core-abi.json');
//import coreAbi from '../abi/core-abi.json';
const utils = require('../utils/utils.js');
//import * as store from '../utils/store.js';

const SingleGameGridCards = (props) => {
  const { game, sportKeyname } = props;

  const dispatch = useDispatch();
  const { loading: loadingBetslip, error: errorBetslip, data: betslip } = useSelector(state => state.betslip); //TODO: ver si es mas eficiente car la betslip en Game.js y pasarla a SingleGameGridCards.js por parametro, para que asi solo cargue una vez
  const { data: wallet } = useSelector(state => state.wallet);
  const { data: bonus } = useSelector(state => state.bonus);
  const globalCompetitions = useSelector((state) => state.jsonCompetitions.data);
  const globalSports = useSelector((state) => state.sports.data);
  
  console.log('globalSports',globalSports)
  const sport = globalSports && globalSports.length > 0
  ? //TODO: this is a workaround. When browser cache is cleared, and as first enter this page, globalSports is undefined
  {id: globalSports.find((sport) => sport.keyName === sportKeyname).id, desc: sportKeyname}
  : //
  utils.goToHome()
  
  const [orderedGameMarkets,setOrderedGameMarkets] = useState([])
  const lang = 'en'; //TODO: make global var
  
  console.log('game GameGridCard',game)
  console.log('globalCompetitions -> ',globalCompetitions)

  const addBetToBetslip = (event, sport, marketId, outcomeId) => {
    console.log('addBetToBetslip', sport.id+' '+game.gameId+' '+marketId+' '+outcomeId);
    event.stopPropagation();
    utils.addBetToBetslip(dispatch, sport, game, betslip, marketId, outcomeId, lang, wallet, bonus)
  };

  const Markets = () => {
    return (
      <>
        {orderedGameMarkets.map((market) =>
          market.values.length > 0 && (
          <React.Fragment key={market.id}>
            <div
              id={`main-container-market-${market.id}`}
              className={utils.hasGameStarted(game.timestamp)
                ? 'main-div game-grid-card-market text-disabled'
                : 'main-div game-grid-card-market'}
              >
              <div className={utils.hasGameStarted(game.timestamp)
                ? 'game-grid-card-header text-disabled'
                : 'game-grid-card-header'}>
                <div className='left-menu-text-div'>
                  {utils.hasGameStarted(game.timestamp) && <Icon icon={'lock'} classname={'icon-small mr-6'} />}
                  {market.description[lang]}
                </div>
              </div>
              <div className='grid-card-event-line'></div>
              <div className='game-grid-card-event-row-odds'>
                {market.values.map((value, index) => (
                    <React.Fragment key={`${market.id}-${index}`}>
                      <div className='game-grid-card-odds-market-element'>
                        <div className='game-grid-card-header-odds-market-element'>
                          {market.values[index]}
                        </div>
                        <button
                          className='game-grid-card-event-odds-market-element'
                          onClick={(event) => addBetToBetslip(event, sport, market.id, market.outcomesIds[index])}
                          disabled={utils.hasGameStarted(game.timestamp)}
                        >
                          {market.odds[index]}
                        </button>
                      </div>
                    </React.Fragment>
                ))}
              </div>
            </div>
          </React.Fragment>
        ))}
      </>
    )
  }

  useEffect(() => {
    if(Object.keys(game).length !== 0 && game !== undefined){
      console.log('game markets undefined',game)
      setOrderedGameMarkets(utils.reorderSingleGameMarkets(sportKeyname, game.markets))
    }
  }, [game])
  
  return(
    <>
    <div id='main-markets-container' className='main-div mt-16'>
      <div className='grid-card-sport-header'>
        <div className='left-menu-text-div'>{game.homeTeamFullName} - {game.awayTeamFullName}</div>
      </div>
      <Markets />
    </div>
    </>
  )
}

export default SingleGameGridCards;