import React, { useState, useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
//import { ethers } from 'ethers';
//import * as ethersConfig from '../config/ethersConfig.js';
import PolygonLogo from '../../assets/icons/crypto/PolygonLogo.js';
import UsdcLogo from '../../assets/icons/crypto/UsdcLogo.js';
import DaiLogo from '../../assets/icons/crypto/DaiLogo.js';
//import { utils } from 'ethers';
import * as utils from '../../utils/utils.js';
/* import { useContractRead, useContract } from "@thirdweb-dev/react";
import GetTokenData from './GetTokenData.js'
import TokenAllowance from './utils/TokenAllowance.js'

import tokenAbi from '../abi/token-abi.json'; */

const NetworkButton = (props) => {
    //const [isToggleOn, setIsToggleOn] = useState(true);
    const dispatch = useDispatch();
    const { data: wallet } = useSelector(state => state.wallet);
    const [isNetworkVisible, setIsNetworkVisible] = useState(false);

    /*const web3Config = ethersConfig.web3Config
    const contractAddress = web3Config.tokenAddress;*/

    /*const { contract } = useContract(contractAddress, tokenAbi);

    if (contract) {
        console.log('contract',contract)
    }

    const { data, isLoading, error } = useContractRead(
        contract,
        'balanceOf',
        ['0x12198B1eD2e27cC0326bAC76E828Aea8760c12D1'],
    );

    if (error) {
        console.error("data: failed to read contract", error);
    }

    if (isLoading) {
        console.log('isLoading',isLoading)
    }

    if (data) {
        console.log('data',data)
    }

    useEffect(() => {
        //(async () => {
            console.log('error',error)
        //})()
    }, [error]);

    useEffect(() => {
        //(async () => {
            console.log('data',data)
        //})()
    }, [data]);

    useEffect(() => {
        (async () => {
            console.log('__balance',wallet)
            if (wallet.isConnected && !wallet.balance) getAccountBalance()
        })()
    }, [wallet]);*/

  
    const getAccountBalance = async () => {
        /*if (wallet.isConnected) {
            //await ethersConfig.getAccountBalance(dispatch, wallet)

        }*/
        /*const balance = await contract.erc20.symbol()
        alert(`Balance is ${balance}`)*/
    }

    const toggleNetworkVisible = () => {
        /*setIsNetworkVisible(prevState => !prevState);*/
        getAccountBalance()
    }

    /*const showTokenName = (data) => {
        console.log('Nombre del token:', data.name);
        console.log('data.displayValue:', data.displayValue);

        return (
            <>
            {data.displayValue !== null ? parseInt(data.displayValue) : 'Loading...'}
            </>
        )
    }*/

    /*const ShowLoadingIcon = () => {
        const accountClassName = 'mt-8 dp-block'
        return (
            <FontAwesomeIcon icon={icon({name: 'spinner'})} spin className = {accountClassName} />
        )
    }*/

    const ShowLoadingIcon = () => {
        const accountClassName = 'mt-8 dp-block spinner-loader'
        return (
            <div className = {accountClassName}></div>
        )
    }

    const ShowNetworkButton = () => {
        const networkButtonClassName = 'btn-small btn-network bg-gradient-darkblue-blue br-5 mt-16 mb-16 pt-0 pr-12 pb-0 pl-12 h-32 lh-32 '
        if (wallet && wallet.isConnected) {
        console.log('ShowNetworkButton');
            return (
                <>
                    <button className = {networkButtonClassName}
                    onClick={() => toggleNetworkVisible()/*ethersConfig.handleConnectWallet(dispatch, wallet)*/}>
                        <span className = "pt-7"><PolygonLogo className='icon' /></span>
                        {/* <span className = "pt-7 pr-4"><UsdcLogo className='icon' /></span> */}
                        <span className = "pt-7 pr-4"><DaiLogo className='icon' /></span>
                        <span className = "">{wallet.balance !== null ? utils.parseBalance(wallet.balance) : <ShowLoadingIcon />}
                            {/*<GetTokenData render={(data) => (
                                <>
                                    {console.log('Balance1:', data)}
                                    {balance !== null ? data : 'Loading...'}
                                </>
                            )} address={wallet.address} />*/}
                        </span>
                        <span className = "vertical-left-line-dark-blue dp-none"><ShowDownArrowButton /></span>
                    </button>
                    <div id='network-panel' className={isNetworkVisible ? 'modal-panel dp-block' : 'dp-none'}>
                        <div id='network-subpanel' className={isNetworkVisible ? 'modal-subpanel dp-block' : 'dp-none'}>
                            
                        </div>
                    </div>
                    <div id='network-overlap-background' className={isNetworkVisible ? 'top-overlap-background' : 'dp-none'} onClick={() => setIsNetworkVisible(false)}></div>
                </>
            )
        }
    }

    /*const ShowAccountDetails = () => {
        const accountDetailsClassName = 'btn-small btn-connect bg-yellow-orange mt-16 mr-16 mb-16 pt-0 pr-12 pb-0 pl-12 h-32 lh-32 '
        + (!wallet.isConnected ? 'br-5' : 'btrr-5 bbrr-5')
        if (wallet && wallet.isConnected) {
            return (
                <button className = {accountDetailsClassName}
                onClick={() => ethersConfig.handleConnectWallet(dispatch, wallet)}>
                    <FontAwesomeIcon icon={icon({name: 'sort-down'})} />
                </button>
            )
        }
    }*/

    const ShowDownArrowButton = () => {
        const accountClassName = 'ml-12 dp-block'
        return (
            <FontAwesomeIcon icon={icon({name: 'sort-down'})} className = {accountClassName} />
        )
    }

    return (
        <>
            <ShowNetworkButton />
        </>
    );

    /*return (
        <>
            <Web3Button
              contractAddress="0xc852D36e2700553a94f2Ebc7Fc41477CB3aDB3e3"
              action={async (contract) => contract.call("symbol")}
              onSuccess={(result) => console.log(result)}
              // If the function fails, we can do something here.
              onError={(error) => console.error(error)}
            >
              Call myFunctionName from the connected wallet
            </Web3Button>
        </>
    )*/
}

export default memo(NetworkButton);
