import axios from 'axios';

const fetchCompetitions = async () => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVER}/getCompetitions`)
        console.log('fetchCompetitions.js response',response);
        return response.data.data;
    } catch (error) {
        console.error(error);
        return false
    }
}

export default fetchCompetitions;