import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './utils/store';
import { ThirdwebProvider, metamaskWallet, walletConnect } from "@thirdweb-dev/react";
import { Polygon, Mumbai, BaseSepoliaTestnet } from "@thirdweb-dev/chains";

import './fonts/roboto-condensed/RobotoCondensed-Bold.ttf';
import './fonts/roboto-condensed/RobotoCondensed-BoldItalic.ttf';
import './fonts/roboto-condensed/RobotoCondensed-Italic.ttf';
import './fonts/roboto-condensed/RobotoCondensed-Light.ttf';
import './fonts/roboto-condensed/RobotoCondensed-LightItalic.ttf';
import './fonts/roboto-condensed/RobotoCondensed-Regular.ttf';
import Layout from './components/Layout/Layout';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Game from './pages/Game';
import MyBets from './pages/MyBets';
import Statistics from './pages/admin/Statistics';
import GetToken from './pages/GetToken';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />
  },
  {
    path: '/:network/',
    element: <App />
  },
  {
    path: '/:network/sports/',
    element: <App />
  },
  {
    path: '/:network/sports/:sportParam',
    element: <App />
  },
  {
    path: '/:network/sports/:sportParam/:competitionParam',
    element: <App />
  },
  {
    path: '/:network/sports/:sportParam/:competitionParam/:gameIdParam/:teams?',
    element: <Game />
  },
  {
    path: '/:network/my-bets',
    element: <MyBets />
  },
  {
    path: '/:network/get-token',
    element: <GetToken />
  },
  {
    path: '/admin/statistics',
    element: <Statistics />
  }
  ])

const root = ReactDOM.createRoot(document.getElementById('root'));
const supportedWallets = []
if (window.ethereum) {
  supportedWallets.push(metamaskWallet())
}
supportedWallets.push(walletConnect({
  projectId: process.env.REACT_APP_WC_PROJECTID
}))
//const mumbaiCustomRpc = `https://polygon-mumbai.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_API_KEY}`
//const mumbaiCustomRpc = `https://rpc.ankr.com/polygon_mumbai/${process.env.REACT_APP_ANKR_API_KEY}`
/* const mumbaiCustomChain = {
  slug: 'mumbai',
  chainId: '80001',
  nativeCurrency: {
    name: 'Matic',
    symbol: 'MATIC',
    decimals: 18
  },
  rpc: [mumbaiCustomRpc],
  explorers: ['https://mumbai.polygonscan.com/']
} */
/* const mumbaiCustomChain = {
  [80001]: `https://rpc.ankr.com/polygon_mumbai/${process.env.REACT_APP_ANKR_API_KEY}`,
} */

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThirdwebProvider
        activeChain={BaseSepoliaTestnet}
        //chainRpc={mumbaiCustomChain}
        //rpcUrl={mumbaiCustomRpc}
        supportedWallets={supportedWallets}
        /* customChains={{
          Mumbai: {
            rpcUrl: mumbaiCustomRpc
          }
        }} */
        dAppMeta={{
          name: "BetcoinPro",
          description: "The fully decentralized cryptocurrency sportsbook",
          logoUrl: "https://www.betcoinpro.app/logo512.png",
          url: "https://www.betcoinpro.app",
          isDarkMode: true,
        }}
        supportedChains={[Polygon, BaseSepoliaTestnet]}
        clientId={process.env.REACT_APP_TW_CLIENT_ID}>
          <Layout>
            <RouterProvider router={router} />
          </Layout>
      </ThirdwebProvider>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
