import React from 'react';
import * as utils from '../utils/utils.js';

function Icon(props) {
    return (
        <img
        src={`${utils.iconsPath}${props.icon}.svg`}
        alt=''
        className={props.classname}
        />
        /*return <><img 
            src={`https://ipfs.io/ipfs/QmXUMVbbffKuaf8y7Z3FKhN6hhTRz9KDuWcBy5xPhwNVXz`} 
            alt='' className={this.props.classname}
        />
        <img 
            src={`https://ipfs.io/ipfs/QmfPLykbyQ7oQSAfdZy2Xo5VDw2NRHjAJiWiskLo1BAsd2`} 
            alt='' className={this.props.classname}
        />
        <img 
            src={`https://ipfs.io/ipfs/QmbvaN8JhsoPux6qA4DAtrwJ2B8S27F5YcqexiCWQiLBFz`} 
            alt='' className={this.props.classname}
        />
        <img 
            src={`https://ipfs.io/ipfs/QmTPek9AKS4GpqZXzvSc5LJi7xKip2GhwzS1A7N9TpKjxj`} 
            alt='' className={this.props.classname}
        />
        <img 
            src={`https://ipfs.io/ipfs/QmfB9X62qptmdvmd1xCdNmSXgiUukCZBHEpNxoLSraLgAF`} 
            alt='' className={this.props.classname}
        />
        <img 
            src={`https://ipfs.io/ipfs/QmZTGUzJbVmQ7oX7tcjwqXj2bd2BKKUCr8HzNRotpgxe2X`} 
            alt='' className={this.props.classname}
        />
        <img 
            src={`https://ipfs.io/ipfs/QmREyHiPccTgBj74gWv3JHFaVuiuykKhtBLyEHscLtFDjP`} 
            alt='' className={this.props.classname}
        />
        <img 
            src={`https://ipfs.io/ipfs/QmbY9Dm3PSb9qkYGK9J8EW3f5FtkkwWD8FXsB63hDiDPfK`} 
            alt='' className={this.props.classname}
        />
        <img 
            src={`https://ipfs.io/ipfs/QmfNxSBKBZpqM5V1iE9CM8zFrG5hhsta7d9jtTeW9oJbZR`} 
            alt='' className={this.props.classname}
        />
        <img 
            src={`https://ipfs.io/ipfs/QmYUAH8551Wj664MQXWS4qF2U9ZcGGsJvmW7NGo2SnnYwg`} 
            alt='' className={this.props.classname}
        />
        </>*/
    );
}

export default Icon;