import React, { useState, useEffect, useCallback, memo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ethers } from 'ethers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import TeamLogo from './TeamLogo.js'
import GridCardEvent from './GridCardEvent.js';
import Football from '../assets/icons/sports/Football.js';
import Basketball from '../assets/icons/sports/Basketball.js';
import Tennis from '../assets/icons/sports/Tennis.js';
import Baseball from '../assets/icons/sports/Baseball.js';
import Golf from '../assets/icons/sports/Golf.js';
import Volleyball from '../assets/icons/sports/Volleyball.js';
import AmericanFootball from '../assets/icons/sports/AmericanFootball.js';
import RoundFlag from './RoundFlag.js';
import * as ethersConfig from '../config/ethersConfig.js';
import * as slice from '../utils/slice.js';
import RedeemButton from './utils/RedeemButton.js';
//import { ethers } from 'ethers';
//import IPFS from 'ipfs-core';
//import { setNetworkConfig } from '../config/ethersConfig.js';
//const ethersConfig = require('../config/ethersConfig.js');
//const coreAbi = require('../abi/core-abi.json');
//import coreAbi from '../abi/core-abi.json';
const utils = require('../utils/utils.js');
//import * as store from '../utils/store.js';

const SingleBetGridCard = (props) => {
  const { betsSet, betsSetStatus } = props;
  console.log('***betsSet', betsSet)
  console.log('***SingleBetGridCard betsSetStatus ', betsSetStatus)

  const dispatch = useDispatch();
  const { data: wallet } = useSelector(state => state.wallet);
  const { data: welcomeBonus } = useSelector(state => state.welcomeBonus);
  //const [isBetsSetResolvedAndWon, setIsBetsSetResolvedAndWon] = useState(false)
  //const isBetsSetResolvedAndWon = useRef(null)
  //const { loading: loadingUserBetsSets, error: errorUserBetsSets, data: userBetsSets } = useSelector(state => state.userBetsSets);
  /*const { loading: loadingBetslip, error: errorBetslip, data: betslip } = useSelector(state => state.betslip);
  const globalCompetitions = useSelector((state) => state.jsonCompetitions.data);*/

  const globalCompetitions = useSelector((state) => state.jsonCompetitions.data);
  const globalCountries = useSelector((state) => state.jsonCountries.data);
  
  const lang = 'en'; //TODO: make global var

  const web3Config = ethersConfig.web3Config(wallet.network.keyName)
  console.log('SingleBetGridCard web3Config',web3Config)
  const coreContract = web3Config.coreContract;
  const tokenContract = web3Config.tokenContract;

  //const checkIsBetsSetResolvedAndWon = useCallback(() => {
  /*useEffect(() => {
    if (betsSet !== undefined){
      //setIsBetsSetResolvedAndWon(isBetsSetGameResolvedAndWon(betsSet))
      const checkIsBetsSetResolvedAndWon = isBetsSetGameResolvedAndWon(betsSet)
      if (checkIsBetsSetResolvedAndWon === true) {
        isBetsSetResolvedAndWon.current = checkIsBetsSetResolvedAndWon
      }
    }
  }, [betsSet])*/

  /*useEffect(() => {
    checkIsBetsSetResolvedAndWon()
  }, [])*/

  const betTimestampLocal = utils.gameTimestampToLocal(betsSet.createdAt);

  const comboOdd = () => {
    let comboOdd = 1
    betsSet.bets.map(bet => {
      comboOdd *= (bet.odd/(10**bet.oddDecimals))
      console.log('^Id',bet.betId)
      console.log('^comboOdd',comboOdd)
    })
    return comboOdd
  }

  const isBetsSetGameResolved = (bets) => {
    let allResolved = true
    bets.forEach(bet => {
      if (!bet.game.resolved) {
        allResolved = false;
      }
    })
    return allResolved
  }

  /*const isBetsSetGameResolvedAndWon = (betsSet) => {
    let allWon = true
    betsSet.bets.forEach(bet => {
      if (!bet.game.resolved) {
        allWon = false
      } else {
        if (!bet.market.resolvedOutcomeIds.includes(bet.market.outcomeId)) {
          allWon = false
        }
      }
    })
    console.log('#isBetsSetGameResolvedAndWon betsSet id: ',betsSet.id)
    console.log('#isBetsSetGameResolvedAndWon',allWon)
    return allWon
    //setIsBetsSetResolvedAndWon(allWon)
  }*/

  const BetsSetStatus = ({ betsSetStatus }) => {
    return (
      <div className={`my-bets-bet-status ${betsSetStatus}-bet`}>
        {utils.capitalize(betsSetStatus)}
      </div>
    )
  }

  const BetStatus = ({ bet }) => {
    const status = betsSetStatus === utils.betsSetStatus.SUSPENDED
      ? utils.betsSetStatus.SUSPENDED
      : bet.game.resolved && bet.market.resolvedOutcomeIds.includes(bet.market.outcomeId)
      ? utils.betsSetStatus.WON
      : bet.game.resolved && !bet.market.resolvedOutcomeIds.includes(bet.market.outcomeId)
      ? utils.betsSetStatus.LOST
      : utils.betsSetStatus.PENDING

    const Icon = () => {
      return status === utils.betsSetStatus.SUSPENDED
      ? <FontAwesomeIcon icon={icon({name: 'clock'})} />
      : utils.betsSetStatus.WON
      ? <FontAwesomeIcon icon={icon({name: 'check-circle'})} />
      : status === utils.betsSetStatus.LOST
      ? <FontAwesomeIcon icon={icon({name: 'times-circle'})} />
      : <FontAwesomeIcon icon={icon({name: 'clock'})} />
    }
    
    const betStatus = 
      <span>
        <span className={`text-s-em ${status}-bet`}>
        <Icon />
        </span>
        <span className='my-bets-bet-row-outcome-market'>
          &nbsp;&nbsp;{bet.market.en}
        </span> 
        <span className={`my-bets-bet-row-outcome-outcome ${status}-bet`}>
          &nbsp;<b>{bet.market.value}</b>
        </span>
      </span>

    return (
      <div className='my-bets-bet-row-outcome'>
        {betStatus}
      </div>
    )
  }
  
  const GameTimestampLocal = ({ game }) => {
	  return (utils.gameTimestampToLocal(game.timestamp));
  };

  // const callRedeem = () => {
  //   console.log('callRedeem betsSet.bets',betsSet.bets)
  //   /*const isBetsSetGameResolvedAndWon =
  //     isBetsSetGameResolvedAndWon(betsSet.bets)
  //     ? isBetsSetGameResolvedAndWon(betsSet.bets)
  //     : null*/
  //   //isBetsSetResolvedAndWon()

  //   console.log('callRedeem isBetsSetResolvedAndWon',isBetsSetResolvedAndWon)

  //   if (isBetsSetResolvedAndWon !== undefined) {
  //     return isBetsSetResolvedAndWon
  //   }
  // }

  const handleRedeemResolved = (betsSetId) => {
    console.log('handleRedeemResolved', betsSetId)
    dispatch(slice.setUserBetsSetAsPaid(betsSetId))
  }

/*   const Winnings = memo(({ betsSet, isBetsSetResolvedAndWon, wallet }) => {
    return (
      <div className='my-bets-betsset-winnings'>
        <span>{betsSet.paid || isBetsSetResolvedAndWon ? 'Winnings' : 'Possible winning'}:</span> 
        <span> {betsSet.welcomeBonusApplied ? 
          parseFloat((betsSet.amount/(10**wallet.tokenDecimals)) * comboOdd() * welcomeBonus.bonusMultiplier).toFixed(2) :
          parseFloat((betsSet.amount/(10**wallet.tokenDecimals)) * comboOdd()).toFixed(2)
        } {wallet.tokenSymbol !== null ? wallet.tokenSymbol : ''}</span>
      </div>
    )
  }) */

  const BetsSet = useCallback(() => {
    const betsSetClassName = 'main-div my-bets-grid-card-betset '
    + (betsSetStatus === utils.betsSetStatus.WON && !betsSet.paid ? 'won-bet-card' : '')
    return (
      betsSetStatus !== null && betsSetStatus !== undefined && <div>
      {betsSet.bets.length > 0 && (
        <div key={`main-container-bet-${betsSet.id}`}
        className={betsSetClassName}>
          <div className='my-bets-grid-card-header'>
            <div className='left-menu-text-div'>
              <span>
                {<BetsSetStatus betsSetStatus={betsSetStatus} />}
              </span>
              &nbsp;
              <span>
                · {betsSet.bets.length > 1 ? 'Combo' : 'Single'}
              </span>
            </div>
            <div className='right-menu-text-div'>
              {(betsSet.welcomeBonusApplied ? <span className='text-m fw-500 won-bet'><FontAwesomeIcon icon={icon({name: 'check'})} /> Welcome bonus</span> : '')}
              {(betsSet.bonusAmount > 0 ? <span className='text-m fw-500 won-bet'><FontAwesomeIcon icon={icon({name: 'check'})} /> Bonus</span> : '')}
            </div>
          </div>
          <div className='grid-card-event-line'></div>
          {betsSet.bets.map((bet) => (
            <React.Fragment key={bet.betId}>
              <div className='my-bets-bet-row'>
                <div className='my-bets-bet-first-row'>
                  <div className='my-bets-bet-row-outcome'>
                    {console.log('globalCountries:',globalCountries)}
                    <BetStatus bet={bet} />
                  </div>
                  <div className='my-bets-bet-row-odd'>
                    {parseFloat(bet.odd/(10**bet.oddDecimals)).toFixed(2)}
                  </div>
                </div>
                <div className={`my-bets-bet-row-sport`}>
                  <div className='my-bets-bet-row-participants'>
                    <span className='my-bets-bet-row-participants-sport-icon'>
                      {bet.sportKeyname === 'football' && <Football />}
                      {bet.sportKeyname === 'basketball' && <Basketball />}
                      {bet.sportKeyname === 'tennis' && <Tennis />}
                      {bet.sportKeyname === 'baseball' && <Baseball />}
                      {bet.sportKeyname === 'golf' && <Golf />}
                      {bet.sportKeyname === 'volleyball' && <Volleyball />}
                      {bet.sportKeyname === 'americanfootball' && <AmericanFootball />}
                    </span>
                    <span className='my-bets-bet-row-participants-name'>{bet.home.shortName} {bet.game.goals.home} - {bet.game.goals.away} {bet.away.shortName}</span>
                  </div>
                </div>
              </div>
              <div className='grid-card-event-line'></div>
            </React.Fragment>
          ))}
          <div className='my-bets-grid-card-amounts'>
            <div className='my-bets-betsset-amount'>
              <span>Amount:</span> <span><b>{wallet.tokenDecimals !== null ? parseFloat(betsSet.amount/(10**wallet.tokenDecimals)) : ''} {wallet.tokenSymbol !== null ? wallet.tokenSymbol : ''}</b></span>
            </div>
            <div className='my-bets-betsset-winnings'>
              <span>{
                wallet.tokenDecimals !== null && wallet.tokenSymbol !== null
                && (betsSetStatus === utils.betsSetStatus.PENDING)
                ? 'Possible winning' : 'Winnings'}:</span> 
              <span> <b>{wallet.tokenDecimals !== null && wallet.tokenSymbol !== null && betsSet.welcomeBonusApplied ? 
                parseFloat((betsSet.amount/(10**wallet.tokenDecimals)) * comboOdd() * welcomeBonus.bonusMultiplier).toFixed(2) :
                wallet.tokenDecimals !== null && wallet.tokenSymbol !== null ?
                parseFloat((betsSet.amount/(10**wallet.tokenDecimals)) * comboOdd()).toFixed(2) : ''
              } {wallet.tokenSymbol !== null ? wallet.tokenSymbol : ''}</b></span>
            </div>
          </div>
          <div className='grid-card-event-line'></div>
          <div className='my-bets-betsset-footer'>
            <div className='my-bets-betsset-foorter-info'>
              <span>
                Ref: #{
                  betsSet.id
                } · {
                  betTimestampLocal.day
                } {
                  betTimestampLocal.year
                }, {
                  betTimestampLocal.timeLabel
                }
              </span>
            </div>
              <div className='my-bets-betsset-buttons-redeem'>
                {
                  wallet.network.keyName && <RedeemButton
                    network={wallet.network.keyName}
                    betsSet={betsSet}
                    betsSetStatus={betsSetStatus}
                    onTxRedeemResolved={handleRedeemResolved}
                  />
                }
              </div>
            </div>
        </div>
      )}
      </div>
    )
  }, [betsSetStatus]);

  return(
    <>
    <BetsSet />
    </>
  )
}

export default React.memo(SingleBetGridCard);