import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SingleGameGridCards from '../components/SingleGameGridCards.js';
import BetSlip from '../components/BetSlip.js';
import TeamLogo from '../components/TeamLogo.js'
import * as utils from '../utils/utils.js';
import * as networkConfig from '../config/ethersConfig.js';
//const utils = require('../utils/utils.js');
import * as store from '../utils/store.js';
import { LoaderContext } from '../context/loader.jsx';
import RoundFlag from '../components/RoundFlag.js';

const Game = () => {
    const [windowHeight, setWindowHeight] = useState(0);

    useEffect(() => {
        setWindowHeight(window.innerHeight);
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    const updateDimensions = () => {
        setWindowHeight(window.innerHeight);
    };

    const { network, sportParam, competitionParam, gameIdParam } = useParams()
    const competitionKeyname = utils.replaceCharacter(competitionParam,'-','_');
    const sportKeyname = utils.replaceCharacter(sportParam,'-','_');

    const globalGames = useSelector((state) => state.jsonGames.data);
    const globalCompetitions = useSelector((state) => state.jsonCompetitions.data);
    const globalCountries = useSelector((state) => state.jsonCountries.data);
    const globalTeams = useSelector((state) => state.jsonTeams.data);
    const globalMarkets = useSelector((state) => state.jsonMarkets.data);
    const globalFullGames = useSelector((state) => state.fullGames.data);

    const [game, setGame] = useState({});
    const [gameTimestampLocal, setGameTimestampLocal] = useState({});

    const { isLoading, setIsLoading } = useContext(LoaderContext)
    setIsLoading(false);

    const LoadGameTopCardDetails = () => {
        return (
            Object.keys(game).length !== 0 && game !== undefined &&
            <div id='game-top-card' className={`game-top-card grid-card ${sportParam}-background`}>
                <div className='game-top-card-competition-title btlr-0-5 btrr-0-5'>{globalCountries.find(globalCountry => globalCountry.isoCountry === (globalCompetitions[sportKeyname].find((globalCompetition) => globalCompetition.keyName === competitionKeyname).isoCountry)).shortName} · {(globalCompetitions[sportKeyname].find(_competition => _competition.keyName === competitionKeyname)).fullName}</div>
                <div className='game-top-card-team-details bblr-0-5 bbrr-0-5'>
                    <div className='game-top-card-team bblr-0-5'>
                        <div className='game-top-card-team-logo'>
                            <div className='game-top-card-team-round-logo'></div>
                            {game.homeTeamIsoCountry === '-'
                                ? <RoundFlag country={game.homeTeamLogoKeyname} size={'big'} />
                                : <TeamLogo sportKeyname={sportKeyname} isoCountry={game.homeTeamIsoCountry} teamLogoKeyname={game.homeTeamLogoKeyname} size={'big'} />
                            }
                        </div>
                        <div className='game-top-card-team-name bblr-0-5'>
                            {game.homeTeamFullName}
                        </div>
                    </div>
                    <div className='game-top-card-details'>
                        <div className='game-top-card-details-date'>
                            {gameTimestampLocal.dayLabel}
                        </div>
                        <div className='game-top-card-details-time'>
                            {gameTimestampLocal.timeLabel}
                        </div>
                    </div>
                    <div className='game-top-card-team bbrr-0-5'>
                        <div className='game-top-card-team-logo'>
                            <div className='game-top-card-team-round-logo'></div>
                            {game.awayTeamIsoCountry === '-'
                                ? <RoundFlag country={game.awayTeamLogoKeyname} size={'big'} />
                                : <TeamLogo sportKeyname={sportKeyname} isoCountry={game.awayTeamIsoCountry} teamLogoKeyname={game.awayTeamLogoKeyname} size={'big'} />
                            }
                        </div>
                        <div className='game-top-card-team-name bbrr-0-5'>
                            {game.awayTeamFullName}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    useEffect(() => {
        console.log('globalGames GAME.js: ', globalGames);
        console.log('globalCompetitions GAME.js: ', globalCompetitions);
        console.log('globalTeams GAME.js: ', globalTeams);
        console.log('globalMarkets GAME.js: ', globalMarkets);
        console.log('globalFullGames GAME.js: ', Object.keys(globalFullGames).length);
        console.log('game GAME.js: ', Object.keys(game).length);
        if(Object.keys(globalFullGames).length !== 0 && globalFullGames !== undefined){
            const foundGame = globalFullGames[sportKeyname][competitionKeyname].find(_game => _game.gameId === parseInt(gameIdParam));
            if (foundGame !== undefined) {
                setGame(foundGame)
                setGameTimestampLocal(utils.gameTimestampToLocal(game.timestamp));
                console.log('SETTING GAME VAR GAME.js: ', game);
            } else {
                console.log('GAME NOT FOUND GAME.js: ', gameIdParam);
                utils.goToHome();
            }
        }
    }, [globalFullGames, game]);

    console.log('globalFullGames1 GAME.js: ', globalFullGames);
    console.log('GAME PAGE:',game)

    return (
        <div id='main-parent-container'>
            <div id='main-container' style={{minHeight: windowHeight}}>
                <LoadGameTopCardDetails />
                <SingleGameGridCards key={game.gameId} game={game} sportKeyname={sportKeyname} />
            </div>
            <BetSlip />
        </div>
    );
};

export default Game;