import axios from "axios";
import * as utils from "../utils/utils.js";

// Fetch games json from backend
const fetchGames = async (coreContract, sports) => {
  const games = {};
  const oddsDecimals = 2;

  const minGamesNumberToIncreaseDaysOnwards = 6;
  const daysOnwardsToIncrease = 5;
  const shouldLoadMoreGamesIfThereAreFew = true;
  let isFirstTime = true;
  let daysOnwards = utils.daysOnwards;
  let totalGames = 0;

  try {
    if (sports.length > 0) {
      await Promise.all(
        sports.map(async (sport) => {
          for (let i = 0; i <= daysOnwards; i++) {
            const timestampOnward =
              utils.dateToTimestampAtMidnight(/* "2024-04-22" */) + (3600 * 24 * i);
            //console.log("timestampOnward: ", i, timestampOnward);
            //const result = await coreContract.getGamesByDate(timestampOnward);
            const gamesResult = async () => {
              try {
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVER}/getGamesByDate`, { timestampOnward })
                console.log('fetchGames.js response',response);


                return response.data;
              } catch (error) {
                  console.error(error);
                  return false
              }
            }
            console.log("fetchGames.js gamesResult: ", gamesResult);
            //const gamesIds = result.map((res) => parseInt(res._hex));
            //console.log("gamesIds: ", gamesIds);
            totalGames += gamesResult.length;
            if (totalGames <= minGamesNumberToIncreaseDaysOnwards
              && i === daysOnwards
              && shouldLoadMoreGamesIfThereAreFew
              && isFirstTime) {
              daysOnwards += daysOnwardsToIncrease;
              isFirstTime = false;
            }
            /* await Promise.all(
              gamesIds.map(async (id) => {
                const gameScResult = await coreContract.getGameById(id);
                const gameSportId = parseInt(gameScResult[1]._hex);
                if (gameSportId === sport.id) {
                  console.log("fetchGames.js hash: ", gameSportId, gameScResult.ipfsHash, gameScResult);
                  const gameIpfsData = await utils.getJsonObjectFromHash(
                    gameScResult.ipfsHash
                  );
                  const gameMarketsResult = await coreContract.getGameMarkets(id);
                  const marketsScData = await Promise.all(
                    gameMarketsResult.map(async (GameMarket, index) => {
                      const marketId = parseInt(GameMarket.marketId);
                      console.log("fetchGames.js marketId: ", gameSportId, marketId);
                      const matchedMarket = gameIpfsData["markets"].find(
                        (market) => market.id === marketId
                      );
                      console.log("fetchGames.js matchedMarket: ", gameSportId, id, matchedMarket);
                      const marketValues = matchedMarket !== undefined && matchedMarket.values.map(
                        (valueObject) => valueObject.value
                      );
                      console.log("fetchGames.js marketValues: ", gameSportId, marketValues);
                      return {
                        id: marketId,
                        odds: GameMarket.odds.map((odd, index) =>
                          (parseInt(odd._hex) / 10 ** oddsDecimals).toFixed(2)
                        ),
                        oddsDecimals: oddsDecimals,
                        outcomesIds: GameMarket.outcomesIds.map((outcomeIds) =>
                          parseInt(outcomeIds._hex)
                        ),
                        resolvedOutcome: parseInt(GameMarket.resolvedOutcome),
                        values: marketValues,
                      };
                    })
                  );
                  await Promise.all(marketsScData);

                  if (Object.keys(gameIpfsData).length > 0) {
                    if (!games.hasOwnProperty(sport.keyName)) {
                      games[sport.keyName] = [];

                      //console.log("gamesArray__: ", gamesObject);
                    }

                    if (!games[sport.keyName].find(game => game.id === id)) {
                      gameIpfsData.id = id;
                      gameIpfsData.markets = marketsScData;
                      gameIpfsData.status = gameScResult.status;
                      gameIpfsData.resolved = gameScResult.resolved;
                      games[sport.keyName].push(gameIpfsData);
                    }
                  }
                  console.log("gamesObject:", games);
                }
                console.log('games::', games)
              })
            ); */
          }
        })
      );

      
      return games;
    }

    return {};
  } catch (error) {
    console.error("Error getting games json from IPFS", error);
    return {};
  }
};

/* const _wrappedFullGames = async (
  coreContract,
  sports,
  competitions,
  teams,
  markets
) => {
  const games = await fetchGames(coreContract, sports);
  
  if (
    sports.length > 0 &&
    Object.keys(games).length > 0 &&
    Object.keys(competitions).length > 0 &&
    competitions.constructor === Object &&
    Object.keys(markets).length > 0 &&
    Object.keys(teams).length > 0
  ) {
    console.log("App.js sport");
    console.log("competitions::", competitions);
    console.log("gamesObject::", games);

    const sortedGames = {};
    for (const sport in games) {
      console.log('fetchGames.js sport game:', sport, games[sport])
      sortedGames[sport] = [...games[sport]].sort((a, b) => {
        if (a.timestamp === b.timestamp) {
          return a.gameId - b.gameId;
        }
        return a.timestamp - b.timestamp;
      });
    }

    const sortedCompetitions = {};
    for (const sport in competitions) {
      sortedCompetitions[sport] = [...competitions[sport]].sort((a, b) => {
        if (a.relevance === b.relevance) {
          return a.id - b.id;
        }
        return a.relevance - b.relevance;
      });
    }

    const wrappedGames = {};
    for (const sport in sortedGames) {
      console.log("sortedGames", sortedGames);
      console.log("sortedCompetitions", sortedCompetitions);
      for (const competition of sortedCompetitions[sport]) {
        sortedGames[sport].forEach((game) => {
          if (competition.id === game.competitionId) {
            const homeTeam = teams[sport].find(
              (team) => team.id === game.homeId
            );
            const awayTeam = teams[sport].find(
              (team) => team.id === game.awayId
            );
            console.log('fetchGames.js game:', sport, game);
            const orderedGameMarkets = utils.reorderGameMarkets(
              sport,
              game.markets
            );
            const attachedGameMarkets = utils.attachGameMarkets(
              sport,
              markets,
              orderedGameMarkets
            );

            if (!wrappedGames.hasOwnProperty(sport)) {
              wrappedGames[sport] = {};
            }
            if (!wrappedGames[sport].hasOwnProperty(competition.keyName)) {
              wrappedGames[sport][competition.keyName] = [];
            }

            if (
              wrappedGames[sport][competition.keyName].find(
                //(wrappedGame) => wrappedGame.oracleId === game.oracleId
                (wrappedGame) => wrappedGame.id === game.id
              )
            ) {
            } else {
              wrappedGames[sport][competition.keyName].push({
                gameId: game.id,
                //oracleId: game.oracleId,
                homeTeamId: homeTeam ? homeTeam.id : "",
                awayTeamId: awayTeam ? awayTeam.id : "",
                homeTeamKeyName: homeTeam ? homeTeam.keyName : "",
                awayTeamKeyName: awayTeam ? awayTeam.keyName : "",
                homeTeamShortName: homeTeam ? homeTeam.shortName : "",
                awayTeamShortName: awayTeam ? awayTeam.shortName : "",
                homeTeamFullName: homeTeam ? homeTeam.fullName : "",
                awayTeamFullName: awayTeam ? awayTeam.fullName : "",
                homeTeamLogoHash:
                  homeTeam && homeTeam.logoHash !== ""
                    ? homeTeam.logoHash
                    : utils.genericTeamLogoHash,
                awayTeamLogoHash:
                  awayTeam && awayTeam.logoHash !== ""
                    ? awayTeam.logoHash
                    : utils.genericTeamLogoHash,
                timestamp: game.timestamp,
                goals: game.goals,
                score: game.score,
                status: game.status,
                resolved: game.resolved,
                markets: attachedGameMarkets,
              });
            }
          }
        });
      }
    }

    console.log("wrappedGames:", wrappedGames);
    return wrappedGames;
  } else {
    return {};
  }
}; */

const wrappedFullGames = async () => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVER}/getGamesByDate`,)
    console.log('fetchGames.js response',response);

    if (response.data.data) {
      return response.data.data;
      //return {}
    } else {
      return {}
    }
  } catch (error) {
      console.error(error);
      return {}
  }
}

export default wrappedFullGames;
