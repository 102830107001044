import { configureStore, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
//import { fetchJsonFromIpfs } from './utils';
import * as slice from './slice';

const rootReducer = combineReducers({
    version: slice.versionSlice.reducer,
    lastConnection: slice.lastConnectionSlice.reducer,
    referral: slice.referralSlice.reducer,
    sports: slice.sportsSlice.reducer,
    jsonGames: slice.gamesSlice.reducer,
    jsonCompetitions: slice.competitionsSlice.reducer,
    jsonCountries: slice.countriesSlice.reducer,
    jsonTeams: slice.teamsSlice.reducer,
    jsonMarkets: slice.marketsSlice.reducer,
    fullGames: slice.fullGamesSlice.reducer,
    betslip: slice.betslipSlice.reducer,
    userBetsSets: slice.userBetsSetsSlice.reducer,
    wallet: slice.walletSlice.reducer,
    welcomeBonus: slice.welcomeBonusSlice.reducer,
    bonus: slice.bonusSlice.reducer,
});

const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({ //TODO: comentar middleware para corregir error que aparece
    serializableCheck: false
  })
});

const persistor = persistStore(store);

export { store, persistor };