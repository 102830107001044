import * as utils from '../utils/utils.js';

// Fetch user bets set from smart contract
const fetchBetsSet = async (coreContract, globalObject, betsSetId) => {
  const globalSports = globalObject.sports;
  const globalCompetitions = globalObject.competitions;
  const globalTeams = globalObject.teams;
  const globalMarkets = globalObject.markets;

  try {
    const userBetsSetMappingUnparsedResult =
      await coreContract.getBetsMapping(betsSetId);
    const userBetsIds = userBetsSetMappingUnparsedResult.map((betIdRes) => {
        return parseInt(betIdRes._hex);
    });

    const betsSetResult = await coreContract.getBetsSetById(betsSetId);
    
    const betsSet = {
      id: betsSetId,
      amount: parseInt(betsSetResult.amount._hex),
      paid: betsSetResult.paid,
      createdAt: parseInt(betsSetResult.createdAt._hex),
      welcomeBonusApplied: betsSetResult.welcomeBonusApplied,
      bonusAmount: parseInt(betsSetResult.bettorBonusAmount._hex),
      accepted: true,
      bets: [],
    };

    for (const betId of userBetsIds) {
      const betResult = await coreContract.getBetsById(betId);
      const betSportId = parseInt(betResult.sportId._hex);
      const betSport = globalSports.find((sport) => sport.id === betSportId);
      const betGameId = parseInt(betResult.gameId._hex);
      const gameResult = await coreContract.getGameById(betGameId);
      const jsonGame = await utils.getJsonObjectFromHash(gameResult.ipfsHash);

      const betGame = {
        id: betGameId,
        //oracleId: parseInt(gameResult.oracleId._hex),
        competitionId: jsonGame.competitionId,
        homeId: jsonGame.homeId,
        awayId: jsonGame.awayId,
        timestamp: jsonGame.timestamp,
        goals: jsonGame.goals,
        score: jsonGame.score,
        status: jsonGame.status,
        resolved: gameResult.resolved,
        markets: jsonGame.markets,
      };

      const marketsGame = await coreContract.getGameMarkets(betResult.gameId);
      const betOutcomeWinId = parseInt(betResult.outcomeWinId._hex);
      const betMarketId = parseInt(betResult.marketId._hex);

      const betMarket = {
        ...globalMarkets[betSport.keyName].find(
          (market) => market.id === betMarketId
        ),
      };
      betMarket.value = betGame.markets.find(
        (market) => market.id === betMarketId
      ).values[betOutcomeWinId].value;
      betMarket.odd = parseInt(
        marketsGame.find(
          (market) => parseInt(market.marketId._hex) === betMarketId
        ).odds[betOutcomeWinId]._hex
      );
      betMarket.oddsDecimals = 2
      betMarket.outcomeId = parseInt(
        marketsGame.find(
          (market) => parseInt(market.marketId._hex) === betMarketId
        ).outcomesIds[betOutcomeWinId]._hex
      );
      betMarket.resolvedOutcomeIds = marketsGame
        .find((market) => parseInt(market.marketId._hex) === betMarketId)
        .resolvedOutcome.map((id) => parseInt(id._hex));
      const betCompetition = globalCompetitions[betSport.keyName].find(
        (competition) => competition.id === betGame.competitionId
      );
      const betHomeTeam = globalTeams[betSport.keyName].find(
        (team) => team.id === betGame.homeId
      );
      const betAwayTeam = globalTeams[betSport.keyName].find(
        (team) => team.id === betGame.awayId
      );

      const bet = {
        betId,
        sportId: betSport.id,
        sportKeyname: betSport.keyName,
        game: betGame,
        home: betHomeTeam,
        away: betAwayTeam,
        competition: betCompetition,
        amount: parseInt(betResult.amount._hex),
        market: betMarket,
        odd: parseInt(betResult.odd._hex),
        oddDecimals: 2,//parseInt(betResult.oddDecimals._hex),
        outcomeWinId: betOutcomeWinId,
      };

      console.log("MyBets.js - betsSet: ", bet);
      betsSet.bets.push(bet);
    }

    console.log("fetchBetsSet.js betsSet", betsSet);
    return betsSet;
  } catch (error) {
    console.error("Error getting bets set from smart contract", error);
    return [];
  }
};

export default fetchBetsSet;
