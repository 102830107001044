import axios from 'axios';

export const updateWBInfo = async (props) => {
    const WBInfo = props
    console.log('updateWBInfo.js ',WBInfo);
    if (WBInfo) {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_SERVER}/uwb`, { WBInfo: WBInfo })
            console.log('updateWBInfo.js response',response);
            return response.data;
        } catch (error) {
            console.error(error);
            return false
        }
    } else {
        console.error('There is no WB info to send to the server.');
        return false;
    }
}