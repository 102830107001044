const fetchTokenWalletData = async ({
  tokenContract,
  liquidityAddress,
  walletAddress,
  dataToFetch,
}) => {
  try {
    const _wallet = {};
    const data =
      dataToFetch[0] === "all"
        ? ["balance", "allowance", "tokenDecimals", "tokenSymbol"]
        : dataToFetch;
    const promises = [];

    if (data.includes("balance")) {
      promises.push(
        tokenContract.balanceOf(walletAddress).then((result) => {
          _wallet["balance"] = parseInt(result._hex);
          console.log("fetchTokenWalletData balance", _wallet["balance"])
        })
      );
    }

    if (data.includes("allowance")) {
      promises.push(
        tokenContract
          .allowance(walletAddress, liquidityAddress)
          .then((result) => {
            _wallet["allowance"] = parseInt(result._hex);
          })
      );
    }

    if (data.includes("tokenDecimals")
      || data.includes("balance")
      || data.includes("allowance")) {
      promises.push(
        tokenContract.decimals().then((result) => {
          _wallet["tokenDecimals"] = result;
        })
      );
    }

    if (data.includes("tokenSymbol")) {
      promises.push(
        tokenContract.symbol().then((result) => {
          _wallet["tokenSymbol"] = result;
        })
      );
    }
    await Promise.all(promises);

    console.log("fetchTokenWalletData _wallet", _wallet);

    const wallet = {
      ..._wallet
    }

    if (data.includes("balance")) {
      wallet["balance"] = _wallet.tokenDecimals !== null
        && _wallet.tokenDecimals !== undefined
        && _wallet.balance !== null
        && _wallet.balance !== undefined ?
        (_wallet.balance / (10**_wallet.tokenDecimals)) : null;
    }
    if (data.includes("allowance")) {
      wallet["allowance"] = _wallet.tokenDecimals !== null
      && _wallet.tokenDecimals !== undefined
      && _wallet.allowance !== null
      && _wallet.allowance !== undefined ?
      (_wallet.allowance / (10**_wallet.tokenDecimals)) : null;
    }
    console.log("fetchTokenWalletData _wallet final", _wallet);
    console.log("fetchTokenWalletData wallet final", wallet);

    return wallet;
  } catch (error) {
    console.error("Error getting games json from IPFS", error);
    return {};
  }
};

export default fetchTokenWalletData;
