import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as slice from '../../utils/slice.js';

const useGetURLParam = (type) => {
  //const dispatch = useDispatch()
  const [ param, setParam ] = useState(undefined);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const param = urlParams.get(type);
    console.log('useGetURLParam',param)
    if (param !== undefined && param !== null) {
      setParam((param))
    }
  }, []);

  return param
}

export default useGetURLParam;