import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ethers } from 'ethers';
import Icon from './Icon.js';
import { Toggle } from './Toggle.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import MyBetSingleCard from '../components/MyBetSingleCard.js';
import * as utils from '../utils/utils.js';
import * as slice from '../utils/slice.js';
import * as ethersConfig from '../config/ethersConfig.js';
import { ConnectWallet } from "@thirdweb-dev/react";
//import * as updateBetslipProps from './utils/UpdateBetslipProps.js';
//import TokenAllowance from './utils/TokenAllowance.js';
import GetTokenData from './GetTokenData.js'
import ApproveButton from './utils/ApproveButton.js';
import PlaceBetButton from './utils/PlaceBetButton.js';
import { LoaderContext } from '../context/loader.jsx';
import { updateWBInfo } from '../services/updateWBInfo.js';
import fetchTokenWalletData from '../services/fetchTokenWalletData.js';
import fetchWelcomeBonusData from '../services/fetchWelcomeBonusData.js';
import fetchBonusData from '../services/fetchBonusData.js';

const Betslip = () => {
  const [windowHeight, setWindowHeight] = useState(0);
  useEffect(() => {
    setWindowHeight(window.innerHeight);
    window.addEventListener('resize', updateDimensions);
    return () => {
        window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  const updateDimensions = () => {
      setWindowHeight(window.innerHeight);
  };

  const { isLoading, setIsLoading } = useContext(LoaderContext)

  const dispatch = useDispatch();
  const { data: wallet } = useSelector(state => state.wallet);
  const { data: welcomeBonus } = useSelector(state => state.welcomeBonus);
  const { data: bonus } = useSelector(state => state.bonus);
  const { loading: loadingBetslip, error: errorBetslip, data: betslip } = useSelector(state => state.betslip);
  const { loading: loadingUserBetsSets, error: errorUserBetsSets, data: userBetsSets } = useSelector(state => state.userBetsSets);
  const globalSports = useSelector((state) => state.sports.data);
  const globalGames = useSelector((state) => state.jsonGames.data);
  const globalCompetitions = useSelector((state) => state.jsonCompetitions.data);
  const globalTeams = useSelector((state) => state.jsonTeams.data);
  const globalMarkets = useSelector((state) => state.jsonMarkets.data);
  const globalFullGames = useSelector((state) => state.fullGames.data);
  
  const network = wallet.network.keyName
    ? wallet.network.keyName
    : utils.defaultNetwork.keyName
  const web3Config = ethersConfig.web3Config(network)
  //const connectUsingMetamask = useMetamask();
  //const address = useAddress();
  /*const { contract } = useContract(
    "0xc852D36e2700553a94f2Ebc7Fc41477CB3aDB3e3",
    web3Config.tokenAbi
  );
  const { mutateAsync, isLoading, error } = useContractWrite(
    contract,
    "approve",
  );*/

  useEffect(() => {
      console.log('userBetsSets',userBetsSets);
      console.log('betslip =>',betslip);
  }, [betslip]);

  const [betslipToggleOn, setBetslipToggleOn] = useState('betslip');
  const [isBetslipVisible, setIsBetslipVisible] = useState(false);
  //const [allowance, setAllowance] = useState(0);
  const [resetAmount, setResetAmount] = useState(parseFloat(5).toFixed(2));
  const [allUserBetsSetsResolved, setAllUserBetsSetsResolved] = useState(true)
  //const [bets, setBets] = useState(initialBets);
  const [loadingRecentlyPlacedBetsSets, setLoadingRecentlyPlacedBetsSets] = useState(false)
  
  const tokenContract = web3Config.tokenContract
  const coreContract = web3Config.coreContract
  const liquidityContract = web3Config.liquidityContract
  //const tokenContractWithSigner = web3Config.tokenContract.connect(web3Config.signer)
  //const coreContractWithSigner = web3Config.coreContract.connect(web3Config.signer)

  /*const { contract } = useContract(web3Config.tokenAddress);
  const { data, isLoading, error } = useContractRead(
    contract,
    "allowance",
    ["0x51885Af62E7EEF82925Cb24b7D8d86Ed5b352c1f", "0x57355A42970C0c4Bc177591D32dC749D0AF5939F"],
  );
  console.log('_allowance data1',data)*/

  //console.log('MyBetSingleCard.js: ',betsSet)

  const handleConnectWallet = async () => {
    ethersConfig.handleConnectWallet(dispatch, wallet)
  }

  useEffect(() => {
    (async () => {
      //console.log('__wallet',wallet)
      //if (!wallet.isConnected) {
        //ethersConfig.handleConnectWallet(dispatch, wallet)
      //}
      //await updateAllowance()
      await updateWallet(['allowance'])
      
    })()
  }, [/*wallet*//*, tokenContract*/]);

  useEffect(() => {
    (async () => {
      await updateWelcomeBonus()
    })()
  }, []);
  
  useEffect(() => {
    (async () => {
      await updateBonus()
    })()
  }, []);

  useEffect(() => {
    checkAllUserBetsSetsResolved()
  }, [userBetsSets])

  const [isBetslipOpenCloseButtonHidden, setIsBetslipOpenCloseButtonHidden] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || window.pageYOffset;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const distanceToBottom = documentHeight - (scrollY + windowHeight);

      if (distanceToBottom < 130) {
        setIsBetslipOpenCloseButtonHidden(true);
      } else {
        setIsBetslipOpenCloseButtonHidden(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  /* const updateAllowance = async () => {
    //OLD WAY
    if (wallet.address) {
      const _returnAllowance = await tokenContract.allowance(wallet.address, liquidityContract.address)
      const _allowance = parseFloat(parseInt(_returnAllowance._hex)/10**6)
      //setAllowance(_allowance)
      console.log('_allowance',_allowance)
      const _wallet = {
        ...wallet,
        allowance: _allowance
      }
      console.log('Betslip allowance',_wallet)
      dispatch(slice.updateWallet(_wallet))
    }

  } */

  const getUpdatedWallet = async (dataToFetch = ['all']) => { //TODO: externalizar a utils
    console.log('__updateWallet1')
    try {
        //const tokenWalletData = async () => {
            console.log('__updateWallet2')
            //if (wallet.network.keyName) {
                const tokenWalletData = await fetchTokenWalletData({
                    tokenContract: web3Config.tokenContract,
                    liquidityAddress: liquidityContract.address,
                    walletAddress: wallet.address,
                    dataToFetch: dataToFetch
                })
                console.log('fetchTokenWalletData tokenWalletData',tokenWalletData)
                //return _tokenWalletData;

                return {
                    ...wallet,
                    //..._tokenWalletData,
                    network: {
                        keyName: wallet.network.keyName,
                        name: wallet.network.name,
                        networkId: web3Config.network.networkId, //TODO: El id de la red deberia obtenerse de la red conectada desde la libreria de ThirdWeb
                        TwRpc: wallet.network.rpc
                    },
                    address: wallet.address,
                    isConnected: true,
                    balance: tokenWalletData.balance !== null && tokenWalletData.balance !== undefined ? tokenWalletData.balance : wallet.balance ? wallet.balance : null,
                    tokenDecimals: tokenWalletData.tokenDecimals ? tokenWalletData.tokenDecimals : wallet.tokenDecimals ? wallet.tokenDecimals : null,
                    tokenSymbol: tokenWalletData.tokenSymbol ? tokenWalletData.tokenSymbol : wallet.tokenSymbol ? wallet.tokenSymbol : null,
                    allowance: tokenWalletData.allowance !== null && tokenWalletData.allowance !== undefined ? tokenWalletData.allowance : wallet.allowance ? wallet.allowance : null,
                }
            //}
        //}

        //await tokenWalletData()
        /* return {
            ...wallet,
            network: {
                keyName: walletChain.slug,
                name: walletChain.name,
                networkId: walletChain.networkId,
                TwRpc: walletChain.rpc
            },
            address: walletAddress,
            isConnected: true,
            balance: tokenWalletData ?? tokenWalletData.balance,
            tokenDecimals: tokenWalletData ?? tokenWalletData.tokenDecimals,
            tokenSymbol: tokenWalletData ?? tokenWalletData.tokenSymbol,
            allowance: tokenWalletData ?? tokenWalletData.allowance
        } */
    } catch (error) {
        console.error("Error fetching token wallet data", error);
        return null;
    }
  }

  const updateWallet = async (dataToFetch = ['all']) => {
    if (wallet.address) {
      console.log('betslip.js updateWallet')
      const updatedWallet = await getUpdatedWallet(dataToFetch)
      dispatch(slice.updateWallet(updatedWallet))
    }
  }

  const getUpdatedWelcomeBonus = async (dataToFetch = ['all']) => { //TODO: externalizar a utils
    console.log('__updateWB1')
    try {
      console.log('__updateWB2')
      const welcomeBonusData = await fetchWelcomeBonusData({
          welcomeBonusContract: web3Config.welcomeBonusContract,
          walletAddress: wallet.address,
          dataToFetch: dataToFetch
      })
      console.log('fetchWelcomeBonusData ',welcomeBonusData)
      //return _tokenWalletData;

      return {
        ...welcomeBonus,                    
        isEnabled: welcomeBonusData.isEnabled !== null && welcomeBonusData.isEnabled !== undefined ? welcomeBonusData.isEnabled : welcomeBonus.isEnabled ? welcomeBonus.isEnabled : false,
        isEnjoyed: welcomeBonusData.isEnjoyed !== null && welcomeBonusData.isEnjoyed !== undefined ? welcomeBonusData.isEnjoyed : welcomeBonus.isEnjoyed ? welcomeBonus.isEnjoyed : false,
        isUserApplicableForBonus: welcomeBonusData.isUserApplicableForBonus !== null && welcomeBonusData.isUserApplicableForBonus !== undefined ? welcomeBonusData.isUserApplicableForBonus : welcomeBonus.isUserApplicableForBonus ? welcomeBonus.isUserApplicableForBonus : false,
        bonusMultiplier: welcomeBonusData.bonusMultiplier !== null && welcomeBonusData.bonusMultiplier !== undefined ? welcomeBonusData.bonusMultiplier : welcomeBonus.bonusMultiplier ? welcomeBonus.bonusMultiplier : 0,
      }
    } catch (error) {
        console.error("Error fetching token wallet data", error);
        return null;
    }
  }

  const updateWelcomeBonus = async (dataToFetch = ['all']) => {
    if (wallet.address) {
      console.log('betslip.js updateWelcomeBonus')
      const updatedWelcomeBonus = await getUpdatedWelcomeBonus(dataToFetch)
      dispatch(slice.updateWelcomeBonus(updatedWelcomeBonus))
    }
  }

  const getUpdatedBonus = async (dataToFetch = ['all']) => { //TODO: externalizar a utils
    console.log('__updateBonus1')
    try {
      console.log('__updateBonus2')
      const bonusData = await fetchBonusData({
          bonusContract: web3Config.bonusContract,
          walletAddress: wallet.address,
          dataToFetch: dataToFetch
      })
      console.log('fetchBonusData ',bonusData)
      //return _tokenWalletData;

      return {
        ...bonus,                    
        isEnabled: bonusData.isEnabled !== null && bonusData.isEnabled !== undefined ? bonusData.isEnabled : bonus.isEnabled ? bonus.isEnabled : false,
        isUserApplicableForBonus: bonusData.isUserApplicableForBonus !== null && bonusData.isUserApplicableForBonus !== undefined ? bonusData.isUserApplicableForBonus : bonus.isUserApplicableForBonus ? bonus.isUserApplicableForBonus : false,
        bonusAmount: bonusData.bonusAmount !== null && bonusData.bonusAmount !== undefined ? bonusData.bonusAmount : bonus.bonusAmount ? bonus.bonusAmount : 0,
      }
    } catch (error) {
        console.error("Error fetching token wallet data", error);
        return null;
    }
  }

  const updateBonus = async (dataToFetch = ['all']) => {
    if (wallet.address) {
      console.log('betslip.js updateBonus')
      const updatedBonus = await getUpdatedBonus(dataToFetch)
      dispatch(slice.updateBonus(updatedBonus))
    }
  }

  const handleBetslipTopButtons = (button) => {
    if (betslipToggleOn !== button) {
      setBetslipToggleOn(button);
    }
  }

  const toggleBetslipVisible = () => {
    setIsBetslipVisible(prevState => !prevState);
  }

  /*useEffect(() => {
    console.log('isBetslipVisible: '+isBetslipVisible);
  }, [isBetslipVisible]);*/

  const onAmountChange = (value, gameId, marketId, outcomeId, odd) => {
    //const regExp = new RegExp(/^\d*\.?\d*$/);
    
    const amount = value
    //let totalAmount = 0
    console.log('onChange',gameId+' '+amount+' '+odd)
    const bets = betslip.bets.map(bet => {
      if (bet.gameId === gameId && bet.marketId === marketId && bet.outcomeId === outcomeId) {
        return {
          ...bet,
          amount: parseFloat(amount).toFixed(2),
          possibleWinnings: parseFloat(amount * odd).toFixed(2)
        };
      } else {
        return bet;
      }
    })
    //element.value = parseFloat(element.value).toFixed(2);
    console.log('onAmountChange - bets:',bets)
    //const _bets = dispatch(slice.updateBetsFromBetslip(bets, () => bets)).payload;
    const updatedBetslip = {
      ...betslip,
      bets: bets
    }
    utils.updateBetslipProps(dispatch, updatedBetslip, wallet, bonus)
    /*_bets.forEach(bet => {
      totalAmount += eval(bet.amount)
    })
    const updatedBetslip = {
      ...betslip,
      //bets: bets,
      totalAmount: parseFloat(totalAmount).toFixed(2),
      totalComboAmount: parseFloat(element.value).toFixed(2)
    }
    console.log('updatedBetslip0',updatedBetslip);
    dispatch(slice.updateBetslip(updatedBetslip));*/
  }

  const onComboAmountChange = (value) => {
    /*setBetslip(previousState => {
      return {
        ...previousState,
        comboAmount: comboAmount
      }
    })*/
    const updatedBetslip = {
      ...betslip,
      totalComboAmount: parseFloat(value).toFixed(2),
      totalComboAmountWithoutBonus: parseFloat(value).toFixed(2)
    }
    console.log('updatedBetslip2',updatedBetslip);
    //dispatch(slice.updateBetslip(updatedBetslip)); //Replace for next line
    utils.updateBetslipProps(dispatch, updatedBetslip, wallet, bonus)
    handleTotalComboAmount(value)
  }

  /*const updateBetslipProps = () => {
    let totalAmount = 0;
    let totalPossibleWinnings = 0;
    let totalComboOdds = 0;
    let totalComboPossibleWinnings = 0;

    betslip.bets.forEach(bet => {
      totalAmount += eval(bet.amount)
      totalPossibleWinnings += bet.possibleWinnings
      totalComboOdds === 0 ? totalComboOdds = bet.odd : totalComboOdds *= bet.odd
      totalComboPossibleWinnings = betslip.totalComboAmount * totalComboOdds
    });

    const updatedBetslip = {
      ...betslip,
      totalAmount: parseFloat(totalAmount).toFixed(2),
      totalPossibleWinnings: parseFloat(totalPossibleWinnings).toFixed(2),
      totalComboOdds: parseFloat(totalComboOdds).toFixed(2),
      totalComboPossibleWinnings: parseFloat(totalComboPossibleWinnings).toFixed(2)
    }
    console.log('updatedBetslip1',updatedBetslip);
    dispatch(slice.updateBetslip(updatedBetslip));
  }*/

  const switchCombo = () => {
    /*setBetslip(previousState => {
      return {
        ...previousState,
        combo: !previousState.combo
      }
    })*/
    //TODO: Aplicar lo anterior con un dispatch
    const isCombo = !betslip.combo
    const updatedBetslip = { ...betslip, combo: isCombo }
    //dispatch(slice.updateBetslip(updatedBetslip)); //Replace for next line
    utils.updateBetslipProps(dispatch, updatedBetslip, wallet, bonus)
  }
  
  const switchBonus = () => {
    const isBonus = !betslip.isBonusApplied
    const updatedBetslip = { ...betslip, isBonusApplied: isBonus }
    //dispatch(slice.updateBetslip(updatedBetslip)); //Replace for next line
    utils.updateBetslipProps(dispatch, updatedBetslip, wallet, bonus)
  }

  const removeBet = (gameId, marketId, outcomeId) => {
    /*setBets(oldValues => {
      return oldValues.filter(bet => bet.gameId !== id)
    })*/
    const betToRemove = {
      gameId: gameId,
      marketId: marketId,
      outcomeId: outcomeId
    }
    console.log('Calling to remove with gameId:',betToRemove);
    dispatch(slice.removeBetFromBetslip(betToRemove, () => betslip));
  }

  const handleTotalComboAmount = (event) => {
    setResetAmount(event.value)
  }

  const ComboAmountDiv = ({ isCombo }) => {
    if (!isCombo) {
      return 'GT: ' + parseFloat(betslip.totalPossibleWinnings).toFixed(2);
    }
    return 'GTC: ' + parseFloat(betslip.totalComboPossibleWinnings).toFixed(2);
  }

  /*const handleAddBet = (event) => {
    event.preventDefault();
    const gameId = event.target.elements.gameId.value;
    const homeKeyname = event.target.elements.homeKeyname.value;
    const awayKeyname = event.target.elements.awayKeyname.value;
    const sportType = event.target.elements.sportType.value;
    const marketId = event.target.elements.marketId.value;
    const marketName = event.target.elements.marketName.value;
    const odd = event.target.elements.odd.value;
    const oddDecimals = event.target.elements.oddDecimals.value;
    const outcomeWin = event.target.elements.outcomeWin.value;
    const outcomeWinDesc = event.target.elements.outcomeWinDesc.value;
    const startsAt = event.target.elements.outcomeWinDesc.value;
    const newBet = { gameId, homeKeyname, awayKeyname, sportType, marketId, marketName, odd, oddDecimals, outcomeWin, outcomeWinDesc, startsAt };
    setBets([...bets, newBet]);
  };*/

  //For functional components, not class based components => https://stackoverflow.com/questions/65019527/calling-function-after-setstate-react-hooks

  useEffect(() => {
    console.log('useEffect betslip', betslip)
    //utils.updateBetslipProps(dispatch, betslip, wallet, bonus)
    handleBetslipTopButtons('betslip')
  }, [betslip]); //TODO: Comentado por haber desarrollado betslip global

  //[[0,1,1,10000000,200,2,0]],false,10000000
  //putBets(Bet[] calldata _newBets, bool _isCombo, uint256 _paidAmount)
  /*struct Bet {
		uint256 sportId;
		uint256 gameId;
		uint256 marketId;
		uint256 amount;
		uint64 odd;
		uint64 oddDecimals;
		uint64 outcomeWinId;
	}*/

  const createBetsSets = (betslip) => {
    const newBetsSets = []
    let newBets = []
    betslip.bets.forEach((bet, index) => {
      const betSport = globalSports.find(sport => sport.id === bet.sportId)
      console.log('betSport ',betSport)
      console.log('globalFullGames 1',globalFullGames[betSport.keyName])
      
      let competition = null;
      Object.keys(globalFullGames[betSport.keyName]).forEach((key) => {
        //console.log('competition_0a ', globalFullGames[betSport.keyName], key)
        //console.log('competition_0b ', globalFullGames[betSport.keyName][key].gameId, bet.gameId)
        globalFullGames[betSport.keyName][key].forEach((game) => {
          if (game.gameId === bet.gameId) {
            //console.log('competition_1 ', key)
            //globalCompetitions[betSport.keyName].find((comp) => console.log('competition_2 ',comp))
            competition = globalCompetitions[betSport.keyName].find(
              (comp) => comp.keyName === key
            );
          }
        });
      });

      console.log('competition_ ',competition)
      const betGame = globalFullGames[betSport.keyName][competition.keyName].find(game => game.gameId === bet.gameId)

      console.log('bet',bet)
      console.log('betGame',betGame)
      console.log('globalFullGames 2',globalFullGames[betSport.keyName])
      console.log('bet.marketId',bet.marketId)
      console.log('bet.outcomeId',bet.outcomeId)
      const market = betGame.markets.find(market => market.id === bet.marketId)
      console.log('market',market)

      const newBet = {
        betId: null,
        amount: betslip.combo && !isNaN(bet.amount) ? 0 : parseInt(ethers.utils.parseUnits(bet.amount.replace('.',''), wallet.tokenDecimals-2)), //TODO: decimals
        away: {
          id: betGame.awayTeamId,
          fullName: betGame.awayTeamFullName,
          keyName: betGame.awayTeamKeyName,
          logoHash: betGame.awayTeamLogoHash,
          shortName: betGame.awayTeamShortName,
        },
        home: {
          id: betGame.homeTeamId,
          fullName: betGame.homeTeamFullName,
          keyName: betGame.homeTeamKeyName,
          logoHash: betGame.homeTeamLogoHash,
          shortName: betGame.homeTeamShortName,
        },
        competition: competition,
        game: {
          id: betGame.gameId,
          awayId: betGame.awayTeamId,
          competitionId: competition.id,
          goals: betGame.goals,
          homeId: betGame.homeTeamId,
          markets: betGame.markets,
          //oracleId: betGame.oracleId,
          resolved: betGame.resolved,
          score: betGame.score,
          timestamp: betGame.timestamp
        },
        market: {
          en: market.description.en,
          id: market.id,
          odd: market.odds[bet.outcomeId],
          oddsDecimals: 2,//market.oddsDecimals[bet.outcomeId],
          outcomeId: market.outcomesIds[bet.outcomeId],
          resolvedOutcomeId: market.resolvedOutcome,
          value: market.values[bet.outcomeId]
        },
        odd: parseInt(market.odds[market.resolvedOutcome].replace('.','')), //TODO: decimals
        oddDecimals: 2,//market.oddsDecimals[bet.outcomeId],
        outcomeWinId: market.outcomesIds[bet.outcomeId],
        sportId: betSport.id,
        sportKeyname: betSport.keyName
      }

      const newBetsSetToPush = {
        id: null,
        amount: null,
        paid: false,
        createdAt: Math.floor(Date.now()/1000),
        accepted: false,
        bets: null
      }

      if (betslip.combo) {
        newBets.push(newBet)
        if (index === (betslip.bets.length - 1)) {
          newBetsSetToPush.amount = parseInt(ethers.utils.parseUnits(betslip.totalComboAmount.replace('.',''), wallet.tokenDecimals-2)) //TODO: decimals
          newBetsSetToPush.bets = newBets
          newBetsSets.push(newBetsSetToPush)
          newBets = []
        }
      } else {
        newBets.push(newBet)
        newBetsSetToPush.amount = parseInt(ethers.utils.parseUnits(bet.amount.replace('.',''), wallet.tokenDecimals-2)) //TODO: decimals
        newBetsSetToPush.bets = newBets
        newBetsSets.push(newBetsSetToPush)
        newBets = []
      }
    })
    console.log('newBetsSets: ',newBetsSets)
    return newBetsSets
  };

  /*const signPlaceBetTx = async (betsWrapper, _betslip, parsedPaidAmount) => {
    const web3Config = ethersConfig.web3Config
    const provider = await new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner()
    const coreContractWithSigner = await web3Config.coreContract.connect(signer)
    console.log('betsWrapper:', betsWrapper)
    console.log('allowance:', allowance)
    console.log('_betslip:', _betslip)
    console.log('parsedPaidAmount:', parseFloat(parseInt(parsedPaidAmount._hex)/10**6))
    const tx = await coreContractWithSigner.putBets(betsWrapper, _betslip.combo, parsedPaidAmount)
    
    
    //const createBetsSets = createBetsSets(betslip)
    createBetsSets(_betslip).forEach(createdBetSet => {
      console.log('createdBetSet',createdBetSet)
      dispatch(slice.addUserBetsSet(createdBetSet)) //TODO: al hacer la apuesta, añadir este betsSet al userBetsSet para que se muestre en el panel My Bets/página My Bets
    })
    //switchCombo(false)
    const updatedBetslip = {
      ...betslip,
      combo: false
    }
    dispatch(slice.clearBetslip(updatedBetslip, () => _betslip))
    setResetAmount(parseFloat(5).toFixed(2))
    //dispatch(slice.updateBetslip(updatedBetslip))
    await tx.wait();
    console.log('Resultado de tx:', tx.hash)
    if (tx.hash !== null) {
      await updateAllowance()
    }
  }*/

  //OLD WAY
  /*const placeBet = async () => {
    try {
      const betsWrapper = betslip.bets.map(bet => {
        return [
          bet.sportId,
          bet.gameId,
          bet.marketId,
          ethers.utils.parseUnits(bet.amount.replace('.',''), 6-2), //TODO: decimals
          ethers.utils.parseUnits(bet.odd.replace('.',''), 6-2),
          //5000000,
          //1000000,
          bet.oddDecimals,
          bet.outcomeId
        ]
      })
      console.log('Resultado de betslip:', betslip)
      console.log('Resultado de betsWrapper:', betsWrapper)
      console.log('Resultado de betsWrapper [3]:', parseInt(betsWrapper[0][3]))
      console.log('Resultado de betsWrapper [4]:', parseInt(betsWrapper[0][4]))
      const paidAmount = betslip.combo ?
        betslip.totalComboAmount :
        betslip.totalAmount
      //const betsSetWrapper = [betsWrapper, betslip.combo, paidAmount]
      const parsedPaidAmount = ethers.utils.parseUnits(paidAmount.replace('.',''), 6-2)
      if (!wallet.isConnected) {
        await ethersConfig.handleConnectWallet(dispatch, wallet)
      }

      
      await signPlaceBetTx(betsWrapper, betslip, parsedPaidAmount)

    } catch (error) {
      console.error('Bet couldn\'t be placed:', error)
    }
  }*/

  const getBetsSet = async (userBetsSetsIds, betsSetId) => {
    const betsSetResult = await coreContract.getBetsSetById(betsSetId);
    //console.log('___mybets result betsSetResult',betsSetResult)
    const betsSet = {
      id: betsSetId,
      amount: parseInt(betsSetResult.amount._hex),
      paid: betsSetResult.paid,
      createdAt: parseInt(betsSetResult.createdAt._hex),
      welcomeBonusApplied: betsSetResult.welcomeBonusApplied,
      bonusAmount: betsSetResult.bonusAmount,
      accepted: true,
      bets: [],
    };

    for (const betId of userBetsSetsIds[betsSetId]) {
      const betResult = await coreContract.getBetsById(betId);
      const betSportId = parseInt(betResult.sportId._hex);
      const betSport = globalSports.find((sport) => sport.id === betSportId);
      const betGameId = parseInt(betResult.gameId._hex);
      const gameResult = await coreContract.getGameById(betGameId);
      //const betGame = globalGames[betSport.keyName].find(game => game.id === betGameId)
      const jsonGame = await utils.getJsonObjectFromHash(gameResult.ipfsHash);

      const betGame = {
        id: betGameId,
        //oracleId: parseInt(gameResult.oracleId._hex),
        competitionId: jsonGame.competitionId,
        homeId: jsonGame.homeId,
        awayId: jsonGame.awayId,
        timestamp: jsonGame.timestamp,
        goals: jsonGame.goals,
        score: jsonGame.score,
        status: jsonGame.status,
        resolved: gameResult.resolved,
        markets: jsonGame.markets,
      };

      const marketsGame = await coreContract.getGameMarkets(betResult.gameId);
      console.log("MyBets.js - marketsGame: ", marketsGame);
      console.log(
        "MyBets.js - userBetsSetsIds[betsSetId]: ",
        userBetsSetsIds[betsSetId]
      );
      console.log("MyBets.js - gameResult: ", gameResult); //gameResult.oracleId
      console.log("MyBets.js - betResult: ", betResult); //betResult.gameId
      console.log("MyBets.js - betGameId: ", betGameId);
      console.log("MyBets.js - betGame: ", betGame);
      console.log("MyBets.js - jsonGame: ", jsonGame);
      //console.log('MyBets.js - game: ',game)
      console.log("MyBets.js - globalMarkets: ", globalMarkets);
      console.log("MyBets.js - globalGames: ", globalGames); //globalGames.football [{oracleId,id}]
      console.log("MyBets.js - globalFullGames: ", globalFullGames); //globalFullGames.football.la_liga [{oracleId,gameId}]
      const betOutcomeWinId = parseInt(betResult.outcomeWinId._hex);
      const betMarketId = parseInt(betResult.marketId._hex);
      console.log("MyBets.js - betMarketId: ", betMarketId);
      console.log(
        "MyBets.js - marketsGame.find(): ",
        marketsGame.find(
          (market) => parseInt(market.marketId._hex) === betMarketId
        )
      );
      const betMarket = {
        ...globalMarkets[betSport.keyName].find(
          (market) => market.id === betMarketId
        ),
      };
      betMarket.value = betGame.markets.find(
        (market) => market.id === betMarketId
      ).values[betOutcomeWinId].value;
      betMarket.odd = parseInt(
        marketsGame.find(
          (market) => parseInt(market.marketId._hex) === betMarketId
        ).odds[betOutcomeWinId]._hex
      );
      betMarket.oddsDecimals = 2/* parseInt(
        marketsGame.find(
          (market) => parseInt(market.marketId._hex) === betMarketId
        ).oddsDecimals[betOutcomeWinId]._hex
      ); */
      betMarket.outcomeId = parseInt(
        marketsGame.find(
          (market) => parseInt(market.marketId._hex) === betMarketId
        ).outcomesIds[betOutcomeWinId]._hex
      );
      betMarket.resolvedOutcomeIds = marketsGame
        .find((market) => parseInt(market.marketId._hex) === betMarketId)
        .resolvedOutcome.map((id) => parseInt(id._hex));
      const betCompetition = globalCompetitions[betSport.keyName].find(
        (competition) => competition.id === betGame.competitionId
      );
      const betHomeTeam = globalTeams[betSport.keyName].find(
        (team) => team.id === betGame.homeId
      );
      const betAwayTeam = globalTeams[betSport.keyName].find(
        (team) => team.id === betGame.awayId
      );

      //console.log('__mybets betGame',betGame)
      const bet = {
        betId,
        sportId: betSport.id,
        sportKeyname: betSport.keyName,
        game: betGame,
        home: betHomeTeam,
        away: betAwayTeam,
        competition: betCompetition,
        amount: parseInt(betResult.amount._hex),
        market: betMarket,
        odd: parseInt(betResult.odd._hex),
        oddDecimals: 2,//parseInt(betResult.oddDecimals._hex),
        outcomeWinId: betOutcomeWinId,
      };

      console.log("MyBets.js - betsSet: ", bet);
      betsSet.bets.push(bet);
    }

    return betsSet;
  };

  const getBetsSets = async (userBetsSetsIds) => {
    const betsSets = [];
    const promises = [];
    
    const sortedKeys = Object.keys(userBetsSetsIds).sort(
      (a, b) => parseInt(b) - parseInt(a)
    );
    console.log("sortedKeys", sortedKeys);
    for (const betsSetId of sortedKeys) {
      /*const betsSet = await getBetsSet(userBetsSetsIds, betsSetId);
      betsSets.push(betsSet);
      dispatch(slice.addUserBetsSet(betsSet));
      console.log("___mybets result betsSets dispatch_", userBetsSets);
      console.log("___mybets result betsSet_", betsSet);*/

      const promise = getBetsSet(userBetsSetsIds, betsSetId).then(betsSet => {
        betsSets.push(betsSet);
        dispatch(slice.addUserBetsSet(betsSet));
  
        console.log("___betslip result betsSets dispatch_", userBetsSets);
        console.log("___betslip result betsSet_", betsSet);
  
      });
  
      await Promise.all(promises);

      promises.push(promise);
    }
    return promises;
  }

  const getRecentlyPlacedBetsSets = async () => {
    //const betsSets = [];
    //const promises = [];

    const userBetsSetIdsUnparsedResult = await coreContract.getUserBetsSetIds(wallet.address);
    const userBetsSetsIdsResult = userBetsSetIdsUnparsedResult.map((res) =>
      parseInt(res._hex)
    );

    const recentlyPlacedBetsSetsIds = userBetsSetsIdsResult.filter((id) => !userBetsSets.some(userBetsSet => userBetsSet.id === id))
    console.log('recentlyPlacedBetsSetsIds',recentlyPlacedBetsSetsIds)

    /* const betsMapping = await Promise.all(
      recentlyPlacedBetsSetsIds.map((id) => coreContract.getBetsMapping(id))
    );
    console.log("___mybets result betsMapping", betsMapping) */
    const betsMapping = await userBetsSetsIdsResult.reduce(async (accPromise, id) => {
      const acc = await accPromise;
      const result = await coreContract.getBetsMapping(id);
      return { ...acc, [id]: result.map(res => parseInt(res._hex)) };
    }, Promise.resolve({}));
    console.log("___mybets result betsMapping", betsMapping);

    /* let userBetsSetsIds = userBetsSetsIdsResult.reduce((acc, id, index) => {
      acc[id] = betsMapping[index].map(res => parseInt(res._hex));
      return acc;
    }, []);
    console.log("___mybets result userBetsSetsIds reduce", userBetsSetsIds);

    userBetsSetsIds = userBetsSetsIds.filter((element) => {
        return element !== undefined;
    });
    console.log("___mybets result userBetsSetsIds filter", userBetsSetsIds); */

    /* const sortedKeys = Object.keys(userBetsSetsIds).sort(
      (a, b) => parseInt(b) - parseInt(a)
    );
    console.log("sortedKeys", sortedKeys);
    for (const betsSetId of sortedKeys) {

      const promise = getBetsSet(userBetsSetsIds, betsSetId).then(betsSet => {
        betsSets.push(betsSet);
        dispatch(slice.addUserBetsSet(betsSet));
  
        console.log("___betslip result betsSets dispatch_", userBetsSets);
        console.log("___betslip result betsSet_", betsSet);
  
      });
  
      await Promise.all(promises);

      promises.push(promise);
    } */

    if (
      globalSports &&
      globalGames &&
      globalMarkets &&
      globalCompetitions &&
      globalTeams
      /*&& userBetsSets.length === 0*/
    ) {
      const fetchData = async () => {
        console.log("___mybets result betsSets_loading");
        const betsSets = await getBetsSets(betsMapping);
        //setUserBetsSets(betsSets)
        //dispatch(slice.addUserBetsSets(betsSets))
        console.log("___mybets result betsSets_", betsSets);
        //console.log('___mybets result userBetsSets_',userBetsSets)
        //getFilteredBetsSets(betsSets);
        setLoadingRecentlyPlacedBetsSets(false);
        return betsSets;
      };
      fetchData();
    }

    //return betsSets;
  };

  //const [capturedApproveValue, setCapturedApproveValue] = useState(null)
  const handlePlaceBetResolved = async (betsWrapper, /*betslip,*/ parsedPaidAmount) => {
    setLoadingRecentlyPlacedBetsSets(true);
    handleBetslipTopButtons('my-bets')
    console.log('handleApprovedResolved')
    //setCapturedApproveValue(value);
    console.log('resultado betsWrapper',betsWrapper)
    console.log('resultado parsedPaidAmount',parsedPaidAmount)
    /* createBetsSets(betslip).forEach(createdBetSet => {
      console.log('createdBetSet',createdBetSet)
      dispatch(slice.addUserBetsSet(createdBetSet)) //TODO: al hacer la apuesta, añadir este betsSet al userBetsSet para que se muestre en el panel My Bets/página My Bets
    }) */

    getRecentlyPlacedBetsSets();

    //switchCombo(false)
    const updatedBetslip = {
      ...betslip,
      combo: false
    }
    dispatch(slice.clearBetslip(updatedBetslip, () => betslip))
    setResetAmount(parseFloat(5).toFixed(2))

    //TODO: externalizar este bloque a funcion
    const _wallet = {
      ...wallet,
      balance: null
    }
    console.log('Betslip.js wallet',wallet)
    console.log('Betslip.js balance',_wallet)
    dispatch(slice.updateWallet(_wallet))
    //FIN TODO

    //updateAllowance()
    updateWallet()
    updateBonus()
    
    //Update WB in DB server
    /* updateWBInfo({
      ...welcomeBonus,
      isEnjoyed: true,
      wallet: wallet
    }) */

    //TODO: Poner el welcome bonus a false (FALTA PROBAR)
    const _welcomeBonus = {
      ...welcomeBonus,
      isApplied: true,
      isEnjoyed: true,
      isUserApplicableForBonus: false
    }

    /* const wbResponse = await */ updateWBInfo({
      ..._welcomeBonus,
      wallet: wallet
    })
    
    dispatch(slice.updateWelcomeBonus(_welcomeBonus))
  }

  const callPlaceBet = () => {
    //try {
      const betsWrapper = betslip.bets.length > 0
      ? betslip.bets.map(bet => {
          return [
            bet.sportId,
            bet.gameId,
            bet.marketId,
            !isNaN(bet.amount) ? ethers.utils.parseUnits(bet.amount.replace('.', ''), wallet.tokenDecimals - 2) : 0,
            !isNaN(bet.odd) ? ethers.utils.parseUnits(bet.odd.replace('.', ''), wallet.tokenDecimals - 2) : 0,
            bet.outcomeId
          ];
        })
      : []
      
      console.log('Resultado de betslip:', betslip)
      console.log('Resultado de betsWrapper:', betsWrapper)
      //console.log('Resultado de betsWrapper [3]:', parseInt(betsWrapper[0][3]))
      //console.log('Resultado de betsWrapper [4]:', parseInt(betsWrapper[0][4]))
      const paidAmount = betslip.combo ?
        betslip.totalComboAmount :
        betslip.totalAmount
      console.log('Resultado de paidAmount:', paidAmount)
      //const betsSetWrapper = [betsWrapper, betslip.combo, paidAmount]
      const parsedPaidAmount = !isNaN(paidAmount) && paidAmount !== undefined ? ethers.utils.parseUnits((paidAmount).toString().replace('.',''), wallet.tokenDecimals-2) : 0
      /*if (!wallet.isConnected) {
        await ethersConfig.handleConnectWallet(dispatch, wallet)
      }

      
      await signPlaceBetTx(betsWrapper, betslip, parsedPaidAmount)*/
      if (betsWrapper && betslip && parsedPaidAmount) {
        console.log('betsWrapper.length',betsWrapper)
        return {betsWrapper, /* betslip, */ parsedPaidAmount}
      }

    /*} catch (error) {
      console.error('Bet couldn\'t be placed:', error)
    }*/
  }

  //OLD WAY WITH window.ethereum
  /*const callApprove = async () => {
    const amount = betslip.combo ? 
    betslip.totalComboAmount : //TODO: obtener decimales del contrato
    betslip.totalAmount //TODO: obtener decimales del contrato
    const parsedAmount = ethers.utils.parseUnits(amount.replace('.',''), 6-2)
    if (!wallet.isConnected) {
      await ethersConfig.handleConnectWallet(dispatch, wallet)
    }
    const web3Config = ethersConfig.web3Config
    const provider = await new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner()
    console.log('#provider:',provider)
    console.log('#signer:',signer)
    console.log('#await web3Config.tokenContract:',await web3Config.tokenContract)
    const tokenContractWithSigner = await web3Config.tokenContract.connect(signer)
    if (!ethers.utils.isAddress(liquidityContract.address)) {
      console.error("Dirección no válida:", liquidityContract.address);
    }else{
      console.log("Dirección VÁLIDA:", liquidityContract.address);
    }
    console.log('parsedAmount:', parsedAmount)
    const tx = await tokenContractWithSigner.approve(liquidityContract.address, parsedAmount)
    await tx.wait();
    if (tx.hash !== null) {
      await updateAllowance()
    }
    console.log('Resultado de tx:', tx.hash)
    //const tx = await tokenContract.approve(liquidityContract.address, amount)

  }*/

  const [capturedApproveValue, setCapturedApproveValue] = useState(null)
  
  /*useEffect(() => {
    console.log('isBeingTransacted',isBeingTransacted)
  }, [isBeingTransacted])*/
  const handleApprovedResolved = async (value) => {
    //updateAllowance()
    await updateWallet(['allowance'])
    console.log('handleApprovedResolved')
    setCapturedApproveValue(value); //TODO: si no se usa, quitar esta linea
    console.log('resultado approve',value)
  }

  const callApprove = () => {
      console.log('callApprove')
      const amount = '1000000'+(10**wallet.tokenDecimals).toString() /*betslip.combo ? 
      betslip.totalComboAmount : //TODO: obtener decimales del contrato
      betslip.totalAmount //TODO: obtener decimales del contrato*/
      //const parsedAmount = ethers.utils.parseUnits(amount.replace('.',''), wallet.tokenDecimals)
      const parsedAmount = ethers.utils.parseUnits(amount, wallet.tokenDecimals)
      if (!wallet.isConnected) {
        //await ethersConfig.handleConnectWallet(dispatch, wallet)
        //TODO: comprobar si hace falta llamar a conectar billetera o lo hace TW
      }
      
      return parsedAmount
  }

  //PROVISIONAL
  /*const ApproveButton = () => {
    const web3Config = ethersConfig.web3Config('mumbai');
    const { contract } = useContract(
        web3Config.tokenAddress,
        web3Config.tokenAbi
    );
    const { mutateAsync } = useContractWrite(
        contract,
        "approve",
    );

    const tx = mutateAsync({ args: [liquidityContract.address, 1000000] })
    console.log(tx)
    tx.then(async (txResult) => {
      console.log('resultado approve',txResult)
      //if (txResult.receipt.transactionHash) {
        //await updateAllowance()
      //}
    })
  }*/

  /*const PlaceBetButton = () => {
    let placeBetButton
    if (!betslip.combo && allowance < parseFloat(betslip.totalAmount)
      || betslip.combo && allowance < parseFloat(betslip.totalComboAmount)) {
      placeBetButton = false
    } else {
      placeBetButton = true
    }
    //console.log('allowance',allowance)
    //console.log('betslip.totalAmount',parseFloat(betslip.totalAmount))
    //console.log('placeBetButton',placeBetButton)
    const buttonClassName = 'btn-medium betslip-place-bet-btn bg-gradient-yellow-orange pt-0 pr-12 pb-0 pl-20 pr-20 h-40 lh-40 br-5'
    if (!wallet.isConnected) {
      return (
        <button className={buttonClassName}
          onClick={handleConnectWallet}>
          {'Connect wallet'}
        </button>
      )
    } else {
      return (
        {//<button className={buttonClassName}
          //onClick={placeBetButton ? placeBet : <ApproveButton network={'mumbai'} spender={liquidityContract.address} amount='1000000' />}>
          //{placeBetButton ? 'Place bet' : 'Approve'}
      //</button>
      }
      
      )
    }
  }*/

  const BetslipOpenCloseButton = () => {
    if (isBetslipVisible) {
        return (
            <FontAwesomeIcon icon={icon({name: 'xmark'})} />
        )
    } else {
        return (
            <FontAwesomeIcon icon={icon({name: 'ticket'})} />
        )
    }
  }

  const checkAllUserBetsSetsResolved = () => {
    let areAllResolved = true
    if (userBetsSets !== undefined) {
      userBetsSets.forEach(betsSet => {
        if (betsSet.bets !== undefined) {
          betsSet.bets.forEach(bet => {
            if (!bet.game.resolved) {
              areAllResolved = false;
            }
          })
        }
      })
      console.log('areAllUserBetsSetsResolved',areAllResolved)
      setAllUserBetsSetsResolved(areAllResolved)
    }
  }

  const isBetsSetGameResolved = (bets) => {
    let allResolved = true
    bets.forEach(bet => {
      if (!bet.game.resolved) {
        allResolved = false;
      }
    })
    return allResolved
  }

  const isUserApplicableForBonusForThisSingleBet = (index) => {
    return (
      /* !welcomeBonus.isApplied
      && bonus.isEnabled
      && bonus.isUserApplicableForBonus
      && (bonus.bonusAmount/(10**wallet.tokenDecimals) > 0) */
      isUserApplicableForBonus()
      && !betslip.combo
      && index === 0
    )
  }
  
  const isUserApplicableForBonusForThisCombo = () => {
    console.log('isUserApplicableForBonusForThisCombo',betslip.combo)
    return (
      /* !welcomeBonus.isApplied
      && bonus.isEnabled
      && bonus.isUserApplicableForBonus
      && (bonus.bonusAmount/(10**wallet.tokenDecimals) > 0) */
      isUserApplicableForBonus()
      && betslip.combo
    )
  }
  
  const isUserApplicableForBonus = () => {
    console.log('isUserApplicableForBonus', welcomeBonus.isApplied
    , bonus.isEnabled
    , bonus.isUserApplicableForBonus
    , (bonus.bonusAmount/(10**wallet.tokenDecimals) > 0))
    return (
      (!welcomeBonus.isApplied
        || (welcomeBonus.isApplied && welcomeBonus.isEnjoyed))
      && bonus.isEnabled
      && bonus.isUserApplicableForBonus
      && (bonus.bonusAmount/(10**wallet.tokenDecimals) > 0)
    )
  }

  const connectButtonClassName = 'btn-medium betslip-place-bet-btn bg-gradient-yellow-orange pt-0 pr-12 pb-0 pl-20 pr-20 h-40 lh-40 br-5'
  const deleteClassName = 'mt-6 dp-block'

  const ShowLoadingIcon = () => {
    const loadingIconClassName = 'mt-8 dp-block spinner-loader-big-white'
    return (
        <div className = {loadingIconClassName}></div>
    )
  }

  return (
    <>
      <div id='betslip-overlap-background' className={isBetslipVisible ? 'betslip-overlap-background' : 'dp-none'} onClick={() => setIsBetslipVisible(false)}></div>
      <div id='betslip-panel' className={isBetslipVisible ? null : 'dp-none-mobile'}>
        {/*<TokenAllowance render={(sportsKeyName) => (
          <>
              {console.log('Nombre del token:', sportsKeyName)}
              {sportsKeyName !== null ? sportsKeyName : 'Loading...'}
          </>
        )} address={web3Config.tokenAddress} />*/}
        <div id='betslip-subpanel'>
          <div className='betslip-top-buttons'>
            <button
              className={betslipToggleOn === 'betslip' ? 'betslip-top-button-clicked btn-small betslip-top-btn' : 'betslip-top-button-non-clicked btn-small betslip-top-btn'}
              onClick={() => handleBetslipTopButtons('betslip')} >
              Betslip
            </button>
            <button
              className={betslipToggleOn === 'my-bets' ? 'betslip-top-button-clicked btn-small betslip-top-btn' : 'betslip-top-button-non-clicked btn-small betslip-top-btn'}
              onClick={() => handleBetslipTopButtons('my-bets')} >
              My bets
            </button>
          </div>
          <div id='betslip' className={betslipToggleOn === 'betslip' ? 'betslip-info dp-block' : 'betslip-info dp-none'}>
            <div className={betslip &&betslip.bets.length === 0 ? 'dp-block' : 'dp-none'}>
              <div className='betslip-info-icon'>
                <Icon icon={'info'} classname={'betslip-info-icon'} />
              </div>
              <div className='betslip-info-title'>Betslip is empty</div>
              <div className='betslip-info-description col-grey-light'>To add a bet to your betslip, choose a market and make your selection</div>
            </div>
            <div className={betslip.bets.length > 0 ? 'dp-block' : 'dp-none'}>
              <div className='betslip-combo-toggle'>
                <Toggle
                  label='Combo'
                  toggled={betslip.combo}
                  onClick={switchCombo}
                />
              </div>
              {isUserApplicableForBonus() && <div className='betslip-combo-toggle'>
                <Toggle
                  label={`${wallet.tokenSymbol}${bonus.bonusAmount / (10**wallet.tokenDecimals)} Bonus`}
                  toggled={betslip.isBonusApplied}
                  onClick={switchBonus}
                />
              </div>}
              {!welcomeBonus.isEnjoyed && welcomeBonus.isApplied && betslip.combo && !betslip.bets.some(bet => bet.odd < 1.5) && (
                <div className='betslip-card-wb-odd-info'>
                  <div className='betslip-card-wb-odd-info-message'>
                    <FontAwesomeIcon icon={icon({name: 'circle-check'})} /> Welcome Bonus will be applied to this combo
                  </div>
                </div>
              )}
              {!welcomeBonus.isEnjoyed && welcomeBonus.isApplied && betslip.combo && betslip.bets.some(bet => bet.odd < 1.5) && (
                <div className='betslip-card-wb-odd-warning'>
                  <div className='betslip-card-wb-odd-warning-message'>
                    <FontAwesomeIcon icon={icon({name: 'triangle-exclamation'})} /> This combo doesn't meet the minimum odd requirement to enjoy the Welcome Bonus. Please choose odds of 1.5 or higher
                  </div>
                </div>
              )}
              {isUserApplicableForBonusForThisCombo() && betslip.isBonusApplied && (
                <div className='betslip-card-wb-odd-info'>
                  <div className='betslip-card-wb-odd-info-message'>
                    <FontAwesomeIcon icon={icon({name: 'circle-check'})} /> {wallet.tokenSymbol}{bonus.bonusAmount/(10**wallet.tokenDecimals)} bonus will be applied to this combo
                  </div>
                </div>
              )}
              <div style={{maxHeight: windowHeight * 0.3}} className='betslip-cards'>
                {/*console.log('betslip ==== ',betslip)*/}
                {/*console.log('betslip.bets ==== ',betslip.bets)*/}
                {betslip.bets.map((bet, index) => (
                  <div key={`${bet.gameId}-${bet.marketId}-${bet.outcomeId}`} className='betslip-card'>
                    {!welcomeBonus.isEnjoyed && welcomeBonus.isApplied && !betslip.combo && bet.odd >= 1.5 && index === 0 && (
                      <div className='betslip-card-wb-odd-info'>
                        <div className='betslip-card-wb-odd-info-message'>
                          <FontAwesomeIcon icon={icon({name: 'circle-check'})} /> Welcome Bonus will be applied to this bet
                        </div>
                      </div>
                    )}
                    {isUserApplicableForBonusForThisSingleBet(index) && betslip.isBonusApplied && (
                      <div className='betslip-card-wb-odd-info'>
                        <div className='betslip-card-wb-odd-info-message'>
                          <FontAwesomeIcon icon={icon({name: 'circle-check'})} /> {wallet.tokenSymbol}{bonus.bonusAmount/(10**wallet.tokenDecimals)} bonus will be applied to this bet
                        </div>
                      </div>
                    )}
                    <button className='betslip-card-remove-btn' onClick={() => removeBet(bet.gameId, bet.marketId, bet.outcomeId)}>
                      <FontAwesomeIcon icon={icon({name: 'trash-can'})} className = {deleteClassName} />
                    </button>
                    <div className={utils.hasGameStarted(bet.startsAt)
                      ? 'betslip-card-participants text-disabled'
                      : 'betslip-card-participants'}>
                      {utils.hasGameStarted(bet.startsAt) && <Icon icon={'lock'} classname={'icon-small mr-6'} />}
                      {bet.homeShortName} - {bet.awayShortName}
                    </div>
                    <div className={utils.hasGameStarted(bet.startsAt) ? 'betslip-card-market text-disabled' : 'betslip-card-market'}>
                      {bet.marketName}
                    </div>
                    <div className={utils.hasGameStarted(bet.startsAt) ? 'betslip-card-market-value strike-out text-disabled' : 'betslip-card-market-value'}>
                      {bet.outcomeDesc}
                    </div>
                    <div className={utils.hasGameStarted(bet.startsAt) ? 'betslip-card-odd strike-out text-disabled' : 'betslip-card-odd'}>
                      @ {bet.odd}
                    </div>
                    {((!welcomeBonus.isEnjoyed && welcomeBonus.isApplied && !betslip.combo && bet.odd < 1.5 && index === 0)
                      || (!welcomeBonus.isEnjoyed && welcomeBonus.isApplied && betslip.combo && bet.odd < 1.5))
                      && (
                      <div className='betslip-card-wb-odd-warning'>
                        <div className='betslip-card-wb-odd-warning-triangle'></div>
                        <div className='betslip-card-wb-odd-warning-message'>
                          <FontAwesomeIcon icon={icon({name: 'triangle-exclamation'})} /> This odd doesn't meet the minimum odd requirement. Please choose odds of 1.5 or higher
                        </div>
                      </div>
                    )}
                    <div className={betslip.combo === false ? 'betslip-single-amount dp-block' : 'betslip-single-amount dp-none'}>
                      <div className='betslip-card-amount'>
                        <div className='betslip-card-amount-text'>
                          Amount
                        </div>
                        <input className='betslip-card-amount-input'
                          type='number'
                          name='amount'
                          pattern='^([1-9]\d*(\.|\,)\d{1,2}|0?(\.|\,){1,2}[1-9]{1,2}|[1-9]{1,2})$' //TODO
                          defaultValue={
                            isUserApplicableForBonusForThisSingleBet(index) && betslip.isBonusApplied
                            ? parseFloat(bonus.bonusAmount/(10**wallet.tokenDecimals)).toFixed(2)
                            : bet.amount
                          }
                          value={
                            isUserApplicableForBonusForThisSingleBet(index) && betslip.isBonusApplied
                            ? parseFloat(bonus.bonusAmount/(10**wallet.tokenDecimals)).toFixed(2)
                            : bet.amount
                          }
                          onChange={e => parseFloat(onAmountChange(e.target.value, bet.gameId, bet.marketId, bet.outcomeId, bet.odd)).toFixed(2)}
                          disabled={
                            utils.hasGameStarted(bet.startsAt)
                            || (isUserApplicableForBonusForThisSingleBet(index) && betslip.isBonusApplied)
                          }
                        />
                        <div className='betslip-card-amount-currency'>
                          {wallet.tokenSymbol ? wallet.tokenSymbol : utils.defaultCurrencySymbol}
                        </div>
                      </div>
                      <div className='betslip-card-possible-winnings'>
                        <div className='betslip-card-possible-winnings-text'>
                          Possible winnings
                        </div>
                        <div className='betslip-card-possible-winnings-amount'>
                          {console.log('&&&betslip', betslip)}
                          {bet.possibleWinnings} {wallet.tokenSymbol ? wallet.tokenSymbol : utils.defaultCurrencySymbol}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className={betslip.combo === false ? 'betslip-total-amount' : 'dp-none'}>
                <div className='betslip-total-amount-text'>
                  Total amount
                </div>
                <div className='betslip-total-amount-value'>
                  {!isNaN(betslip.totalAmount) ? betslip.totalAmount : 0} {wallet.tokenSymbol ? wallet.tokenSymbol : utils.defaultCurrencySymbol}
                </div>
              </div>
              <div className={betslip.combo === true ? 'betslip-combo dp-block' : 'betslip-combo dp-none'}>
                <div className='betslip-combo-total-odds'>
                  <div className='betslip-combo-total-odds-text'>
                    Total odds
                  </div>
                  <div className='betslip-combo-total-odds-odd'>
                    @ {betslip.totalComboOdds}
                  </div>
                </div>
                <div className='betslip-combo-amount'>
                  <div className='betslip-card-amount-text'>
                    Amount
                  </div>
                  <input className='betslip-card-amount-input'
                    type='number'
                    name='amount'
                    /*pattern='^\d+(?:\.\d{1,2})?$'*/
                    pattern='^([1-9]\d*(\.|\,)\d{1,2}|0?(\.|\,){1,2}[1-9]{1,2}|[1-9]{1,2})$' //TODO
                    defaultValue={
                      /* parseFloat(betslip.totalComboAmount).toFixed(2) */
                      isUserApplicableForBonusForThisCombo() && betslip.isBonusApplied
                      ? betslip.totalComboAmount
                      : betslip.totalComboAmountWithoutBonus
                    }
                    onChange={e => parseFloat(onComboAmountChange(e.target.value)).toFixed(2)}
                    value={
                      isUserApplicableForBonusForThisCombo() && betslip.isBonusApplied
                      ? betslip.totalComboAmount
                      : betslip.totalComboAmountWithoutBonus
                    }
                    disabled={
                      utils.hasAnyGameStarted(betslip.bets)
                      || isUserApplicableForBonusForThisCombo() && betslip.isBonusApplied
                    }
                  />
                  <div className='betslip-card-amount-currency'>
                    {wallet.tokenSymbol ? wallet.tokenSymbol : utils.defaultCurrencySymbol}
                  </div>
                </div>
                <div className='betslip-card-possible-winnings'>
                  <div className='betslip-card-possible-winnings-text'>
                    Possible winnings
                  </div>
                  <div className='betslip-card-possible-winnings-amount'>
                    {!isNaN(betslip.totalComboPossibleWinnings) ? betslip.totalComboPossibleWinnings : 0} {wallet.tokenSymbol ? wallet.tokenSymbol : utils.defaultCurrencySymbol}
                  </div>
                </div>
              </div>
              <div className='betslip-place-bet'>
              {
                (
                  !welcomeBonus.isEnjoyed && welcomeBonus.isApplied &&
                  (
                    (
                      betslip.combo &&
                      (
                          betslip.totalComboAmount < 20 ||
                          betslip.totalComboAmount > 50
                      )
                    ) ||
                    (
                      !betslip.combo &&
                      (
                          betslip.totalAmount < 20 ||
                          betslip.totalAmount > 50
                      )
                    )
                  )
                ) && (
                  <div className='betslip-place-bet-warning'>
                    <div className='betslip-place-bet-warning-message'>
                      <FontAwesomeIcon icon={icon({name: 'triangle-exclamation'})} /> The bet amount range should be between {wallet.tokenSymbol ? wallet.tokenSymbol : utils.defaultCurrencySymbol}20-50
                    </div>
                  </div>
                )
              }
              {
                (
                  !welcomeBonus.isApplied &&
                  (
                    (
                      betslip.combo &&
                      (
                          betslip.totalComboAmount < 0.5 ||
                          betslip.totalComboAmount > 80
                      )
                    ) ||
                    (
                      !betslip.combo &&
                      (
                          betslip.totalAmount < 0.5 ||
                          betslip.totalAmount > 80
                      )
                    )
                  )
                ) && (
                  <div className='betslip-place-bet-warning'>
                    <div className='betslip-place-bet-warning-message'>
                      <FontAwesomeIcon icon={icon({name: 'triangle-exclamation'})} /> The bet amount range should be between {wallet.tokenSymbol ? wallet.tokenSymbol : utils.defaultCurrencySymbol} 0.50-80
                    </div>
                  </div>
                )
              }
              {
                (!wallet.isConnected)
                ?
                  <ConnectWallet
                      className={connectButtonClassName}
                      detailsBtn={() => {
                          <button>
                              {'Connect wallet'}
                          </button>
                      }}
                      modalSize={'compact'}
                      modalTitle={"Connect wallet"}
                      modalTitleIconUrl={""}
                      showThirdwebBranding={false}
                  />
                :
                ((!betslip.combo && (wallet.allowance < parseFloat(betslip.totalAmount)))
                || (betslip.combo && (wallet.allowance < parseFloat(betslip.totalComboAmount))))
                ?
                  <ApproveButton
                    network={wallet.network.keyName}
                    spender={web3Config.liquidityAddress}
                    onApproveClick={callApprove}
                    onTxApproveResolved={handleApprovedResolved}
                  />
                :
                  <PlaceBetButton
                    network={wallet.network.keyName}
                    onPlaceBetClick={callPlaceBet}
                    onTxPlaceBetResolved={handlePlaceBetResolved}
                  />
              }  
              </div>
            </div>
          </div>
          
          <div id='my-bets' className={betslipToggleOn === 'my-bets' ? 'betslip-info dp-block' : 'betslip-info dp-none'}>
            <div className={allUserBetsSetsResolved ? 'dp-block' : 'dp-none'}>
              <div className='betslip-info-icon'>
                <Icon icon={'info'} classname={'betslip-info-icon'} />
              </div>
              <div className='betslip-info-title'>No active bets</div>
              <div className='betslip-info-description col-grey-light'>All unsettled bets will be listed here</div>
            </div>
            <div className={!allUserBetsSetsResolved && userBetsSets.length > 0 ? 'dp-block' : 'dp-none'}>
              <div style={{maxHeight: windowHeight * 0.5}} className='betslip-cards betslip-my-bets-cards'>
                {loadingRecentlyPlacedBetsSets === true && (
                  <>
                    <div className='betslip-loading-icon'>
                      <ShowLoadingIcon />
                    </div>
                    <div className='betslip-info-title'>Loading bets</div>
                  </>
                )}
                {loadingRecentlyPlacedBetsSets === false && userBetsSets.map(betsSet => betsSet.id && (
                  <MyBetSingleCard key={betsSet.id} betsSet={betsSet} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        !isLoading && (
          <div className={isBetslipOpenCloseButtonHidden ? 'animation-hidden' : 'animation-visible'}>
            <div id='betslip-open-close-button' className='betslip-open-close-button' onClick={toggleBetslipVisible}>
              <BetslipOpenCloseButton />
            </div>
            <div id='betslip-counter' className={betslip.bets.length > 0 ? 'betslip-counter' : 'dp-none'}>
              {betslip.bets.length}
            </div>
          </div>
        )
      }
    </>
  );
}

export default Betslip;
