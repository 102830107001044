import * as slice from '../utils/slice.js';
import moment from 'moment';
import Moment from 'react-moment';

/* if (process.env.NODE_ENV === "production")
    console.log = function () {}; */

/* if (process.env.NODE_ENV === "development")
    console.log = function () {}; */

export const currentVersion = '0.0.9'
//export const currentTimestamp = 1692921600 //25/08/2023
//export const currentTimestamp = 1693008000  //26/08/2023
//export const currentTimestamp = 1693094400; //27/08
//export const currentTimestamp = 1693180800; //28/08
//export const currentTimestamp = 1693267200; //29/08
//export const currentTimestamp = 1693440002; //31/08
//export const currentTimestamp = 1684713600
//export const currentTimestamp = utils.getTimestampAtMidnight();
export const currentTimestamp = moment().unix()
export const daysOnwards = 3;
export const loadAfterMinutesLapsed = 30;
export const lang = 'en';

export const sleep = (ms) => {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
}

export const isCurrentVersionGreater = (storedVersion) => {
    const storedVersionParts = storedVersion.split('.').map(Number);
    const currentVersionParts = currentVersion.split('.').map(Number);

    let isNewVersionGreater = false;
    for (let i = 0; i < storedVersionParts.length; i++) {
        if (currentVersionParts[i] > storedVersionParts[i]) {
            isNewVersionGreater = true;
            break;
        } else if (currentVersionParts[i] < storedVersionParts[i]) {
            break;
        }
    }
    return isNewVersionGreater
}

export const defaultNetwork = {
    keyName: process.env.REACT_APP_DEFAULT_NETWORK_KEYNAME
}

export const Url = {
    PROTOCOL: window.location.protocol,
    DOMAINNAME: window.location.hostname,
    PORT: window.location.port
}

export const betsSetStatus = {
    PENDING: 'pending',
    WON: 'won',
    LOST: 'lost',
    SUSPENDED: 'suspended'
}

export const gameStatus = {
    SUSPENDED: 'SUS',
    CANCELLED: 'CAN'
}

export const baseUrl = `${Url.PROTOCOL}//${Url.DOMAINNAME}:${Url.PORT}`
export const iconsPath = `${baseUrl}/assets/icons/others/`
export const genericTeamLogoHash = 'QmNsTPpokWNL2kUj5EcpboSAc9BGE25HV9pk6Jt6aZV326'
export const defaultCurrencySymbol = 'DAI'

export const Css = {
    BLANK: '-blank'
}

export const unlockedIPs = [
    '79.116.213.138',
    '79.116.75.113'
];

export const blockedCountries = {
    'US': 'United States',
    'RU': 'Russia',
    'ES': 'Spain'
};

export const isIpUnlocked = (ip) => {
    if (unlockedIPs.includes(ip)) {
        return true
    } else {
        return false
    }
}

export const isCountryLocked = (country) => {
    if (Object.keys(blockedCountries).includes(country)) {
        return true
    } else {
        return false
    }
}

export const goToHome = () => {
    const goTo = baseUrl
    window.location.href = goTo;
};

export const buildFullUrl = (network, type, sportKeyname, competitionKeyname, gameId, teams = '') => {
    const _competitionKeyname = replaceCharacter(competitionKeyname, '_', '-');
    const _sportKeyname = replaceCharacter(sportKeyname, '_', '-');
    const _network = network ? network : defaultNetwork.keyName
    
    switch (type) {
        case 'sports':
            return `${baseUrl}/${_network}/${type}/${_sportKeyname}/${_competitionKeyname}/${gameId}/${teams}`
    }
}

export const buildSingleFullUrl = (network, page) => {
    return `${baseUrl}/${network}/${page}`
}

export const getDiscordUserToken = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const discordUserToken = urlParams.get('code');
      console.log('discordUserToken',discordUserToken)
      if (discordUserToken) {
        return discordUserToken
      }
  
    return null
}

export const getJsonObjectFromHash = async (hash) => {
    //return new Promise((resolve, reject) => {
        //setTimeout(async () => {
            //console.log('getJsonObjectFromHash1 utils.js ',hash)
            try {
                await delay(10);
                //const response = await fetch(`https://ipfs.io/ipfs/${hash}`);
                /*TODO: obtener los json de ipfs a traves de algun RPC externo (para
                    intentar que la obtención de datos sea más rápida) y hacerlo mediante
                    axios (para establecer un timeout y si tarda demasiado en responder,
                    continuar con la carga del resto de elementos de la web) y que así no
                    se pare la carga solo porque no se puede obtener un solo json de IPFS
                */
                const response = await fetch(`https://ipfs.io/ipfs/${hash}`);
                //console.log('getJsonObjectFromHash2 utils.js ',hash,response.text())
                const data = await response.text();
                //console.log('getJsonObjectFromHash3 utils.js ',hash,data)
                return JSON.parse(data);
            } catch (error) {
                console.error('Error in getJsonObjectFromHash:',error);
            }
        //}, 50);
    //});
}

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const handleSmartContractError = async (_errorMessage) => {
    let errorMessage = _errorMessage._reason || "";
    
    // Buscar "Reason: " y extraer el mensaje siguiente
    //console.log('#looking for reason1a: ',errorMessage._reason)
    //console.log('#looking for reason1b: ',errorMessage._info)
    //console.log('#looking for reason1c: ',errorMessage._raw)
    //console.log('#looking for reason2: ',typeof errorMessage,errorMessage)

    if (typeof errorMessage === 'string') {
        const reasonParts = errorMessage.split(':');
        if (reasonParts.length > 1) {
            const detailedReason = reasonParts[1].trim();
            console.error("Error reason:", detailedReason);
            return detailedReason;
        } else {
            console.error("No ':' found in the reason.");
        }
    } else {
        console.error("Error message is not a string and could not be processed.");
    }
}

/*export const getTimestamp = (daysToSumArray) => {
    const currentDate = new Date();
    currentDate.setUTCHours(0, 0, 0, 0);
    const currentTimestamp = Math.round(currentDate.getTime() / 1000);

    const timestamps = [currentTimestamp];
    daysToSumArray.forEach((days) => {
        const newDate = new Date(currentDate.getTime());
        newDate.setDate(newDate.getDate() + days);
        const newTimestamp = Math.round(newDate.getTime() / 1000);
        timestamps.push(newTimestamp);
    });

    return timestamps;
}*/

/*export const ipfsHashes = (jsonType) => { //TODO: esto en produccion se obtendra desde el SC
    switch(jsonType){
        case "competitions":
            return "QmXwBMUvbmA4jq5en3Y24VoaZE8DktmieWk82hE3horbj7";
            break;
        case "teams":
            return "QmYjn9auiTEPATyXexPVBzHN3d2pQ41PKVsExQuK6x7RRx";
            break;
    }
}*/

export const dateToTimestampAtMidnight = (_date) => {
    const date = (_date ? moment(_date) : moment()).format("YYYY-MM-DD");
    console.log('getTimestampAtMidnight date:', date, _date)
    const timestampMidnight = moment.parseZone(date+"T00:00:00+00:00").add(0, 'days')/1000;
    /*const timestamp = (
        {timestampMidnight}
    )*/
    console.log('getTimestampAtMidnight:',timestampMidnight);
    return(
        timestampMidnight
    )
}

export const timestampToMidnight = (timestamp) => {
    if (timestamp === undefined) {
        timestamp = moment().unix();
      }
    
      // Create a moment object from the provided timestamp
      const momentTimestamp = moment.unix(timestamp).utc();
    
      // Set the time to midnight (00:00:00)
      momentTimestamp.startOf('day');
    
      // Get the timestamp at midnight in seconds
      const midnightTimestamp = momentTimestamp.unix();
      console.log('midnightTimestamp date:', midnightTimestamp, timestamp)
      return midnightTimestamp;
}

export const hasGameStarted = (gameTimestamp) => {
    const currentTimestamp = moment().unix();
    const hasStarted = currentTimestamp > gameTimestamp;
    console.log('hasGameStarted',currentTimestamp, gameTimestamp, hasStarted)
    return hasStarted;
}

export const hasAnyGameStarted = (games) => {
    let hasStarted = false;
    games.forEach((game) => {
        if (hasGameStarted(game.startsAt)) {
            hasStarted = true;
        }
    });
    return hasStarted;
}

/* Check if maxHours hours have elapsed since current day at midnight*/
export const isTimeLapsedReached = (lastConnectionTimestamp, currentTimestamp) => {
    console.log('isTimeLapsedReached lastConnectionTimestamp currentTimestamp',lastConnectionTimestamp, currentTimestamp)
    const maxHours = 4 // This number means the hour on the day. Ie: 4 => 4am GMT+0; 16 => 4pm GMT+0
    const midnightTimestamp = (moment.unix(timestampToMidnight(currentTimestamp))/1000)
    console.log('isTimeLapsedReached midnightTimestamp',midnightTimestamp)
    const limitMoment = moment.unix(midnightTimestamp+maxHours)
    const currentMoment = moment.unix(lastConnectionTimestamp)
    console.log('limitTimestamp currentMoment',limitMoment, currentMoment)
    const hoursDifference = currentMoment.diff(limitMoment, 'hours')
    console.log('hoursDifference',hoursDifference)
    if (hoursDifference > maxHours) {
        console.log(`More than ${maxHours} hours have passed.`);
        return true
    } else {
        console.log(`Less than ${maxHours} hours have passed.`);
        return false
    }
}

export const timeLapsedFromLastConnection = (lastConnection) => {
    //const lastConnection = props
    //const currentTimestamp = utils.currentTimestamp
    const secondsLapsed = currentTimestamp - lastConnection
    const minutesLapsed = secondsLapsed / 60
    const hoursLapsed = secondsLapsed / 3600

    console.log('Time lapsed since last connection => seconds: '+secondsLapsed+'; minutes: '+minutesLapsed+'; hours: '+hoursLapsed)
    return { secondsLapsed, minutesLapsed, hoursLapsed }
}

export const marketsPriority = {
    "football":[1,5],
    "basketball": [182, 185],
};
const gotMarkets = {};
export const reorderGameMarkets = (sport, markets) => {
    markets.forEach((market) => {
        if (Object.keys(gotMarkets).includes(sport) === false) {
            gotMarkets[sport] = [];
        }
        if (marketsPriority[sport].includes(market.id) && !gotMarkets[sport].includes(market.id) && market !== undefined) {
            gotMarkets[sport].push(market.id);
        }
    });
    //console.log('&&&gotMarkets',sport,gotMarkets[sport])
    const reorderedMarkets = [];
    reorderedMarkets.push(...gotMarkets[sport].map((gotMarketId) => {
        console.log('&&&gotMarketId',sport, gotMarketId,markets)
        return markets.find((market) => market.id === gotMarketId)
    }));
    console.log('&&&reorderedMarkets1',reorderedMarkets)
    reorderedMarkets.push(...markets.filter((market) => !gotMarkets[sport].includes(market.id) && market !== undefined));
    console.log('&&&reorderedMarkets2',reorderedMarkets)
    return reorderedMarkets;
};

export const attachGameMarkets = (sport, jsonMarkets, orderedGameMarkets) => {
    const unifiedGameMarkets = [];
    for (const orderedMarket of orderedGameMarkets) {
        //console.log('%%orderedGameMarkets',orderedGameMarkets)
        console.log('%%jsonMarkets[sport]',jsonMarkets[sport])
        const matchedMarket = jsonMarkets[sport].find(market => {
            console.log('utils.js market.id',market,orderedMarket)
            return market.id === orderedMarket.id
        })
        
        //console.log('matchedMarket',matchedMarket);
        //console.log('orderedMarket',orderedMarket);
        if (matchedMarket) {
            const description = {en: matchedMarket.en}
            const unifiedGameMarket = {
            description: description,
            ...orderedMarket
            };
            
            unifiedGameMarkets.push(unifiedGameMarket);
        }
    }
    return unifiedGameMarkets;
}

const singleGameMarketsPriority = {
    "football":[1,2,9,13,3,8,5,6,10,11],
    "basketball": [182,183,185,188,187,186,212,197,226,227,228],
};
const gotSingleGameMarkets = [];
export const reorderSingleGameMarkets = (sport, markets) => {
    const orderedMarkets = [];
    singleGameMarketsPriority[sport].forEach(id => {
        if (markets.some(market => market.id === id)) {
          const matchingMarket = markets.find(market => market.id === id);
          orderedMarkets.push(matchingMarket);
        }
    });
    
    const remainingMarkets = markets.filter(market =>
    !singleGameMarketsPriority[sport].includes(market.id)
    );
    
    orderedMarkets.push(...remainingMarkets);
    console.log('orderedMarkets',orderedMarkets);
    return orderedMarkets;
};

/*export const attachSingleGameMarkets = (sport, jsonMarkets, orderedGameMarkets) => {
    const unifiedGameMarkets = [];
    for (const orderedMarket of orderedGameMarkets) {
        const matchedMarket = jsonMarkets[sport].find(market => market.id === orderedMarket.id);
        
        //console.log('matchedMarket',matchedMarket);
        //console.log('orderedMarket',orderedMarket);
        if (matchedMarket) {
            const description = {en: matchedMarket.en}
            const unifiedGameMarket = {
            description: description,
            ...orderedMarket
            };
            
            unifiedGameMarkets.push(unifiedGameMarket);
        }
    }
    return unifiedGameMarkets;
}*/

export const addBetToBetslip = async (dispatch, sport, game, betslip, marketId, outcomeId, lang, wallet, bonus) => {
    const betExists = betslip.bets.some(
        betObject =>
            betObject.gameId === game.gameId &&
            betObject.marketId === marketId &&
            betObject.outcomeId === outcomeId
    );

    if(!betExists){
        const initialAmount = parseFloat(5.00).toFixed(2)
        const odd = game.markets.find(market => market.id === marketId).odds[outcomeId];
        const oddDecimals = game.markets.find(market => market.id === marketId).oddsDecimals[outcomeId];
        const newBet = {
            gameId: game.gameId,
            homeShortName: game.homeTeamShortName,
            awayShortName: game.awayTeamShortName,
            sportId: sport.id,
            marketId: marketId,
            marketName: game.markets.find(market => market.id === marketId).description[lang],
            odd: odd,
            oddDecimals: oddDecimals,
            outcomeId: outcomeId,
            outcomeDesc: game.markets.find(market => market.id === marketId).values[outcomeId],
            startsAt: game.timestamp,
            amount: initialAmount,
            possibleWinnings: parseFloat(initialAmount * odd).toFixed(2)
        };

        //let betslipArray = [];
        //console.log('betslipArray',betslipArray);
        //betslipArray = [...betslip,newBet];
        //const bets = await dispatch(slice.addBetToBetslip(newBet, () => betslip)); //TODO: Revisar callback
        const updatedBets = [...betslip.bets, newBet];
        const updatedBetslip = {
            ...betslip,
            bets: updatedBets
        }
        console.log('addBetToBetslip betslip:',betslip);
        console.log('newBet',newBet);
        updateBetslipProps(dispatch, updatedBetslip, wallet, bonus)
    }
}

export const updateBetslipProps = (dispatch, betslip, wallet = null, bonus = null) => {
    let totalAmount = 0;
    let totalPossibleWinnings = 0;
    let totalComboOdds = 0;
    let totalComboPossibleWinnings = 0;

    const updatedBets = betslip.bets.map((bet, index) => {
        return {
            ...bet,
            ...(Boolean
                (betslip.isBonusApplied
                    && bonus !== null
                    && !betslip.combo
                    && index === 0
                ) && {
                    amount: parseFloat(bonus.bonusAmount/10**wallet.tokenDecimals).toFixed(2),
                    possibleWinnings: parseFloat((bonus.bonusAmount/10**wallet.tokenDecimals) * bet.odd).toFixed(2),
                }),
        }
    });
    
    const totalComboAmount = Boolean
        (betslip.isBonusApplied
            && bonus !== null
            && betslip.combo
        )
        ? (bonus.bonusAmount/10**wallet.tokenDecimals)
        : betslip.totalComboAmountWithoutBonus
        /* console.log('#totalComboAmount',totalComboAmount, betslip.isBonusApplied,
            bonus !== null,
            betslip.combo
        ) */

    updatedBets.forEach((bet) => {
      totalAmount += parseFloat(bet.amount)
      totalPossibleWinnings += bet.possibleWinnings
      totalComboOdds === 0 ? totalComboOdds = bet.odd : totalComboOdds *= bet.odd
      totalComboPossibleWinnings = totalComboAmount * totalComboOdds
      console.log('#totalComboPossibleWinnings',betslip.totalComboAmount, totalComboOdds)
    });
    console.log('#totalAmount',totalAmount)


    const updatedBetslip = {
        ...betslip,
        bets: updatedBets,
        totalAmount: parseFloat(totalAmount).toFixed(2),
        totalPossibleWinnings: parseFloat(totalPossibleWinnings).toFixed(2),
        totalComboOdds: parseFloat(totalComboOdds).toFixed(2),
        totalComboPossibleWinnings: parseFloat(totalComboPossibleWinnings).toFixed(2),
        totalComboAmount: parseFloat(totalComboAmount).toFixed(2)
    }
    console.log('updatedBetslip1',updatedBetslip);
    dispatch(slice.updateBetslip(updatedBetslip));
  }

export const getDayLabel = (timestamp) => {
    const today = moment(/*'2023-05-21'*/);//TODO: moment();
    const tomorrow = moment(today).add(1, 'day');
    const gameDate = moment.unix(timestamp);

    if (gameDate.isSame(today, 'day')) {
        return 'Today';
    } else if (gameDate.isSame(tomorrow, 'day')) {
        return 'Tomorrow';
    }
    const gameDateMonth = (<Moment format='D MMM' unix>{timestamp}</Moment>);

    //console.log('---gameDateMonth:', gameDateMonth)
    return gameDateMonth;
}

export const getDay = (timestamp) => {
    const dateMonth = (<Moment format='D MMM' unix>{timestamp}</Moment>);
    return dateMonth;
}

export const getYear = (timestamp) => {
    const gameDate = moment.unix(timestamp);
    return gameDate.format('YYYY');
}

export const gameTimestampToLocal = (timestamp) => {
    //const dateToFormat = '2023-05-21T12:30+0000';
    //const timestamp = '1684686600';
    //console.log('timestamp:', timestamp)
    //const gameDate = (<Moment format='D' unix>{timestamp}</Moment>)
    //const gameDayNumber = moment.unix(timestamp).date();
    const timeLabel = (<Moment format='HH:mm' unix>{timestamp}</Moment>)
    const day = getDay(timestamp);
    const dayLabel = getDayLabel(timestamp);
    const year = getYear(timestamp)
    const fullTime = {
        day: day,
        dayLabel: dayLabel,
        timeLabel: timeLabel,
        year: year
    }
    return fullTime;
}

export const getShortWalletAddress = (address) => {
    const firstCharacters = address.slice(0, 6);
    const lastCharacters = address.slice(-4);
    return `${firstCharacters}...${lastCharacters}`;
}

export const capitalize = (string) => {
    const str1 = string;
    const str2 = str1.charAt(0).toUpperCase() + str1.slice(1);
    return str2
}

export const replaceCharacter = (string, inputCharacters, outputCharacter) => {
    if (typeof string !== 'string' || typeof inputCharacters !== 'string' || typeof outputCharacter !== 'string') {
      throw new Error('Both arguments must be strings');
    }
  
    if (string.length === 0) {
      throw new Error('String must not be empty');
    }
  
    const regex = new RegExp(`[${inputCharacters}]`, 'g');
    return string.replace(regex, outputCharacter);
};

export const parseBalance = (balance) => {
    const numStr = balance.toFixed(2);
    
    // Verifica si los dos primeros decimales son 0
    const [integerPart, decimalPart] = numStr.split('.');
    if (decimalPart === '00') {
        return integerPart; // Retorna solo la parte entera
    } else {
        return numStr; // Retorna el número con 2 decimales
    }
}

export const initWallet = (networkKeyname) => {
    return {
        network: {
            keyName: networkKeyname ? networkKeyname : null,
            name: null,
            networkId: null,
            TwRpc: null
        },
        address: null,
        isConnected: false,
        balance: null,
        tokenDecimals: null,
        tokenSymbol: null,
        allowance: null,
        isWelcomeBonusApplied: false
    }
}

export const getLoadingSentence = () => {
    const sentences = [
        "Loading sports bets... Hang tight!",
        "Shuffling sports stats... like a pro",
        "Loading sports thrills and chills...",
        "Almost there, champ! Get ready to play",
        "Fasten your seatbelt, it's betting time!",
        "In the world of bets, patience pays off!",
        "Stay cool, bets are heating up!",
    ]
    return sentences[Math.floor(Math.random() * sentences.length)];
}