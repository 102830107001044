import React, { useRef } from 'react';
import * as utils from '../utils/utils.js';

const Loader = () => {
  const randomSentence = useRef('');
  if (randomSentence.current === '') {
    randomSentence.current = utils.getLoadingSentence();
  }

  return (
    <div className='preloader-inner'>
      <div className='preloader-orbit-loading'>
        <div className='cssload-inner cssload-one'></div>
        <div className='cssload-inner cssload-two'></div>
        <div className='cssload-inner cssload-three'></div>
      </div>
      <div className='preloader-orbit-loading-sentence'>
        {randomSentence.current}
      </div>
    </div>
  );
}

export default Loader;