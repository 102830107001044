// Fetch user bets sets ids from smart contract
const fetchBetsSetsIds = async (coreContract, walletAddress) => {
  try {
    const userBetsSetIdsUnparsedResult =
      await coreContract.getUserBetsSetIds(walletAddress);
    const userBetsSetsIdsResult = userBetsSetIdsUnparsedResult.map((bsIdRes) => {
        return parseInt(bsIdRes._hex);
    });
    const reversedBetsSetsIds = [...userBetsSetsIdsResult].reverse();

    console.log("fetchBetsSetsIds.js userBetsSetsIdsResult", reversedBetsSetsIds);
    return reversedBetsSetsIds;
  } catch (error) {
    console.error("Error getting bets sets ids from smart contract", error);
    return [];
  }
};

export default fetchBetsSetsIds;
